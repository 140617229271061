import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
   <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25"><path d="M292-506q18-36 38.5-71t43.5-67l-79-16-109 109 106 45Zm515-312q-102 5-184 43.5T463-658q-31 31-64 81t-59 107l133 133q57-26 107-59t81-64q78-78 116.5-159.5T821-803q0-3-1-6t-3-5q-2-2-4.5-3t-5.5-1ZM570-566q-20-20-20-49.5t20-49.5q20-20 49.5-20t49.5 20q20 20 20 49.5T669-566q-20 20-49.5 20T570-566Zm-61 278 45 105 109-109-15-79q-32 23-67.5 44T509-288Zm373-559q3 125-40.5 230.5T704-417q-1 1-2 1.5t-2 1.5l22 110q3 15-1 29t-15 25L565-109q-11 11-26.5 8T517-119l-68-157-169-170-158-67q-15-6-17.5-22t8.5-27l140-140q11-11 25-15.5t29-1.5l110 22q1-1 2-1.5t2-1.5q94-94 199-137.5T850-878q6 0 11.5 2.5T872-868q5 5 7.5 10t2.5 11ZM149-325q35-35 85.5-35.5T320-326q35 35 34.5 85.5T319-155q-26 26-80.5 43T75-80q15-109 31.5-164t42.5-81Zm42 43q-14 15-25 47t-19 82q50-8 82-19t47-25q19-17 19.5-42.5T278-284q-19-18-44.5-17.5T191-282Z"/></svg>
    </SvgIcon>
  );
}

export default function OptimizerIcon() {
  const classes = useStyles();

  return (
    <>
      <HomeIcon color="inherit"  />
    </>
  );
}
