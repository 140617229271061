import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { StateProvider,useStateValue } from "../../../Store/Store";
import { DecimalTwoDigit } from "../../../tools/timeutil";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  rowDiff: {
    background:"#FF9789",
},
row: {
},
});


export default function GT2Parameters() {
  const classes = useStyles();
  const [state, setState] = React.useState<any>({GT2Result: ""});
  const [ stateValue, dispatch ] = useStateValue();

  useEffect(() => {
    if (Object.keys(stateValue.scenarioResults).length !== 0 && "gt2_block" in stateValue.scenarioResults.scenarioResult_1) {
        // console.log("pass",stateValue.scenarioResults);
        setState((prevState: any) => {
          return {
              ...prevState,
              GT2Result: stateValue.scenarioResults,
          };
      });
    }
}, [stateValue.scenarioResults]);
const {GT2Result} = state;

  const rows2:any = [];
  const keys = Object.keys(GT2Result);


if (GT2Result) {
  var i = 0;
  let count: any = Object.keys(GT2Result).length > 1 ? Object.keys(GT2Result).length : 1;

  for (let scenario in GT2Result) {
      i++;
      // console.log("scheamriolist",GT2Result[scenario].global_block);
     GT2Result[scenario].gt2_block.map((key: any, index:any) => {
          // console.log(scenario, "");
          var obj2: any = {};
          if ("scenarioResult_1" === scenario) {
            obj2.name = key.name;
            obj2.unit = key.measured_in;
              let match1: any = 7;
              let match2: any = 8;
              let match3: any = 9;
              let matchstring1 = key.name.substring(0,match1);
              let matchstring2 = key.name.substring(0,match2);
              let matchstring3 = key.name.substring(0,match3);
              if (("on_off_" == matchstring1)) {
                // console.log(matchstring1);
                  // console.log(key[1]);
                  switch (key.value) {
                      case "0.0":
                      case "0": 
                      case "False":
                      case 0 :
                          key.value = "False";
                          // console.log(key.value);
                          break;
                          case "1.0":
                          case "1": 
                          case "True":
                          case 1 :
                          key.value = "True";
                          // console.log(key.value);
                          break;
                      default:
                          key.value = key.value;
                          break;
                  }
              }
              if ("op_mode_1" == matchstring3) {
                switch (key.value) {
                    case "0":
                    case 0:
                        key.value = "N/A";
                        break;
                        case "1":
                        case 1:
                        key.value = "Fixed at minimum (data top)";
                        break;
                    default:
                        key.value = key.value;
                        break;
                }
            }
            if ("op_mode_2" == matchstring3) {
              switch (key.value) {
                  case "0":
                  case 0:
                      key.value = "N/A";
                      break;
                      case "1":
                      case 1:
                      key.value = "Operating range (data top)";
                      break;
                  default:
                      key.value = key.value;
                      break;
              }
          }
              if("True" === key.value || "False" === key.value){
                  obj2.scenario_1 = key.value;
              } else if("Fixed at minimum (data top)" === key.value || "Operating range (data top)" === key.value || "N/A" === key.value) {
                obj2.scenario_1 = key.value;
              }else {
                  obj2.scenario_1 = DecimalTwoDigit(key.value);
              }
              if (count >= 2) {
                let match4: any = 9;
                let matchstring4 = key.name.substring(0,match4);
                  // obj2.scenario_2 = GT2Result["scenarioResult_2"]!.gt2_block[key[0]];
                  for (let j = 2; j <= count; j++) {
                      if (
                        GT2Result["scenarioResult_" + j] && Object.keys(GT2Result["scenarioResult_" + j]).length !== 0 ) {
                          if (("on_off_" == matchstring1)) {
                              switch (GT2Result["scenarioResult_" + j]!.gt2_block[index].value) {
                                  case "0.0":
                                  case "0": 
                                  case "False":
                                  case 0 :
                                      GT2Result["scenarioResult_" + j]!.gt2_block[index].value = "False";
                                      // console.log(GT2Result["scenarioResult_" + j]!.gt2_block[index].value);
                                      break;
                                  case "1.0":
                                  case "1": 
                                  case "True":
                                  case 1 :
                                      GT2Result["scenarioResult_" + j]!.gt2_block[index].value = "True";
                                      // console.log(GT2Result["scenarioResult_" + j]!.gt2_block[index].value);
                                      break;
                                  default:
                                      GT2Result["scenarioResult_" + j]!.gt2_block[index].value = GT2Result["scenarioResult_" + j]!.gt2_block[index].value;
                                      break;
                              }
                          }
                          if ("op_mode_1" === matchstring4) {
                            switch (GT2Result["scenarioResult_" + j]!.gt2_block[index].value) {
                                case "0":
                                case 0:
                                    GT2Result["scenarioResult_" + j]!.gt2_block[index].value = "N/A";
                                    break;
                                    case "1":
                                    case 1:
                                    GT2Result["scenarioResult_" + j]!.gt2_block[index].value = "Fixed at minimum (data top)";
                                    break;
                                default:
                                    GT2Result["scenarioResult_" + j]!.gt2_block[index].value = GT2Result["scenarioResult_" + j]!.gt2_block[index].value;
                                    break;
                            }
                        }
                        if ("op_mode_2" === matchstring4) {
                          switch (GT2Result["scenarioResult_" + j]!.gt2_block[index].value) {
                              case "0":
                              case 0:
                                  GT2Result["scenarioResult_" + j]!.gt2_block[index].value = "N/A";
                                  break;
                                  case "1":
                                  case 1:
                                  GT2Result["scenarioResult_" + j]!.gt2_block[index].value = "Operating range (data top)";
                                  break;
                              default:
                                  GT2Result["scenarioResult_" + j]!.gt2_block[index].value = GT2Result["scenarioResult_" + j]!.gt2_block[index].value;
                                  break;
                          }
                      }
                          if( "True" === GT2Result["scenarioResult_" + j]!.gt2_block[index].value || "False" === GT2Result["scenarioResult_" + j]!.gt2_block[index].value){
                            obj2[`scenario_${j}`] = GT2Result["scenarioResult_" + j]!.gt2_block[index].value;
                          }else if("Fixed at minimum (data top)" === GT2Result["scenarioResult_" + j]!.gt2_block[index].value || "Operating range (data top)" === GT2Result["scenarioResult_" + j]!.gt2_block[index].value || "N/A" === GT2Result["scenarioResult_" + j]!.gt2_block[index].value) {
                            obj2[`scenario_${j}`] = GT2Result["scenarioResult_" + j]!.gt2_block[index].value;
                          }else{
                            obj2[`scenario_${j}`] = DecimalTwoDigit(GT2Result["scenarioResult_" + j]!.gt2_block[index].value);
                          }
                          if(obj2[stateValue.scenarioCompareoption.scenarioCompare1] !== obj2[stateValue.scenarioCompareoption.scenarioCompare2]){
                            obj2[`scenario_diff`] = 1;
                        }else{
                            obj2[`scenario_diff`] = 0;
                        }
                      }else{
                        GT2Result["scenarioResult_" + j] = '';
                      }
                  }
              }

          }
          rows2.push(obj2);
      });
      break;
  }

    // console.log(rows2);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>variable name</TableCell>
          {keys.map((row: any, index: any) => (
                            <TableCell key ={index} align="right">{`Scenario_${index +1}`}</TableCell>
                            ))}
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows2.map((row:any,index:any) => (
           <TableRow key={index} className={ row.scenario_diff ? classes.rowDiff : classes.row}>
              <TableCell component="th" scope="row">
                {row.name}{row.unit !== "na" ? `[${row.unit}]` :"" }
              </TableCell>
              <TableCell align="right">{row.scenario_1}</TableCell>
                                <TableCell align="right">{row.scenario_2}</TableCell>
                                <TableCell align="right">{row.scenario_3}</TableCell>
                                <TableCell align="right">{row.scenario_4}</TableCell>
                                <TableCell align="right">{row.scenario_5}</TableCell>
                                <TableCell align="right">{row.scenario_6}</TableCell>
                                <TableCell align="right">{row.scenario_7}</TableCell>
                                <TableCell align="right">{row.scenario_8}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
return <></>;
}
