
import React from "react";
import Header from "../Header/header";
import Routes from "../Routes/routes";

export default function Structure() {

            return (
                <>      
                <Header />
                {/* <Routes/> */}
               
                <main >
                </main>
                </>
              
            );
}