import React, { ChangeEvent,useContext } from "react";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { createAxiosInstance } from "../../../tools/axios-auth-interceptor";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Box from "@material-ui/core/Box";
import { myConstClass } from "../../../constants";
import { Alert, AlertTitle } from "@material-ui/lab";
import { File } from "buffer";
import { fetchData } from "../../../API/fetchAPI";
import { UnitShowParameter } from "../../../tools/timeutil";
import { useStateValue } from "../../../Store/Store";
import ComputeUtilityDemandIcon from "../Process/ComputeUtilityDemandIcon";
import SaveIcon from '@material-ui/icons/Save';
import { EnergyModeSaveData } from "../../../tools/timeutil";
import { ProfileContext } from "../../../context/profile.context";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
            marginLeft: "57%",
        },
        input: {
            display: "none",
        },
        button: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: "#ED0000",
            color: "#FFFF",
            borderColor: " #ED0000",
        },
        buttonDisable: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: "#FFFF",
            color: "#707374",
            borderColor: "#707374",
            pointerEvents:"none",
        },
        alertMain: {
            marginLeft: "70%",
            padding: "0px 0px 0px 0px",
            borderRadius: " 16px 0px 0px 16px",
            backgroundColor: "#ffffff",
        },
        alertClose: {
            display: "none",
        },
        notClick:{
            pointerEvents:"none",
            marginLeft: "57%",
        }
    })
);

interface responseType {
    config: object;
    data: object;
    headers: object;
    requests: object;
    status: object;
    statusText: object;
}

export default function EnergyModelButton() {
    const [stateValue, dispatch] = useStateValue();
    const classes = useStyles();
    const [FileList, setFileList] = useState<File | null | any>(null);
    const [alertopen, setAlertOpen] = React.useState(false);
    const [EnergyUpdateopen, setEnergyUpdateOpen] = React.useState(false);
    const [open, setOpen] = useState(true);
    const [errorMesaage, setErrorMesaage] = useState("");
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [resposeEnergyAPI, setResposeEnergyAPI] = useState<any | undefined>([]);
    const [Message, setMessage] = useState<any | undefined>([]);
    const [eventCall, setEventCall] = useState<any | undefined>([]);
    const userContext = useContext(ProfileContext);
    const Usertype = userContext?.context?.type;
    const units:any = {
        0: "611",
        1: "631_451",
        2: "641 BHC",
        3: "641-1",
        4: "641-2",
        5: "651",
        6: "661",
        7: "662",
        8: "662",
        9: "663",
        10: "664",
        11: "681",
        12: "691",
        13: "763",
        14: "823",
        15: "824",
        16: "POX",
    };

    useEffect(() => {
        
        if(EnergyUpdateopen){
            let anc = {...stateValue};
            for (const key in stateValue.EnergyEditUnit) {
                fetchData<any>(`${myConstClass.EnergyModel}/${stateValue.EnergyEditUnit[key]}`)
                .then((response: any) => {
                   
                    response.data.map((element: any, index:any) => {

                        // console.log("energysavedata",element.coeff.toString());
                    })
                    anc.energyModel[stateValue.EnergyEditUnit[key]] = response.data;

                    // console.log("fields",test);
                })
                .catch((error: any) => {
                    // console.log(error);
                });    
            }
            anc.energyModelSave = true;
            anc.EnergyEditStatus = false;
           
            dispatch( {
                type: "set",
                ...anc,
            } );
            setEnergyUpdateOpen(false);
        }
}, [EnergyUpdateopen]);

    const handleSaveClick = () => {

        const postEnergyModelSave = async (url: any, data:any, unit:any) => {
            const finalURL =`${url}/${unit}`;
            const response: Promise<AxiosResponse<responseType[]> | undefined | any> = (
                await createAxiosInstance()
            ).post(finalURL, data, {
                headers: {
                    accept: "*/*",
                    "Content-Type": "application/json",
                },
            });
            response
                .then(function (response) {
                    // console.log("result",finalSaveParameter );
                    // console.log("before", response.data.optim_id);
                    setMessage(response.data);
                    setEnergyUpdateOpen(true);
                    // let anc = {...stateValue};
                    // for (const key in stateValue.EnergyEditUnit) {
                    //     fetchData<any>(`${myConstClass.EnergyModel}/${stateValue.EnergyEditUnit[key]}`)
                    //     .then((response: any) => {
                    //         // console.log("energysavedata",response.data);
                    //         console.log("energysavedatastore",anc.energyModel[stateValue.EnergyEditUnit[key]]);
                    //         anc.energyModel[stateValue.EnergyEditUnit[key]] = response.data;
                    //         // console.log("fields",test);
                    //     })
                    //     .catch((error: any) => {
                    //         // console.log(error);
                    //     });    
                    // }
                    // console.log("Energydata",anc.energyModel);
                    // fetchData<any>(myConstClass.utilityDemand)
                    // .then((response: any) => {
                    //     setUtilityDemand(response.data);
                    //     console.log("fields", response.data);
                    //     setAlertOpen(true);
                    //     setTimeout(function () {
                    //         // setOption(false);
                    //         setAlertOpen(false);
                    //         console.log("close");
                    //     }, 3000);
                    // })
                    // .catch((error: any) => {
                    //     // console.log(error);
                    //     setErrorAlertOpen(true);
                    //     setTimeout(function () {
                    //         // setOption(false);
                    //         setErrorAlertOpen(false);
                    //     }, 3000);
                    // });
                        setAlertOpen(true);
                        setTimeout(function () {
                            // setOption(false);
                            setAlertOpen(false);
                        }, 3000);
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorAlertOpen(true);
                    setTimeout(function () {
                        // setOption(false);
                        setErrorAlertOpen(false);
                    }, 3000);
                });
        };
        if('admin' === Usertype){
            for (const key in stateValue.EnergyEditUnit) {
                //     let test = `PITag${units[key]}`;
                let energySave = EnergyModeSaveData(stateValue.energyModel[`${stateValue.EnergyEditUnit[key]}`]);
                if(energySave &&  stateValue.EnergyEditUnit[key]){         
                    postEnergyModelSave(myConstClass.EnergyModel,energySave,stateValue.EnergyEditUnit[key]);
                }
                }
        }else {
            setErrorAlertOpen(true);
                    setTimeout(function () {
                        // setOption(false);
                        setErrorAlertOpen(false);
                    }, 3000);
        }
       
        
    };


    return (
        <Box component="span" sx={{ p: 2, marginLeft: "50%"}} className={stateValue.EnergyEditStatus ? classes.root : classes.notClick}>
            <label>
                <Button
                    variant="outlined"
                    color="secondary"
                    className={stateValue.EnergyEditStatus ? classes.button : classes.buttonDisable}
                    startIcon={<SaveIcon />}
                    onClick={handleSaveClick}
                >
                    Save
                </Button>
            </label>
            <Alert
                // onClose={() => handleClick()}
                severity="success"
                className={alertopen ? classes.alertMain : classes.alertClose}
            >
                <AlertTitle>Successfully</AlertTitle>
                <p>{Message}</p>
            </Alert>
            <Alert
                // onClose={() => handleClick()}
                severity="error"
                className={errorAlertOpen ? classes.alertMain : classes.alertClose}
            >
                <AlertTitle>Unsuccessfully</AlertTitle>
                <p>The Energy model Factor is saved Unsuccessfully</p>
                {'admin' !== Usertype && (<p>Need Adminstration privilages to save energy modee unit.</p>)}
            </Alert>
        </Box>
    );
}
