import React, { useState, useEffect, useReducer } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, IconButton, InputAdornment} from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Box from '@material-ui/core/Box';
import { useStateValue } from "../Store/Store";
import reducer, { initialState }  from "../Store/reducer";
import { DecimalTwoDigit } from "../tools/timeutil";
import { authenticationClientProvider } from '../config/auth-provider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
                width: "25ch",
                display: "flex",
            },
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize:'14px',
        },
        error: {
            borderColor: '#FF0000',
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize:'14px',
        },
        errorMessage: {
           fontSize:'10px',
           color:'#FF0000',
           display: 'flex',
           alignItems: 'center',
        },
        warning: {
            color:'#FF0000',
         },
         noError: {
            visibility: 'hidden',
         },

    })
);

interface APIDataType {
    id: number;
    email: string;
    first_name: string;
    optim_id: number;
    optim_output: null;
    value: number;
    timestamp: string;
    param_id: number;
    units_id: number;
    parameter: string;
    measured_in: String;
}
interface valueType {
    value: number;
}

export const NumberInput = (props: any): any => {
    const classes = useStyles();
    const [errorMessage,setErrorMessage] = useState('');
    const [errorType,setTypeError] = useState(false);
    const [value, setValue] = useState<any | undefined>(props.value);
    const [ state, dispatch ] = useStateValue();
    const accounts = authenticationClientProvider.getAllAccounts();
    const userId = accounts[0].username.split('@')[0];
    // console.log("user_id",userId);
    // const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        setValue(props.value);
    }, [props]);
    
    const handleInputValueChange = (event:any) => {
        let anc = {...state};
        // console.log("statevalue",anc.GT2);
        if("Global" == props.Tabtype){
            // console.log(anc.Global);
            anc.Global[props.parameter].value =event.target.value;
        }
        if("GT2" == props.Tabtype){
            // console.log(anc.GT2);
            anc.GT2[props.parameter].value =event.target.value;
        }
        // console.log(anc.RKB);
        if("RKB" == props.Tabtype){
            // console.log(anc.RKB);
            anc.RKB[props.parameter].value =event.target.value;
        }
        if("Flower" == props.Tabtype){
            anc.Future_API[props.parameter].throughput =event.target.value;
            anc.Future_API[props.parameter]["user_id"] = userId;
            anc.processEditStatus = true;
        }
          dispatch( {
            type: "set",
            ...anc,
        } );
        // setValue(event.target.value);
      };
    
   
   function handleOnKeyDown(event: any) {
    handleOnBlur(event);
   }

    function handleOnBlur(event: any) {
        var regex = /^[()+*#-]*(?:\d[()+*#-]*){8,40}$/;
        let num = Number(value);    // allow only numbers [0-9] 
        if(! value){
            setErrorMessage('Please enter the value.')
            setTypeError(true);
        }else if (value && regex.test(value)){
            setErrorMessage('Only allow Maximum 7 digit ')
            setTypeError(true);
        }else if (isNaN(num)){
        setErrorMessage('Please enter only digit or decimal[0-9].')
        setTypeError(true);
    } else{
            setErrorMessage('');
            setTypeError(false);
        }
       
    }
    function getHandleCross() {
        if (errorType) {
            return (
                <InputAdornment position="end">
                    <IconButton className={classes.warning}>
                        <WarningIcon/>
                    </IconButton>
                </InputAdornment>
            );
        }
    }

    return (
    <>
    <div className="">
            <TextField
               name={props.parameter}
               value={value}
                onChange={(event) => handleInputValueChange(event)}
                variant="outlined"
                onBlur ={(event) => handleOnBlur(event)}
                // onKeyDown ={(event) => handleOnKeyDown(event)}
                className={errorType ? classes.error: classes.field}
                InputProps={{
                    inputProps: { "data-testid": props?.parameter },
                    endAdornment: getHandleCross(),
                    // className: props?.classNameInput,
                }}
            />
        </div>
        <Typography className={errorType ? classes.errorMessage: classes.noError}><Box className='' component="span" m={1}><ErrorOutlineIcon/></Box>{errorMessage} </Typography>
    </>
        
    );
};
