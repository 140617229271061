import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { myConstClass } from '../constants';

export const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: myConstClass.AppInsightsConnectionString,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        extensionConfig: {},
    },
});
appInsights.loadAppInsights();

export const trackAuthenticatedUser = (userMail:any) => {
    appInsights.setAuthenticatedUserContext(userMail);
}