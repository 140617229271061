import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useContext, useEffect } from 'react';
import { authenticationClientProvider } from '../config/auth-provider';
import { trackAuthenticatedUser } from '../config/ApplicationInsights';

interface TrackUserActivityProps {
    name: string;
    children: JSX.Element;
}


interface TrackUserActivityProps {
    name: string;
    children: JSX.Element;
}

export const TrackUserActivity = ({ name, children }: TrackUserActivityProps) => {
    const appInsights = useAppInsightsContext();
    const accounts = authenticationClientProvider.getAllAccounts();
    const authenticatedUserId = accounts[0].username;
    const accountID = accounts[0].localAccountId;
    
    useEffect(() => {
        appInsights.trackPageView({
            name,
            uri: window.location.href,
            properties: {authenticatedUserId,accountID },
        });
        trackAuthenticatedUser(authenticatedUserId);
    },[]);

    return children;
};