import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import B0Boiler from "./Tabcomponents/rkb/B0Boiler";
import B1Boiler from "./Tabcomponents/rkb/B1Boiler";
import B2Boiler from "./Tabcomponents/rkb/B2Boiler";
import B3Boiler from "./Tabcomponents/rkb/B3Boiler";
import Turbines from "./Tabcomponents/rkb/Turbines";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor:'unset',
    "& > *": {
      margin: theme.spacing(1),
      border: "2px solid #F7F9FA",
      borderRadius: " 16px",
      paddingBottom: "0px",
      backgroundColor:'#FFFFFF',
  },
  },
  AppButton:{
    color: '#374649',
    backgroundColor: '#EBEEEF',
  },

}));

export default function TabButton() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.AppButton}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"  centered>
          <Tab label="Boilers" {...a11yProps(0)} />
          <Tab label="Turbines" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
            <B0Boiler/>
            <B1Boiler/>
            <B2Boiler/>
            <B3Boiler/>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Turbines/>
      </TabPanel>
    </div>
  );
}
