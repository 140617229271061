import React, { useState, useContext,useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import TextFields from './TextFields';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../../context";
import { NumberInput } from "../NumberInput";
import { APIDataType } from "../../types/ApitParameter";
import SelectInput from "../../components/SelectInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import InfoIcon from "@material-ui/icons/Info";
import Divider from "@material-ui/core/Divider";
import { useStateValue } from "../../Store/Store";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(1),
                width: theme.spacing(60),
                height: theme.spacing(16),
                border: "2px solid #F7F9FA",
                borderRadius: " 16px",
                padding: "16px",
                paddingBottom: "0px",
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subHeading: {
            fontWeight: 700,
            fontSize: "1rem",
            width: theme.spacing(100),
            height: "unset",
            border: "0px",
        },
        Heading: {
            width: theme.spacing(100),
            height: "unset",
            border: "0px",
            fontSize: "1.13rem",
            fontWeight: 700,
            lineHeight: "22px",
            textTransform: "uppercase",
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "16px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#707374",
        },
        Network: {
            width: theme.spacing(50),
            height: theme.spacing(14),
        },
        Pendelband: {
            width: theme.spacing(50),
            height: theme.spacing(14),
            border: "2px solid #F7F9FA",
            borderRadius: " 16px",
            padding: "16px",
            paddingBottom: "0px",
        },
        select: {
            width: theme.spacing(50),
            height: theme.spacing(20),
        },
        GT2Turbine: {
            border: "unset",
            height: "unset",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "22px",
            marginRight: "41px",
        },
        switch: {
            width: theme.spacing(100),
            height: "unset",
            border: "0px",
        },
        switchIcon: {
            verticalAlign: "middle",
            color: "#80CDFF",
        },
        switchLabel: {
            marginLeft: "9px",
        },
        switchdivider: {
            marginTop: "20px",
        },
        checkboxStyle: {
            width: theme.spacing(50),
            height: theme.spacing(14),
            opacity: "0.5",
        },
        checkboxStyleSwitch: {
            width: theme.spacing(50),
            height: theme.spacing(20),
            opacity: "0.5",
            backgroundColor:'#F7F9FA',
        },
    })
);


export default function ElectricityProductionBounds() {
    const classes = useStyles();
    // const data = useContext(AppContext);

    //     let fields = data.gt2.reduce(
    //         (obj:any, item:any) => Object.assign(obj, { [item.parameter]: item }), {});
    const [ stateValue, dispatch ] = useStateValue();
    // Object.assign(stateValue,{"Tabtype":"Gt2"});
    // const [state, setState] = React.useState({
    //     GT2Turbine: stateValue.GT2["gt2_active"].value,
    // });
    const [state, setState] = React.useState<any>({
        gt2_active: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        gt2_force_active: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        gt2_operating_mode: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: "" },
    });

    useEffect(() => {
        if (Object.keys(stateValue.GT2).length !== 0) {
            // console.log("useeffect",stateValue.Global["fg_allowed"]);
            setState((prevState:any) => {
                return {
                    ...prevState,
                    gt2_active: stateValue.GT2["gt2_active"].value,
                    gt2_force_active: stateValue.GT2["gt2_force_active"].value,
                    gt2_operating_mode:stateValue.GT2["gt2_operating_mode"].value,
                };
            });
        }
    }, [stateValue]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          let anc = {...stateValue};
        let value = "";
        if(event.target.checked === true){
            value = "1";
        } else{
            value = "0";
        }
        // console.log("checkevent:", value);
        // console.log("checkevent:",event.target.name);
        anc.GT2[`${event.target.name}`].value = value;
        dispatch( {
            type: "set",
            ...anc,
        } );
        // setState({ ...state, [event.target.name]: event.target.checked });
    };

    const { gt2_active,gt2_force_active,gt2_operating_mode } = state;

    const booleanValue = (parameter:any) => {
        let testValue;
        if(parameter == "1.0" || parameter == "1"){
            testValue = true;
        }
        else{
            testValue = false;
        }
        return testValue;
      }
     
    // const [switchstate, setswitchState] = React.useState({
    //     checkedB: true,
    //     gilad: true,
    // });

    // const switchHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setswitchState({ ...switchstate, [event.target.name]: event.target.checked });
    // };

    // const { gilad } = switchstate;

    const Selectoption = {
        default:gt2_operating_mode,
        name:"gt2_operating_mode",
        value:{
            "0":"Fixed at minimum (data top)",
            "1":"Operating range (data top)",
            "2":" Let optimizer choose",
        }};

    return (
        <>
            <div className={classes.root}>
                <Paper elevation={0} className={classes.switch}>
                    <FormControlLabel
                        control={<Checkbox checked={booleanValue(gt2_active)} onChange={handleChange} name="gt2_active" />}
                        label="GT2 TURBINE"
                        className={classes.GT2Turbine}
                    />
                    <InfoIcon className={classes.switchIcon} color="primary" />
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={booleanValue(gt2_force_active)}
                                onChange={handleChange}
                                name="gt2_force_active"
                            />
                        }
                        label="Force active"
                        labelPlacement="start"
                        className={classes.switchLabel}
                    />
                    <Divider className={classes.switchdivider} />
                </Paper>
                <Typography variant="h6" gutterBottom className={classes.Heading}>
                    Electricity production bounds
                </Typography>
                <Paper elevation={0} className={booleanValue(gt2_active) ? classes.Network : classes.checkboxStyle}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                        Minimum allowed electricity production
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Min
                                    <Box className={classes.unit} component="span" m={1}>
                                        (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.GT2["gt2_elec_prod_mid1_bnd"],{"Tabtype":"GT2"})} />
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={booleanValue(gt2_active) ? classes.Pendelband : classes.checkboxStyle}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                        Operating range electricity production
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Min
                                    <Box className={classes.unit} component="span" m={1}>
                                        (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.GT2["gt2_elec_prod_mid2_bnd"],{"Tabtype":"GT2"})} />
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Max
                                    <Box className={classes.unit} component="span" m={1}>
                                        (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.GT2["gt2_elec_prod_max_bnd"],{"Tabtype":"GT2"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={booleanValue(gt2_active) ? classes.select : classes.checkboxStyleSwitch}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                        Operating mode choice
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <label className={classes.label}>
                                    Choices
                                    <Box className={classes.unit} component="span" m={1}>
                                    </Box>
                                </label>
                                <SelectInput {...Object.assign({},Selectoption,{"Tabtype":"GT2"})} />
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
        </>
    );
}
