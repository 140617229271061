import { Timestamps } from "./tools/timeutil";
import { authenticationClientProvider } from "./config/auth-provider";
 console.log("f_start:-",Timestamps());
 console.log("start_date",Timestamps().start_date);
 console.log("start_date",Timestamps().end_date);

 let accounts = authenticationClientProvider.getAllAccounts();
 let userId = '';
 console.log(accounts);
 if(accounts.length !== 0){
    userId = accounts[0].username.split('@')[0];
 }


export const myConstClass = {
    GLOBAL_API :`${process.env.REACT_APP_BACKEND_URL}/parameters/unit?unit_ids=3&optim_id=`,
    GT2_API :`${process.env.REACT_APP_BACKEND_URL}/parameters/unit?unit_ids=2&optim_id=`,
    RKB_API : `${process.env.REACT_APP_BACKEND_URL}/parameters/unit?unit_ids=1&optim_id=`,
    FlowerImport_API : `${process.env.REACT_APP_BACKEND_URL}/operating/conditions/import?refinery_id=1`,
    // Actual_API:`${process.env.REACT_APP_BACKEND_URL}/operating/conditions/actual?refinery_id=1&start_timestamp=2023-07-21 04:30:04.010&end_timestamp=2023-07-21 04:35:04.010`,
    Actual_API:`${process.env.REACT_APP_BACKEND_URL}/operating/conditions/actual?refinery_id=1&start_timestamp=${Timestamps().start_date}&end_timestamp=${Timestamps().end_date}`,
    Future_API:`${process.env.REACT_APP_BACKEND_URL}/operating/conditions/future?refinery_id=1&start_timestamp=${Timestamps().date}&end_timestamp=${Timestamps().date}&user_id=${userId}`,
    EnergyModel:`${process.env.REACT_APP_BACKEND_URL}/nrgymodels/1`,
    PITag:`${process.env.REACT_APP_BACKEND_URL}/operating/conditions/tagAndValue`,
        // Future_API:`${process.env.REACT_APP_BACKEND_URL}/operating/conditions/future?refinery_id=1&start_timestamp=${Timestamps().start_date}&end_timestamp=${Timestamps().end_date}`,
    utilityDemand:`${process.env.REACT_APP_BACKEND_URL}/nrgymodels/compute-utility-demand?start_time_pi=${Timestamps().start_date}&end_time_pi=${Timestamps().end_date}&start_time_flowers=${Timestamps().date}&end_time_flowers=${Timestamps().date}`,
    // utilityDemand:`${process.env.REACT_APP_BACKEND_URL}/nrgymodels/compute-utility-demand?start_time_pi=2023-8-8 12:14:7&end_time_pi=2023-8-8 12:19:7&start_time_flowers=2023-8-8&end_time_flowers=2023-8-8`,\
    Optimizer_API : `${process.env.REACT_APP_BACKEND_URL}/optimization/optimization?optim_id=`,
    ParameterSave_API : `${process.env.REACT_APP_BACKEND_URL}/parameters/unit`,
    FutureSaveAPI : `${process.env.REACT_APP_BACKEND_URL}/operating/conditions/future`,
    resetFlowerData : `${process.env.REACT_APP_BACKEND_URL}/operating/conditions/resetFlowerData`,
    AppInsightsConnectionString: process.env.REACT_APP_APPINSIGHTS_CONNECT_STRING,
};
