import React, { useState, useContext, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import TextFields from './TextFields';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../../../context";
import { NumberInput } from "../../NumberInput";
import { APIDataType } from "../../../types/ApitParameter";
import Divider from "@material-ui/core/Divider";
import { InputTextTag } from "../../InputTextTag";
import { myConstClass } from "../../../constants";
import { fetchData } from "../../../API/fetchAPI";
import useFetch from "../../../API/oldfetchAPI";
import EnergyBlock from "./Energyblock";
import { Timestamps } from "../../../tools/timeutil";

import { StateProvider, useStateValue } from "../../../Store/Store";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                // margin: theme.spacing(1),
                width: theme.spacing(200),
                // border: "2px solid #F7F9FA",
                borderRadius: " 16px",
                padding: "16px",
                // pointerEvents: "none",
                marginTop: "16px",
                marginBottom: "16px",
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subHeading: {
            fontWeight: 700,
            fontSize: "1.1rem",
        },
        field: {
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize: "14px",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "16px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#707374",
            paddingLeft: "5px",
            paddingRight: "5px",
        },
        Steam: {
            width: theme.spacing(200),
            height: theme.spacing(22),
        },
        Electricity: {
            width: theme.spacing(50),
            height: theme.spacing(14),
        },
        Fuel: {
            width: theme.spacing(140),
            height: theme.spacing(14),
        },
        CO2: {
            width: theme.spacing(30),
            height: theme.spacing(14),
        },
        fuelMain: {
            paddingLeft: "12px",
        },
    })
);

export default function Energyunits() {
    const classes = useStyles();
    const units:any = {
        0: "611",
        1: "631_451",
        2: "641 BHC",
        3: "641-1",
        4: "641-2",
        5: "651",
        6: "661",
        7: "662",
        8: "662",
        9: "663",
        10: "664",
        11: "681",
        12: "691",
        13: "763",
        14: "823",
        15: "824",
        16: "POX",
    };

    const [stateValue, dispatch] = useStateValue();
    const [stateFuture, setFutureState] = useState<any>({ flowerAPIFields: "" });
    useEffect(() => {
            let test:any;
            for (const key in units) {
            fetchData<any>(`${myConstClass.EnergyModel}/${units[key]}`)
            .then((response: any) => {
               test = { ...test, [units[key]]: response.data };
               dispatch({
                type: "set",
                energyModel:test,
            });
                // console.log("fields",test);
            })
            .catch((error: any) => {
                // console.log(error);
            });
        } 
        let anc = {...stateValue};
        anc.energyModelSave = false;
        dispatch( {
            type: "set",
            energyModelSave:anc.energyModelSave,
        } );
        // setPITag(test);
    }, [stateValue.energyModelSave]);

    const [pistate, setPIState] = React.useState<any>({
        PITag611: "",
        PITag631_451:"",
        PITag641BHC:"",
        PITag641_1: "",
        PITag641_2: "",
        PITag651: "",
        PITag661: "",
        PITag662: "",
        PITag663: "",
        PITag664: "",
        PITag681: "",
        PITag691: "",
        PITag763: "",
        PITag823: "",
        PITag824: "",
        POX: "",
    });
    // console.log(fields);
    useEffect(() => {
        if (Object.keys(stateValue.energyModel).length !== 0 ) {
            setPIState((prevState: any) => {
                return {
                    ...prevState,
                    PITag611: stateValue.energyModel["611"],
                    PITag631_451:stateValue.energyModel["631_451"],
                    PITag641BHC:stateValue.energyModel["641 BHC"],
                    PITag641_1: stateValue.energyModel["641-1"],
                    PITag641_2: stateValue.energyModel["641-2"],
                    PITag651: stateValue.energyModel["651"],
                    PITag661: stateValue.energyModel["661"],
                    PITag662: stateValue.energyModel["662"],
                    PITag663: stateValue.energyModel["663"],
                    PITag664: stateValue.energyModel["664"],
                    PITag681: stateValue.energyModel["681"],
                    PITag691: stateValue.energyModel["691"],
                    PITag763: stateValue.energyModel["763"],
                    PITag823: stateValue.energyModel["823"],
                    PITag824: stateValue.energyModel["824"],
                    PITagPOX: stateValue.energyModel["POX"],
                };
            });
        }
        
    }, [stateValue.energyModel,stateValue.energyModelSave]);

    // console.log(deltavaluesELECAPI);
const{ PITag611,PITag631_451,PITag641BHC,PITag641_1,PITag641_2,PITag651,PITag661,PITag662,PITag663,PITag664,PITag681,PITag691,PITag763,PITag823,PITag824,PITagPOX} = pistate;
    return (
            <div className={classes.root}>
                <EnergyBlock
                    {...Object.assign(
                        { tags: PITag611},
                        { unit: "611"}
                    )}
                />
                 <EnergyBlock
                    {...Object.assign(
                        { tags: PITag631_451},
                        { unit: "631_451"}
                    )}
                />
                <EnergyBlock
                    {...Object.assign(
                        { tags: PITag641BHC },
                        { unit: "641 BHC" }
                    )}
                />
                <EnergyBlock
                    {...Object.assign(
                        { tags: PITag641_1 },
                        { unit: "641-1" }
                    )}
                />
                 <EnergyBlock
                    {...Object.assign(
                        { tags: PITag641_2},
                        { unit: "641-2" }
                    )}
                />
                <EnergyBlock
                    {...Object.assign(
                        { tags: PITag651 },
                        { unit: "651" }
                    )}
                />
                <EnergyBlock
                    {...Object.assign(
                        { tags: PITag661 },
                        { unit: "661" }
                    )}
                />
                <EnergyBlock
                    {...Object.assign(
                        { tags: PITag662 },
                        { unit: "662" }
                    )}
                /> 
                  <EnergyBlock
                    {...Object.assign(
                        { tags: PITag663 },
                        { unit: "663" }
                    )}
                /> 
                  <EnergyBlock
                    {...Object.assign(
                        { tags: PITag664},
                        { unit: "664" }
                    )}
                /> 
                  <EnergyBlock
                    {...Object.assign(
                        { tags: PITag681 },
                        { unit: "681" }
                    )}
                /> 
                  <EnergyBlock
                    {...Object.assign(
                        { tags: PITag691 },
                        { unit: "691" }
                    )}
                /> 
                  <EnergyBlock
                    {...Object.assign(
                        { tags: PITag763 },
                        { unit: "763" }
                    )}
                /> 
                  <EnergyBlock
                    {...Object.assign(
                        { tags: PITag823 },
                        { unit: "823" }
                    )}
                /> 
                  <EnergyBlock
                    {...Object.assign(
                        { tags: PITag824 },
                        { unit: "824" }
                    )}
                /> 
                 <EnergyBlock
                {...Object.assign(
                { tags: PITagPOX },
                { unit: "POX" }
                )}
                 /> 
                
            </div>
    );  
}
