import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OptimizationParameters from "./OptimizationParameters";
import ResultPara from "./ResultPara";
import GlobalParameters from "./GlobalParameters";
import GT2Parameters from "./GT2Parameters";
import RKBParameters from "./RKBParameters";
import { useStateValue } from "../../../Store/Store";
import SaveScenario from "./SaveScenario";
import CompareScenarios from "./CompareScenarios";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            "& > *": {
                padding: "16px",
                marginBottom: "10px",
                backgroundColor: "fffff",
            },
        },
        heading: {
            flexBasis: "92.33%",
            flexShrink: 0,
            fontSize: "18px",
            fontWeight: 700,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: "#ED0000",
        },
        disableSecondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        moreIcon: {
            color: "#ED0000",
        },
    })
);

export default function ControlledAccordions() {
    const classes = useStyles();
    const [stateValue, dispatch] = useStateValue();
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <Accordion expanded={true === stateValue.resulttabs} onChange={handleChange("panel1")}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={stateValue.resulttabs ? classes.moreIcon : ""} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>Global cost values</Typography>
                    <Typography
                        className={stateValue.resulttabs ? classes.secondaryHeading : classes.disableSecondaryHeading}
                    >
                        Open detail
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ResultPara />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <CompareScenarios />
            <Accordion
                expanded={expanded === "panel2" && true === stateValue.resulttabs}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={stateValue.resulttabs ? classes.moreIcon : ""} />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className={classes.heading}>Optimization parameters</Typography>
                    <Typography
                        className={stateValue.resulttabs ? classes.secondaryHeading : classes.disableSecondaryHeading}
                    >
                        Open detail
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <OptimizationParameters />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={true === stateValue.resulttabs} onChange={handleChange("panel3")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon className={stateValue.resulttabs ? classes.moreIcon : ""} />} aria-controls="panel3bh-content" id="panel3bh-header">
                    <Typography className={classes.heading}>Global parameters</Typography>
                    <Typography
                        className={stateValue.resulttabs ? classes.secondaryHeading : classes.disableSecondaryHeading}
                    >
                        Open detail
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <GlobalParameters />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={true === stateValue.resulttabs} onChange={handleChange("panel4")}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={stateValue.resulttabs ? classes.moreIcon : ""} />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography className={classes.heading}>RKB parameters</Typography>
                    <Typography
                        className={stateValue.resulttabs ? classes.secondaryHeading : classes.disableSecondaryHeading}
                    >
                        Open detail
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <RKBParameters />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={true === stateValue.resulttabs} onChange={handleChange("panel5")}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={stateValue.resulttabs ? classes.moreIcon : ""} />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <Typography className={classes.heading}> GT2 parameters</Typography>
                    <Typography
                        className={stateValue.resulttabs ? classes.secondaryHeading : classes.disableSecondaryHeading}
                    >
                        {stateValue.resulttabs ? "Close detail" : " Open detail"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <GT2Parameters />
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
