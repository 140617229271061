import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StateProvider, useStateValue } from "../../../Store/Store";
import { DecimalTwoDigit } from "../../../tools/timeutil";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    heading: {
        fontWeight: 700,
    },
});

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
    return { name, calories, fat, carbs, protein };
}

export default function ResultPara() {
    const classes = useStyles();
    const [state, setState] = useState<any>({ globalResult: "" });
    // console.log(state);
    const [stateValue, dispatch] = useStateValue();
    // console.log("testing",stateValue.scenarioResults);

    useEffect(() => {
        if (
            Object.keys(stateValue.scenarioResults).length !== 0 &&
            "global_block" in stateValue.scenarioResults.scenarioResult_1
        ) {
            // console.log("pass",stateValue.scenarioResults);
            setState((prevState: any) => {
                return {
                    ...prevState,
                    globalResult: stateValue.scenarioResults,
                };
            });
        }
    }, [stateValue.scenarioResults]);
    const { globalResult } = state;
    const keys = Object.keys(globalResult);
    const rows2: any = [];

    if (globalResult) {
        var i = 0;
        let count: any = Object.keys(globalResult).length > 1 ? Object.keys(globalResult).length : 1;

        for (let scenario in globalResult) {
            i++;
            // console.log("scheamriolist",globalResult[scenario].global_block);
            globalResult[scenario].global_block.map((key: any, index:any) => {
                // console.log(scenario, key);
                var obj2: any = {};
                if ("scenarioResult_1" === scenario) {
                    // var temp = "name" + i;
                    // console.log(temp);
                    // var temp1 = "value" + i;
                    if ("global_cost" === key.name) {
                        obj2[scenario] = DecimalTwoDigit(key.value);
                        rows2.push(obj2);
                    }
                    if (count >= 2) {
                        // obj2.scenario_2 = globalResult["scenarioResult_2"]!.global_block[key[0]];
                        for (let j = 2; j <= count; j++) {
                            if (globalResult["scenarioResult_" + j] && Object.keys(globalResult["scenarioResult_" + j]).length !== 0) {
                                if ("global_cost" === key.name) {
                                    obj2[`scenarioResult_${j}`] =
                                    DecimalTwoDigit(globalResult["scenarioResult_" + j]!.global_block[index].value);
                                    rows2.push(obj2);
                                }
                            }
                        }
                    }

                    // obj23 = {...obj23,...obj2};
                }
                // }else if("scenarioResult_1" !== scenario){
                //   // if(obj2.name  == key[0]){
                // // console.log("test",key[0]);
                //   // }
                // // let data = Object.assign(obj2, { "value3": key[1]})
                // obj2 = {...obj2,[scenario]: key[1]};s
                // // console.log("test",obj23);
                // }
                // console.log();
                // console.log("gauravglobal", obj2);
                // rows2.push(obj2);
            });
            break;
        }
        // console.log(rows2);
        // console.log("globalresult",globalResult.global_cost);
        // const rows = [
        //   createData('Global cost (€/h)',globalResult?.global_cost, 6.0, 24, 4.0),
        // ];

        return (
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {keys.map((row: any, index: any) => (
                                <TableCell key={index} align="right">{`Scenario_${index + 1}`}</TableCell>
                            ))}
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows2.map((row: any,index: any) => {
                          if(0 === index) { 
                            return(
                                <TableRow key={row.name}>
                                <TableCell component="th" scope="row" className={classes.heading}>
                                    Global cost (€/h)
                                </TableCell>
                                <TableCell align="right">{row.scenarioResult_1}</TableCell>
                                <TableCell align="right">{row.scenarioResult_2}</TableCell>
                                <TableCell align="right">{row.scenarioResult_3}</TableCell>
                                <TableCell align="right">{row.scenarioResult_4}</TableCell>
                                <TableCell align="right">{row.scenarioResult_5}</TableCell>
                                <TableCell align="right">{row.scenarioResult_6}</TableCell>
                                <TableCell align="right">{row.scenarioResult_7}</TableCell>
                                <TableCell align="right">{row.scenarioResult_8}</TableCell>
                                
                              </TableRow>  
                            )   
                          }
                        }) }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    return <></>;
}
