import React from 'react';
import { useEffect, useState } from "react";
import { useStateValue } from "../../../Store/Store";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

function HomeIcon(props: SvgIconProps) {
    const [state, dispatch] = useStateValue();
  return (
    <SvgIcon {...props}>
   <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 8.5H10.5M13.75 14H17.25M13.75 16.5H17.25M6.75 15.25H10.75M8.75 17.25V13.25M14.1001 7L16.9285 9.82843M14.1001 9.82861L16.9285 7.00019M6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4Z" stroke= {state.processEditStatus ? "#ffff" :"#000000"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
    </SvgIcon>
  );
}

export default function ComputeUtilityDemandIcon() {
  const classes = useStyles();

  return (
    <>
      <HomeIcon color="inherit"/>
    </>
  );
}
