import React, { ChangeEvent} from "react";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
// import { createAxiosInstance } from "../tools/axios-auth-interceptor";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from "@material-ui/core/Box";
// import { myConstClass } from "../constants";
import { Alert, AlertTitle } from '@material-ui/lab';
import { File } from "buffer";
import { useStateValue } from "../../../Store/Store";
import SelectInput from "../../SelectInput";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
            display: "flex",
        },
        input: {
            display: "none",
        },
        button: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            color:"#ffff",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor:"#ED0000",
            borderColor:" #ED0000",
        },
        disableButton : {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            color:"#000000",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor:"#EDEFF0",
            borderColor:" #EDEFF0",
        },
        alertMain: {
          marginLeft: "70%",
          padding: "0px 0px 0px 0px",
          borderRadius:" 16px 0px 0px 16px",
          backgroundColor: '#ffffff',
      },
      alertClose:{
        display: 'none',
      },
      test:{
      }
    })
);

interface responseType {
  config: object;
  data: object;
  headers: object;
  requests: object;
  status: object;
  statusText: object;
}

export default function CompareScenarios() {
    const classes = useStyles();
    const [ stateValue, dispatch ] = useStateValue();
    const Selectscenariooption1 = {
        default: stateValue.scenarioCompareoption.scenarioCompare1,
        name: "scenarioCompare1",
        value: stateValue.scenario.value,
    };
    const Selectscenariooption2 = {
        default: stateValue.scenarioCompareoption.scenarioCompare2,
        name: "scenarioCompare2",
        value: stateValue.scenario.value,
    };

    const [open, setOpen] = useState(true);


const handleClick = () => {
  setOpen(!open)
}
// console.log(open);



    return (
 <div className= {stateValue.resulttabs ? classes.test : classes.alertClose}>
        <Typography variant="h5">Comparaison between :</Typography>
    <Box component="span" sx={{ p: 2 }} className={classes.root}>
        <div className={classes.root}>
        <SelectInput  {...Object.assign({},Selectscenariooption1,{"Tabtype":"compare"})} />
        <SelectInput  {...Object.assign({},Selectscenariooption2,{"Tabtype":"compare"})}/>
        
        </div>
    </Box>
    <div/>
        </div>
       
    );
}
