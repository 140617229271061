import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StateProvider, useStateValue } from "../../../Store/Store";
import { KeyboardHideSharp } from "@material-ui/icons";
import { DecimalTwoDigit } from "../../../tools/timeutil";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    rowDiff: {
        background:"#FF9789",
    },
    row: {
    },
});

function createData(name: string) {
    return { name };
}

export default function GlobalParameters() {
    const classes = useStyles();
    const [state, setState] = React.useState<any>({ globalResult: "" });
    // unit save object parameter.
    const [stateValue, dispatch] = useStateValue();

    useEffect(() => {
        if (
            Object.keys(stateValue.scenarioResults).length !== 0 &&
            "global_block" in stateValue.scenarioResults.scenarioResult_1
        ) {
            // console.log("pass", stateValue.scenarioResults);
            setState((prevState: any) => {
                return {
                    ...prevState,
                    globalResult: stateValue.scenarioResults,
                };
            });
        }
    }, [stateValue.scenarioResults]);
    const { globalResult } = state;
    const rows: any = [];
   
    const resultRows: any = [];
    const keys = Object.keys(globalResult);

    const rows2: any = [];

    if (globalResult) {
        var i = 0;
        let count: any = Object.keys(globalResult).length > 1 ? Object.keys(globalResult).length : 1;

        for (let scenario in globalResult) {
            i++;
            // console.log("scheamriolist",globalResult[scenario].global_block);
            // console.log(globalResult[scenario].global_block);
            globalResult[scenario].global_block.map((key: any, index:any) => {
                
                var obj2: any = {};
                if ("scenarioResult_1" === scenario) {
                    obj2.name = key.name;
                    obj2.unit = key.measured_in;
                    obj2.scenario_1 = DecimalTwoDigit(key.value);
                    if (count >= 2) {
                        // obj2.scenario_2 = globalResult["scenarioResult_2"]!.global_block[key[0]];
                        for (let j = 2; j <= count; j++) {
                            if (
                                globalResult["scenarioResult_" + j] && Object.keys(globalResult["scenarioResult_" + j]).length !== 0 ) {
                                obj2[`scenario_${j}`] = DecimalTwoDigit(globalResult["scenarioResult_" + j]!.global_block[index].value);
                                // console.log(globalResult["scenarioResult_" + j]!.global_block[index].value);
                                if(obj2[stateValue.scenarioCompareoption.scenarioCompare1] !== obj2[stateValue.scenarioCompareoption.scenarioCompare2]){
                                    obj2[`scenario_diff`] = 1;
                                }else{
                                    obj2[`scenario_diff`] = 0;
                                }
                            }else{
                                globalResult["scenarioResult_" + j] = '';
                              }
                        }
                    }

                }
                rows2.push(obj2);
            });
            break;
        }

        return (
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                        <TableCell>Variable name</TableCell>
                        {keys.map((row: any, index: any) => (
                            <TableCell key ={index} align="right">{`Scenario_${index +1}`}</TableCell>
                            ))}
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                    
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows2.map((row: any, index : any) => (
                            <TableRow key={index} className={ row.scenario_diff ? classes.rowDiff : classes.row}>
                                <TableCell component="th" scope="row">
                                    {row.name}{row.unit !== "na" ? `[${row.unit}]` :"" }
                                </TableCell>
                                <TableCell align="right">{row.scenario_1}</TableCell>
                                <TableCell align="right">{row.scenario_2}</TableCell>
                                <TableCell align="right">{row.scenario_3}</TableCell>
                                <TableCell align="right">{row.scenario_4}</TableCell>
                                <TableCell align="right">{row.scenario_5}</TableCell>
                                <TableCell align="right">{row.scenario_6}</TableCell>
                                <TableCell align="right">{row.scenario_7}</TableCell>
                                <TableCell align="right">{row.scenario_8}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    return <></>;
}
