import { ReactElement, useContext, useEffect } from 'react';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import {
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import LoginComponent from './login-page';
import { UserType } from './types';
import { ProfileContext } from '../../context/profile.context';
import { getTokenScopes } from '../../tools/axios-auth-interceptor';

export function getUserType(groups: string[]): UserType | undefined {
  const {
    REACT_APP_AZURE_ADMIN_ID
  } = process.env;
      return REACT_APP_AZURE_ADMIN_ID && groups.includes(REACT_APP_AZURE_ADMIN_ID)
        ? UserType.APP_ENGINEER_ADMIN
        : UserType.APP_USER;
 
}

export default function AuthenticationProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): ReactElement {
  const { login, error } = useMsalAuthentication(InteractionType.Silent);
  const { accounts, instance } = useMsal();
  const userContext = useContext(ProfileContext);

  useEffect(() => {
    // All scopes to identify the user (name, email, group)
    const AZURE_AD_LOGIN_SCOPES = process.env.REACT_APP_AZURE_SCOPES || '';
    // console.log('AZURE_AD_LOGIN_SCOPES', AZURE_AD_LOGIN_SCOPES);
    const loginRequest = {
      scopes: [...(AZURE_AD_LOGIN_SCOPES.split(',') ?? [])],
    };
    error && login(InteractionType.Redirect, loginRequest);
  }, [error, login]);

  useEffect(() => {
    SetLoggedUser();
    requestAccesToken();
  }, [accounts]);

  const requestAccesToken = () => {
    const accessTokenRequest = {
      scopes: getTokenScopes(),
      account: accounts[0],
    };
    instance.acquireTokenSilent(accessTokenRequest).catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        void instance.acquireTokenRedirect(accessTokenRequest);
      }
      console.log(error);
    }); 
  };

  const SetLoggedUser = () => {
    if (accounts && accounts.length > 0) {
      const idTokenClaims = accounts[0].idTokenClaims as {
        name: string;
        preferred_username: string;
        groups: string[] | undefined;
      };
      if (idTokenClaims.groups) {
        const userType = getUserType(idTokenClaims.groups);

        if (userType !== null) {
          userContext?.setContext({
            name: idTokenClaims.name,
            email: idTokenClaims.preferred_username,
            type: userType,
          });
          return;
        }
      }
    }
  };

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginComponent />
      </UnauthenticatedTemplate>
    </>
  );
}