import { AnyIfEmpty } from "react-redux";

export const Timestamps = (): any => {
    let current = new Date();
    // let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    // let cTime = current.getHours() + ":" + (current.getMinutes()) + ":" + current.getSeconds();
    // let pTime = current.getHours() + ":" + (current.getMinutes()-5) + ":" + current.getSeconds();
    // let dateTime = cDate + ' ' + cTime;
    // let pDateTime = cDate + ' ' + pTime;
    // const timestamp = {
    //     "start_date":pDateTime,
    //     "end_date":dateTime,
    // }

    function convertTZ(date: any, tzString: any) {
        return new Date(
            (typeof date === "string" ? new Date(date) : date).toLocaleString("en-FR", { timeZone: tzString })
        );
    }

    // usage: Asia/Jakarta is GMT+7
    // convertTZ("2012/04/20 10:10:30 +0000", "Asia/Jakarta") // Tue Apr 20 2012 17:10:30 GMT+0700 (Western Indonesia Time)

    // Resulting value is regular Date() object
    // const convertedDate = convertTZ("2012/04/20 10:10:30 +0000", "Asia/Jakarta")
    // convertedDate.getHours(); // 17

    // Bonus: You can also put Date object to first arg
    const date: Date = new Date();
    const abc = date.getTimezoneOffset() * 60 * 1000;
    const strintDate = new Date().toLocaleString("en-FR");
    let milliseconds = Date.parse(strintDate);
    milliseconds = milliseconds - 5 * 60 * 1000;
    const datePrev = new Date(milliseconds);
    function addZero(i:any) {
        if (i < 10) {i = "0" + i}
        return i;
      }
    function AddMinutesToDate(date:any, minutes:any) {
        return new Date(date.getTime() - minutes*60000);
   }
   function DateFromat(date:any) {
    let hUTC = addZero(date.getUTCHours());
   let mUTC = addZero(date.getUTCMinutes());
   let sUTC = addZero(date.getUTCSeconds());
   
    let time = hUTC + ":" + mUTC + ":" + sUTC;
    return time;
}
   var next = AddMinutesToDate(date,5);
 

    const temp1 = convertTZ(datePrev, "Europe/Paris");
    const temp = convertTZ(date, "Europe/Paris"); // current date-time in jakarta.
   
//    let hUTC = addZero(date.getUTCHours());
//    let mUTC = addZero(date.getUTCMinutes());
//    let sUTC = addZero(date.getUTCSeconds());
   
    // let time = hUTC + ":" + mUTC + ":" + sUTC;
    let cDate = temp.getFullYear() + "-" + (temp.getMonth() + 1) + "-" + temp.getDate();
    // let cTime = temp.getHours() - 2 + ":" + temp.getMinutes() + ":" + temp.getSeconds();
    // let pTime = temp1.getHours() - 2 + ":" + temp1.getMinutes() + ":" + temp1.getSeconds();
    let cTime = DateFromat(date);
    let pTime = DateFromat(next);
    // console.log(cDate,"",time);
    let dateTime = cDate + " " + cTime;
    let pDateTime = cDate + " " + pTime;
    // console.log("current",cDate,"",DateFromat(date));
    // console.log("prev",cDate,"",DateFromat(next));

    const timestamp = {
        start_date: pDateTime,
        end_date: dateTime,
        date: cDate,
        Uploaddate: dateTime,
    };
    return timestamp;
};

export const DecimalTwoDigit = (number: any): any => {
    //   return Number.parseFloat(`${number}`).toFixed(2);
    if (number !== Math.floor(number)) {
        let result = (Math.round(number * 100) / 100).toFixed(1);
        if (result && !Number.isNaN(result)) {
            return result;
        }
    }
    // console.log(number);
    return number;
};

export const UnitSaveParameter = (objectParama: any): any => {
    // const [ stateValue, dispatch ] = useStateValue();
    const Gt2 = [objectParama];
    var fields = Object.entries(Gt2[0]).map((key, index) => {
        Object.assign(Gt2[0], { [index]: key[1] });
    });
    // console.log(Gt2[0]);

    const arrayFields = Object.values(Gt2[0]);

    let object = arrayFields.reduce((obj: any, item: any) => Object.assign(obj, { [item.param_id]: item.value }), {});

    return object;
};

export const UnitShowParameter = (objectParama: any): any => {
    // const [ stateValue, dispatch ] = useStateValue();
    const Gt2 = objectParama;
    const arrayFields = Object.values(Gt2);
    // console.log("arrayFields",arrayFields);
    const rows2: any = [];
    if (arrayFields.length !== 0) {
        let test = {};
        const flowerAPIFields: any = arrayFields.reduce((obj: any, item: any) => {
            // console.log(item);
            if (typeof item.throughput != "undefined" && item.throughput != null && item.user_id ) {
                let currentObject1 = {};
                if (item.unit === "631_451_1" && item.tag === "I_631B1001:F1002.") {
                    item.unit = "631_451";

                    currentObject1 = { ...currentObject1, ["unit"]: item.unit };
                    currentObject1 = { ...currentObject1, ["tag"]: item.tag };
                    currentObject1 = { ...currentObject1, ["throughput"]: item.throughput };
                    currentObject1 = { ...currentObject1, ["timestamp"]: item.timestamp };
                    if(item.user_id){
                        currentObject1 = { ...currentObject1, ["user_id"]: item.user_id };
                    }
                    //   test = { ...test, [item.unit]: item };
                } else if (item.unit === "651_1" && item.tag === "I_651B1001:F1015.") {
                    item.unit = "651";
                    currentObject1 = { ...currentObject1, ["unit"]: item.unit };
                    currentObject1 = { ...currentObject1, ["tag"]: item.tag };
                    currentObject1 = { ...currentObject1, ["throughput"]: item.throughput };
                    currentObject1 = { ...currentObject1, ["timestamp"]: item.timestamp };
                    if(item.user_id){
                        currentObject1 = { ...currentObject1, ["user_id"]: item.user_id };
                    }
                } else {
                    currentObject1 = { ...currentObject1, ["unit"]: item.unit };
                    currentObject1 = { ...currentObject1, ["tag"]: item.tag };
                    currentObject1 = { ...currentObject1, ["throughput"]: item.throughput };
                    currentObject1 = { ...currentObject1, ["timestamp"]: item.timestamp };
                    if(item.user_id){
                        currentObject1 = { ...currentObject1, ["user_id"]: item.user_id };
                    }
                }

                test = { ...test, ...currentObject1 };
                // console.log(test);
                rows2.push(currentObject1);
                // return test;
            }
        }, {});
        return rows2;
    }
    return "";
};

export const UnitShowParameterDemo = (objectParama: any): any => {
    // const [ stateValue, dispatch ] = useStateValue();
    const Gt2 = objectParama;

    const arrayFields = Object.values(Gt2);
    // console.log("before",objectParama);

    if (arrayFields.length !== 0) {
        // let objectdata = arrayFields.reduce(
        //     (obj: any, item: any) => Object.assign(obj, { [item.parameter]: item.value }),
        //     {}
        // );
        let test = {};
        const fields: any = arrayFields.reduce((previousObject: any, currentObject: any) => {
            if (typeof currentObject.parameter != "undefined" && currentObject.parameter != null) {
                let currentObject1 = {};
                currentObject1 = { ...currentObject1, ["name"]: currentObject.parameter };
                currentObject1 = { ...currentObject1, ["unit"]: currentObject.measured_in };
                if (
                    currentObject.parameter !== "fo_co2_emission" &&
                    currentObject.parameter !== "hel_co2_emission" &&
                    currentObject.parameter !== "fg_co2_emission" &&
                    currentObject.parameter !== "ng_co2_emission" &&
                    currentObject.parameter !== "sfsg_co2_emission" &&
                    currentObject.parameter !== "gt2_operating_mode" &&
                    currentObject.parameter !== "gt2_electricity_a" &&
                    currentObject.parameter !== "gt2_electricity_b" &&
                    currentObject.parameter !== "netback_HPS" &&
                    currentObject.parameter !== "netback_LPS" &&
                    currentObject.parameter !== "ng_fee" &&
                    currentObject.parameter !== "standby_fees" &&
                    currentObject.parameter !== "steam_hps_coeff_a" &&
                    currentObject.parameter !== "steam_hps_coeff_b" &&
                    currentObject.parameter !== "steam_lps_coeff_a" &&
                    currentObject.parameter !== "steam_lps_coeff_b" &&
                    currentObject.parameter !== "steam_mps_coeff_a" &&
                    currentObject.parameter !== "steam_mps_coeff_b" &&
                    currentObject.parameter !== "boilerfeedwater_price" &&
                    currentObject.parameter !== "elec_fees" &&
                    currentObject.parameter !== "elec_consumption" &&
                    currentObject.parameter !== "b0_hel_model_coeff_a" &&
                    currentObject.parameter !== "b0_hel_model_coeff_b" &&
                    currentObject.parameter !== "b0_ng_model_coeff_a" &&
                    currentObject.parameter !== "b0_ng_model_coeff_b" &&
                    currentObject.parameter !== "b0_sfsg_model_coeff_a" &&
                    currentObject.parameter !== "b0_sfsg_model_coeff_b" &&
                    currentObject.parameter !== "b1_fo_model_coeff_a" &&
                    currentObject.parameter !== "b1_fo_model_coeff_b" &&
                    currentObject.parameter !== "b1_ng_model_coeff_a" &&
                    currentObject.parameter !== "b1_ng_model_coeff_b" &&
                    currentObject.parameter !== "b1_fg_model_coeff_a" &&
                    currentObject.parameter !== "b1_fg_model_coeff_b" &&
                    currentObject.parameter !== "b2_fo_model_coeff_a" &&
                    currentObject.parameter !== "b2_fo_model_coeff_b" &&
                    currentObject.parameter !== "b2_ng_model_coeff_a" &&
                    currentObject.parameter !== "b2_ng_model_coeff_b" &&
                    currentObject.parameter !== "b2_fg_model_coeff_a" &&
                    currentObject.parameter !== "b2_fg_model_coeff_b" &&
                    currentObject.parameter !== "b3_fo_model_coeff_a" &&
                    currentObject.parameter !== "b3_fo_model_coeff_b" &&
                    currentObject.parameter !== "b3_ng_model_coeff_a" &&
                    currentObject.parameter !== "b3_ng_model_coeff_b" &&
                    currentObject.parameter !== "b3_fg_model_coeff_a" &&
                    currentObject.parameter !== "b3_fg_model_coeff_b" &&
                    currentObject.parameter !== "hpt_steam_stage1_model_coeff_a" &&
                    currentObject.parameter !== "hpt_steam_stage1_model_coeff_b" &&
                    currentObject.parameter !== "mpt_steam_stage1_model_coeff_a" &&
                    currentObject.parameter !== "mpt_steam_stage1_model_coeff_b" &&
                    currentObject.parameter !== "mpt_steam_stage2_model_coeff_a" &&
                    currentObject.parameter !== "mpt_steam_stage2_model_coeff_b" &&
                    currentObject.parameter !== "lpt_steam_stage1_model_coeff_a" &&
                    currentObject.parameter !== "lpt_steam_stage1_model_coeff_b"
                ) {
                    currentObject1 = { ...currentObject1, ["value"]: DecimalTwoDigit(currentObject.value) };
                } else {
                    currentObject1 = { ...currentObject1, ["value"]: currentObject.value };
                }
                // currentObject = { ...currentObject, ["value2"]: DecimalTwoDigit(currentObject.value) };
                test = { ...test, [currentObject.param_id]: currentObject1 };
                return test;
            }
        }, {});
        // console.log("after",fields);
        //         const keys = Object.keys(fields);
        // const rows2:any = [];
        //         Object.entries(fields).map((key, index) =>{
        //   const obj2:any={name:""};
        //   obj2.name =  key[1];
        //   rows2.push(obj2);
        // });
        //  console.log("show",rows2);
        return fields;
    }
    return "";
};

export const EnergyModeSubUnitType = (objectParama: any, type: any): any => {
    const Gt2 = objectParama;

    const arrayFields = Object.values(Gt2);
    // console.log("before",objectParama);
    let newArray:any = [];
    if (arrayFields.length !== 0) {
        // let objectdata = arrayFields.reduce(
        //     (obj: any, item: any) => Object.assign(obj, { [item.parameter]: item.value }),
        //     {}
        // );
        let test = {};
  
        const fields: any = arrayFields.map(( currentObject: any, index: any) => {
            let label ="";
            // let count = 1;
            if (typeof currentObject != "undefined" && currentObject != null) {
                // console.log("index",currentObject);
                if (type === currentObject.refinery_units_type) {
                    // console.log("index",count);
                    // console.log("index",currentObject);
                    // switch (count) {
                    //     case 1:
                    //       label = "A";
                    //       break;
                    //     case 2:
                    //       label = "B";
                    //       break;
                    //     case 3:
                    //        label = "C";
                    //       break;
                    //     case 4:
                    //       label = "D";
                    //       break;
                    //     case 5:
                    //       label = "E";
                    //       break;
                    //     case 6:
                    //       label = "F";
                    //       break;
                    //     case 7:
                    //       label = "G";
                    //       break;
                    //       case 8:
                    //         label = "H";
                    //         break;
                    //   }
                    // console.log("object",currentObject);
                    // test = { ...test, ...currentObject };
                    //   currentObject = { ...currentObject, ["index"]: label };
                    newArray.push({currentObject});
                     
                }
                // test = { ...test, [currentObject.param_id]: currentObject1 };
                // console.log(test);
            }
        });
    }
    return newArray;
};

export const EnergyModeSaveData = (objectParama: any): any => {
    const Gt2 = objectParama;
if(Gt2){
    const arrayFields = Object.values(Gt2);
    // console.log("before",objectParama);
    let newArray:any = [];
    if (arrayFields.length !== 0) {
        // let objectdata = arrayFields.reduce(
        //     (obj: any, item: any) => Object.assign(obj, { [item.parameter]: item.value }),
        //     {}
        // );
        let test = {};
  
        const fields: any = arrayFields.map(( currentObject: any, index: any) => {
            let label ="";
            // let count = 1;
            if (typeof currentObject != "undefined" && currentObject != null && currentObject.coeff) {
                // console.log("index",currentObject);
                let currentObject1 ={};
                    // console.log("object",currentObject);
                    // test = { ...test, ...currentObject };
                    currentObject1 = { ...currentObject1, ["tag"]: currentObject.pi_tag };
                    currentObject1 = { ...currentObject1, ["refinery_units_type"]: currentObject.refinery_units_type };
                    currentObject1 = { ...currentObject1, ["coeff"]: currentObject.coeff.toString() };
                    //   currentObject = { ...currentObject, ["index"]: label };
                    newArray.push(currentObject1);
                // test = { ...test, [currentObject.param_id]: currentObject1 };
                // console.log(test);
            }
        });
    }
    return newArray;
}

};

export const DownloadResult = (objectParama: any, block: any, stateValue: any): any => {
    //  console.log("2",block);
    //  console.log("3",stateValue);
    const rows2: any = [];
    let count: any = Object.keys(objectParama).length > 1 ? Object.keys(objectParama).length : 1;
    for (let scenario in objectParama) {
        objectParama[scenario][block].map((key: any, index: any) => {
            var obj2: any = {};
            var obj2: any = {};
            if ("scenarioResult_1" === scenario) {
                obj2.name = key.name;
                obj2.unit = key.measured_in;
                let match1: any = 7;
                let match2: any = 8;
                let match3: any = 9;
                let matchstring1 = key.name.substring(0, match1);
                let matchstring2 = key.name.substring(0, match2);
                let matchstring3 = key.name.substring(0, match3);
                if ("on_off_" == matchstring1) {
                    // console.log(matchstring1);
                    // console.log(key.value);
                    switch (key.value) {
                        case "0.0":
                        case "0":
                        case "False":
                        case 0:
                            key.value = "False";
                            // console.log(key.value);
                            break;
                        case "1.0":
                        case "1":
                        case "True":
                        case 1:
                            key.value = "True";
                            // console.log(key.value);
                            break;
                        default:
                            key.value = key.value;
                            break;
                    }
                }
                if ("op_mode_1" == matchstring3) {
                    switch (key.value) {
                        case "0":
                        case 0:
                            key.value = "N/A";
                            break;
                        case "1":
                        case 1:
                            key.value = "Fixed at minimum (data top)";
                            break;
                        default:
                            key.value = key.value;
                            break;
                    }
                }
                if ("op_mode_2" == matchstring3) {
                    switch (key.value) {
                        case "0":
                        case 0:
                            key.value = "N/A";
                            break;
                        case "1":
                        case 1:
                            key.value = "Operating range (data top)";
                            break;
                        default:
                            key.value = key.value;
                            break;
                    }
                }

                if ("True" === key.value || "False" === key.value) {
                    obj2.scenario_1 = key.value;
                } else if (
                    "Fixed at minimum (data top)" === key.value ||
                    "Operating range (data top)" === key.value ||
                    "N/A" === key.value
                ) {
                    obj2.scenario_1 = key.value;
                } else {
                    obj2.scenario_1 = DecimalTwoDigit(key.value);
                }
                if (count >= 2) {
                    // obj2.scenario_2 = GT2Result["scenarioResult_2"]!.gt2_block[key[0]];
                    for (let j = 2; j <= count; j++) {
                        if (
                            objectParama["scenarioResult_" + j] &&
                            Object.keys(objectParama["scenarioResult_" + j]).length !== 0
                        ) {
                            if ("on_off_" == matchstring1) {
                                switch (objectParama["scenarioResult_" + j]![block][index].value) {
                                    case "0.0":
                                    case "0":
                                    case "False":
                                    case 0:
                                        objectParama["scenarioResult_" + j]![block][index].value = "False";
                                        // console.log(objectParama["scenarioResult_" + j]![block][index].value);
                                        break;
                                    case "1.0":
                                    case "1":
                                    case "True":
                                    case 1:
                                        objectParama["scenarioResult_" + j]![block][index].value = "True";
                                        // console.log(objectParama["scenarioResult_" + j]![block][index].value);
                                        break;
                                    default:
                                        objectParama["scenarioResult_" + j]![block][index].value =
                                            objectParama["scenarioResult_" + j]![block][index].value;
                                        break;
                                }
                            }
                            if ("op_mode_1" == matchstring3) {
                                switch (objectParama["scenarioResult_" + j]![block][index].value) {
                                    case "0":
                                    case 0:
                                        objectParama["scenarioResult_" + j]![block][index].value = "N/A";
                                        break;
                                    case "1":
                                    case 1:
                                        objectParama["scenarioResult_" + j]![block][index].value =
                                            "Fixed at minimum (data top)";
                                        break;
                                    default:
                                        objectParama["scenarioResult_" + j]![block][index].value =
                                            objectParama["scenarioResult_" + j]![block][index].value;
                                        break;
                                }
                            }
                            if ("op_mode_2" == matchstring3) {
                                switch (objectParama["scenarioResult_" + j]![block][index].value) {
                                    case "0":
                                    case 0:
                                        objectParama["scenarioResult_" + j]![block][index].value = "N/A";
                                        break;
                                    case "1":
                                    case 1:
                                        objectParama["scenarioResult_" + j]![block][index].value =
                                            "Operating range (data top)";
                                        break;
                                    default:
                                        objectParama["scenarioResult_" + j]![block][index].value =
                                            objectParama["scenarioResult_" + j]![block][index].value;
                                        break;
                                }
                            }

                            if (typeof objectParama["scenarioResult_" + j][block][index] !== "undefined") {
                                // console.log("value",objectParama["scenarioResult_" + j][block][index]);
                                if (
                                    (objectParama["scenarioResult_" + j][block][index] &&
                                        "True" == objectParama["scenarioResult_" + j][block][index].value) ||
                                    "False" == objectParama["scenarioResult_" + j][block][index].value
                                ) {
                                    obj2[`scenario_${j}`] = objectParama["scenarioResult_" + j]![block][index].value;
                                } else if (
                                    (objectParama["scenarioResult_" + j]![block][index].value &&
                                        "Fixed at minimum (data top)" ===
                                            objectParama["scenarioResult_" + j]![block][index].value) ||
                                    "Operating range (data top)" ===
                                        objectParama["scenarioResult_" + j]![block][index].value ||
                                    "N/A" === objectParama["scenarioResult_" + j]![block][index].value
                                ) {
                                    obj2[`scenario_${j}`] = objectParama["scenarioResult_" + j]![block][index].value;
                                } else {
                                    obj2[`scenario_${j}`] = DecimalTwoDigit(
                                        objectParama["scenarioResult_" + j]![block][index].value
                                    );
                                }
                            }
                            if (
                                obj2[stateValue.scenarioCompareoption.scenarioCompare1] !==
                                obj2[stateValue.scenarioCompareoption.scenarioCompare2]
                            ) {
                                obj2[`scenario_diff`] = 1;
                            } else {
                                obj2[`scenario_diff`] = 0;
                            }
                        } else {
                            objectParama["scenarioResult_" + j] = "";
                        }
                    }
                }
            }
            rows2.push(obj2);
        });
        break;
        // console.log("gaurav", rows2);
    }
    // console.log("final",rows2);
    return rows2;
};
