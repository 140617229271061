import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PowerPlantParameters from './Tabcomponents/rkb/PowerPlantParameters';
import TabButton from './TabButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(120),
        height: '100%',
        borderRadius: '16px',
        padding:'24px',
      },
    },
    fuel:{
      width: theme.spacing(200),
    },
    steam:{
      width: theme.spacing(200),
    },
    Heading:{
      fontWeight: 700,
      fontSize: '1.13rem',
      lineHeight: 1.3,
    }
  }),
);

export default function RKBFieldContainer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Paper elevation={0}>
            <PowerPlantParameters/>
        </Paper>
            {/* <Typography variant="h6" gutterBottom className={classes.Heading}>
            Utility demand
            </Typography> */}
            <TabButton/>
    </div>
  );
}
