import React,{ useState, useContext,useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import TextFields from './TextFields';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { AppContext } from "../../context";
import { NumberInput } from "../NumberInput";
import { APIDataType } from "../../types/ApitParameter";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { useStateValue } from "../../Store/Store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(60),
        height: theme.spacing(16),
        border: '2px solid #F7F9FA',
        borderRadius:' 16px',
        padding:'16px',
        paddingBottom: '0px',

      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    subHeading:{
      fontWeight: 700,
      fontSize:'1rem',
    },
    field:{
      width:'140px',
      height:'38px',
      borderRadius: '8px',
      flexDirection:'row',
    },
    label:{
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
    },
    unit:{
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#707374',
    },
    Network:{
      width: theme.spacing(50),
      height: theme.spacing(14),
    },
    Pendelband:{
      width: theme.spacing(50),
      height: theme.spacing(14),
    },
    Heading:{
      fontWeight: 700,
      fontSize: '1.13rem',
      lineHeight: 1.3,
      paddingLeft: "17px",
      paddingTop: "17px",
    },
    checkboxstyle: {
      width: theme.spacing(40),
      height: theme.spacing(15),
      opacity: "0.5",
  },
  }),
);

export default function ElectricityImportBounds() {
  const classes = useStyles();
 
  const [ stateValue, dispatch ] = useStateValue();

    const [state, setState] = React.useState<any>({
      import_pb_allowed: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
      allow_electricity_import_bounds: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
    });
    
    useEffect(() => {
        if (Object.keys(stateValue.Global).length !== 0) {
            // console.log("useeffect",stateValue.Global);
            setState((prevState:any) => {
                return {
                    ...prevState,
                    allow_electricity_import_bounds: stateValue.Global["allow_electricity_import_bounds"],
                };
            });
        }
    }, [stateValue]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name: any) => {
        let anc = {...stateValue};
        let value = "";
        if(event.target.checked === true){
            value = "1";
        } else{
            value = "0";
        }
        // console.log("checkevent:", event.target.name);
        anc.Global[`${event.target.name}`].value = value;
        dispatch( {
            type: "set",
            ...anc,
        } );
        // setState((prevState:any) => {
        //     return {
        //         ...prevState,
        //         [event.target.name]: {
        //             param_id: fo_allowed.param_id,
        //             parameter: fo_allowed.parameter,
        //             value: event.target.checked,
        //         },
        //     };
        // });
    };

    const booleanValue = (parameter:any) => {
        let testValue;
        if(parameter.value == "1.0"|| parameter.value == "1"){
            testValue = true;
        }
        else{
            testValue = false;
        }
        return testValue;
      }

    const { allow_electricity_import_bounds } = state;
        
  return (
    <>
       <Typography variant="h6" gutterBottom className={classes.Heading}>
    <FormControlLabel
                            control={
                              <Checkbox
                              checked={booleanValue(allow_electricity_import_bounds)}
                              onChange={(event) => handleChange(event, allow_electricity_import_bounds)}
                              name={allow_electricity_import_bounds.parameter}
                          />
                            }
                            label=" Allow Electricity Imports"
                        />
                                </Typography>
    <div className={classes.root}>
        <Paper elevation={0} className={booleanValue(allow_electricity_import_bounds) ? classes.Network : classes.checkboxstyle}>
        {/* <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
        Network
      </Typography> */}
      <form noValidate autoComplete="off">
      {/* <FormControlLabel
                            control={
                                <Checkbox
                                    checked={booleanValue(import_network_allowed)}
                                    onChange={(event) => handleChange(event, import_network_allowed)}
                                    name={import_network_allowed.parameter}
                                />
                            }
                            label="Network"
                            className={classes.Heading}
                        /> */}
      <Grid container spacing={3}>
            <Grid item xs={6}>
            <label className={classes.label} >Network Min<Box className={classes.unit} component="span" m={1}>(MW)</Box></label>
       <NumberInput {...Object.assign( {},stateValue.Global["import_elec_network_min_bnds"],{"Tabtype":"Global"})} />
            </Grid>
            <Grid item xs={6}>
            <label className={classes.label} >Network Max<Box className={classes.unit} component="span" m={1}>(MW)</Box></label>
       <NumberInput {...Object.assign( {},stateValue.Global["import_elec_network_max_bnds"],{"Tabtype":"Global"})} />
            </Grid>
        </Grid>
      </form>
        </Paper>
        <Paper elevation={0} className={booleanValue(allow_electricity_import_bounds) ? classes.Pendelband : classes.checkboxstyle}>
      {/* <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
      Pendelband
      </Typography> */}
      <form noValidate autoComplete="off">
      
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <label className={classes.label} >Pendelband Min<Box className={classes.unit} component="span" m={1}>(MW)</Box></label>
     <NumberInput {...Object.assign( {},stateValue.Global["import_elec_pb_min_bnds"],{"Tabtype":"Global"})} />
        </Grid>
        <Grid item xs={6}>
          <label className={classes.label} >Pendelband Max<Box className={classes.unit} component="span" m={1}>(MW)</Box></label>
     <NumberInput {...Object.assign( {},stateValue.Global["import_elec_pb_max_bnds"],{"Tabtype":"Global"})} />
        </Grid>
      </Grid>
      </form>
        </Paper>
    </div>
     </>
  );
}
