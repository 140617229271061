import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import TotalBackground from '../assets/img/logo/logo-TotalEnergies.png';
import MainHeader from './MainHeader';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { useMsal } from '@azure/msal-react';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor:'#FFFFFF',
      boxShadow:'unset',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color:'#ED0000',
      padding: '12px 8px',
    },
    menuArea:{
        display: 'contents',
    },
    title: {
      marginLeft: theme.spacing(4),
      flexGrow: 1,
      align:'left',
      color:'#ED0000',
      padding: '12px 8px',
    },
    mainBar: {
        backgroundColor:'#FFFFFF',
      },
    button: {
        backgroundColor:'#FFFFFF',
      },
      imgLogo: {
        maxWidth: "6%",
    },
    appBarDivider:{
      // flex: 1,
    },
    Toolbar:{
      alignItems: 'flex-start',
    }
  }),
);

export default function Appbar() {
  const classes = useStyles();
  const { accounts, instance } = useMsal();

  return (
    <div className={classes.root} >
      <AppBar position="static" className={classes.mainBar} elevation={0} >
        <Toolbar className={classes.Toolbar}>
        <img src={TotalBackground} alt="total-energies-background" className={classes.imgLogo} />
            <Box component="span" m={1} className={classes.menuArea}>
                <Typography variant="h6" className={classes.title}>
                    NRJEZER
                </Typography>
                <Button color="inherit" className={classes.menuButton} >{accounts[0].name}</Button>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <AccountCircleOutlinedIcon />
                </IconButton>
            </Box>
           
    </Toolbar>
   
      </AppBar>
    </div>
  );
}
