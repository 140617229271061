import React, { ChangeEvent } from "react";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import * as FileSaver from "file-saver";
// import { createAxiosInstance } from "../tools/axios-auth-interceptor";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
// import { myConstClass } from "../constants";
import { useStateValue } from "../../../Store/Store";
import XLSX from "sheetjs-style";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import { DownloadResult } from "../../../tools/timeutil";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
            marginLeft: "55%",
        },
        input: {
            display: "none",
        },
        button: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            color: "#ffff",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: "#ED0000",
            borderColor: " #ED0000",
        },
        Select: {
            color: "#ED0000",
            gap: "8px",
            // backgroundColor:"#ED0000",
            border: "2px solid red",
            borderRadius: "40px",
            borderColor: " #ED0000",
            backgroundColor: "#ffffff",
        },
        disableButton: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            color: "#000000",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: "#EDEFF0",
            borderColor: " #EDEFF0",
        },
        disableSelect: {
            color: "#000000",
            gap: "8px",
            backgroundColor: "#EDEFF0",
            borderColor: " #EDEFF0",
            minWidth: "120px",
            borderRadius: "40px",
        },
        alertMain: {
            marginLeft: "70%",
            padding: "0px 0px 0px 0px",
            borderRadius: " 16px 0px 0px 16px",
            backgroundColor: "#ffffff",
        },
        alertClose: {
            display: "none",
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        formControltDisable: {
            margin: theme.spacing(1),
            minWidth: 120,
            pointerEvents: "none",
        },
        selectLabel: {
            color: "#ED0000",
        },
        selectLabelDisable: {
            color: "#000000",
        },
    })
);

interface responseType {
    config: object;
    data: object;
    headers: object;
    requests: object;
    status: object;
    statusText: object;
}

const DownloadButton = ({ excelData, fileName }: any) => {
    const classes = useStyles();
    const [stateValue, dispatch] = useStateValue();
    const [state, setState] = React.useState<{ age: string | number; name: string }>({
        age: "",
        name: "hai",
    });
    const [age, setAge] = React.useState("");

    const [open, setOpen] = useState(true);
    const [stateDemo, setDemoState] = React.useState<any>({ globalResultparameter: "" });
    const [stateResult, setStateResult] = React.useState<any>({ globalResult: "" });
    const [exportDataTest, setExportDataTest] = useState<any | undefined>([]);
    // let ExcelExportData: any = [{}];
    useEffect(() => {
        if (Object.keys(stateValue.scenarioResults).length !== 0) {
            setDemoState((prevState: any) => {
                return {
                    ...prevState,
                    globalResultparameter: stateValue?.scenarioOptimParamResultsDemo,
                };
            });
        }
    }, [stateValue.scenarioResults]);
    // const {globalResult} = state;
    const { globalResultparameter } = stateDemo;
    useEffect(() => {
        if (Object.keys(stateValue.scenarioResults).length !== 0) {
            setStateResult((prevState: any) => {
                return {
                    ...prevState,
                    globalResult: stateValue?.scenarioResults,
                };
            });
        }
    }, [stateValue.scenarioResults]);
    //   useEffect(() => {
    //     dispatch({
    //         type: "set",
    //         exportData: exportDataTest,
    //     });
    // }, [exportDataTest]);
    // const {globalResult} = state;
    const { globalResult } = stateResult;
    //result download logic
    const globalBlock = DownloadResult(globalResult, "global_block", stateValue);
    const gt2Block = DownloadResult(globalResult, "gt2_block", stateValue);
    const rkbBlock = DownloadResult(globalResult, "rkb_block", stateValue);
    const final = { ...Object.assign({ global: globalBlock },{ gt2: gt2Block }, { rkb: rkbBlock }) };
    let ExcelExportDataResult = final;
    let data = { ...stateValue };

    // if(ExcelExportData){
    //   data.exportData = ExcelExportData;
    //   dispatch({
    //     type: "set",
    //     ...data,
    // });
    // }

    const rows2: any = [];
    let count: any = Object.keys(globalResultparameter).length > 1 ? Object.keys(globalResultparameter).length : 1;
    // console.log(globalResult);
    for (let scenario in globalResultparameter) {
        // console.log(scenario);
        Object.entries(globalResultparameter[scenario]).map((key: any, index) => {
            // console.log(key[1].name);
            // console.log(keySub);
            var obj2: any = {};
            if ("scenarioOptimizeParashow_1" === scenario) {
                obj2.name = key[1].name;
                obj2.unit_1 = key[1].unit;
                let match1: any = 6;
                let match2: any = 7;
                let matchstring1 = key[1].name.substring(key[1].name.length - match1);
                let matchstring2 = key[1].name.substring(key[1].name.length - match2);
                // console.log(matchstring1);
                if ("active" == matchstring1 || "allowed" == matchstring2) {
                    switch (key[1].value) {
                        case "0.0":
                        case "0":
                        case "False":
                        case 0:
                            key[1].value = "False";
                            break;
                        case "1.0":
                        case "1":
                        case "True":
                        case 1:
                            key[1].value = "True";
                            break;
                        default:
                            key[1].value = key[1].value;
                            break;
                    }
                }
                if ("gt2_operating_mode" === key[1].name) {
                    switch (key[1].value) {
                        case "0":
                        case 0:
                            key[1].value = "Fixed at minimum (data top)";
                            break;
                        case "1":
                        case 1:
                            key[1].value = "Operating range (data top)";
                            break;
                        case "2" || 2:
                            key[1].value = "Let optimizer choose";
                            break;
                        default:
                            key[1].value = key[1].value;
                            break;
                    }
                }
                // console.log(key[1]);
                obj2.scenario_1 = key[1].value;
                if (count >= 2) {
                    // obj2.scenario_2 = globalResultparameter["scenarioResult_2"]![key[0]];
                    for (let j = 2; j <= count; j++) {
                        if (
                            globalResultparameter["scenarioOptimizeParashow_" + j] &&
                            Object.keys(globalResultparameter["scenarioOptimizeParashow_" + j]).length !== 0
                        ) {
                            // console.log( globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value);
                            if ("active" == matchstring1 || "allowed" == matchstring2) {
                                let match = globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value;
                                // console.log(match);
                                switch (match) {
                                    case "0.0":
                                    case "0":
                                    case "False":
                                    case 0:
                                        globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value = "False";
                                        // console.log(globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value);
                                        break;
                                    case "1.0":
                                    case "1":
                                    case "True":
                                    case 1:
                                        globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value = "True";
                                        break;
                                    default:
                                        globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value =
                                            globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value;
                                        break;
                                }
                            }
                            // console.log("match",globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value);
                            // console.log("match1",globalResultparameter["scenarioOptimizeParashow_" + j][key[0]]);
                            if ("gt2_operating_mode" == key[0]) {
                                let match = globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value;
                                // console.log(match);
                                switch (match) {
                                    case "0":
                                    case 0:
                                        globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value =
                                            "Fixed at minimum (data top)";
                                        break;
                                    case "1":
                                    case 1:
                                        globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value =
                                            "Operating range (data top)";
                                        break;
                                    case "2":
                                    case 2:
                                        globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value =
                                            "Let optimizer choose";
                                        break;
                                    default:
                                        globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value =
                                            globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value;
                                        break;
                                }
                            }
                            obj2[`scenario_${j}`] =
                                globalResultparameter["scenarioOptimizeParashow_" + j]![key[0]].value;
                            // if (
                            //     obj2[stateValue.scenarioCompareoption.scenarioCompare1] !==
                            //     obj2[stateValue.scenarioCompareoption.scenarioCompare2]
                            // ) {
                            //     obj2[`scenario_diff`] = 1;
                            // } else {
                            //     obj2[`scenario_diff`] = 0;
                            // }
                        } else {
                            globalResultparameter["scenarioOptimizeParashow_" + j] = "";
                        }
                    }
                }
            }
            rows2.push(obj2);
        });
        break;
        // console.log("gaurav", rows2);
    }
    const exportData = Object.values(rows2);
    let ExcelExportDataInput = exportData;

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    const fileExtention = ".xlsx";

    const ExporttoExcel = async (fileName: any, ExcelExportData: any) => {
        // console.log(ExcelExportData);
        var wb = XLSX.utils.book_new();

        if (ExcelExportData.hasOwnProperty("global")) {

            /* create a worksheet for books */
            var wsglobal = XLSX.utils.json_to_sheet(ExcelExportData.global);

            /* Add the worksheet to the workbook */
            XLSX.utils.book_append_sheet(wb, wsglobal, "GlobalResult");

            /* create a worksheet for person details */
            var wsGT2 = XLSX.utils.json_to_sheet(ExcelExportData.gt2);

            /* Add the worksheet to the workbook */
            XLSX.utils.book_append_sheet(wb, wsGT2, "Gt2Result");

            var wsRKB = XLSX.utils.json_to_sheet(ExcelExportData.rkb);

            /* Add the worksheet to the workbook */
            XLSX.utils.book_append_sheet(wb, wsRKB, "RKBResult");
        } else {
            /* create a worksheet for books */
            var wsInput = XLSX.utils.json_to_sheet(ExcelExportData);

            /* Add the worksheet to the workbook */
            XLSX.utils.book_append_sheet(wb, wsInput, "OptimizeParamaeters");
        }
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtention);
    };
    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        event.preventDefault();
        const name = event.target.name as keyof typeof state;
        setAge(event.target.value as string);
        setState({
            ...state,
            [name]: event.target.value,
        });
        let data = { ...stateValue };
        data.downloadOption = event.target.value;
        dispatch({
            type: "set",
            ...data,
        });
        if ("input" === event.target.value) {
            ExporttoExcel("InputParameters", ExcelExportDataInput);
        } else {
            ExporttoExcel("Results", ExcelExportDataResult);
        }
    };

    return (
        <Box component="span" sx={{ p: 2, marginLeft: "50%" }} className={classes.root}>
            <FormControl
                variant="outlined"
                className={stateValue.resulttabs ? classes.formControl : classes.formControltDisable}
                form-testid= {true === stateValue.resulttabs ? "DownloadButtonactive":"DownloadButton"}
            >
                <InputLabel
                    id="demo-simple-select-outlined-label"
                    className={stateValue.resulttabs ? classes.selectLabel : classes.selectLabelDisable}
                    form-label={true === stateValue.resulttabs ? "Download-label":"Download-label-disable"}
                >
                    {" "}
                    Download
                </InputLabel>
                <Select
                    className={stateValue.resulttabs ? classes.Select : classes.disableSelect}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={age}
                    onChange={handleChange}
                    label="Download"
                >
                    {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
                    <MenuItem value={"input"}>Download parameters values</MenuItem>
                    <MenuItem value={"result"}>Download optimization results</MenuItem>
                </Select>
            </FormControl>

            {/* </div> */}
        </Box>
    );
};

export default DownloadButton;
