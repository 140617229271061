import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { createAxiosInstance } from "../tools/axios-auth-interceptor";


interface APIDataType {
    id: number;
    email: string;
    first_name: string;
    optim_id: number;
    optim_output: null;
    value: number;
    timestamp: string;
    param_id: number;
    units_id: number;
    parameter: string;
    measured_in: String;
}

interface responseType {
    config: object;
    data: APIDataType[];
    headers: object;
    requests: object;
    status: object;
    statusText: object;
}



 

export async function fetchData<T>(url: string): Promise<AxiosResponse<T>> {

    return (await createAxiosInstance()).get<T>(url);

}

export async function DeleteData<T>(url: string): Promise<AxiosResponse<T>> {

    return (await createAxiosInstance()).delete<T>(url);

}


    //  const fetchData = async (url: any) => {
    //     // const url = 'https://azwbdnrjezer01.azurewebsites.net/parameters/unit?unit_ids=1&optim_id=0';
    //     // const response: Promise<AxiosResponse<responseType[]> | undefined | any> = (await createAxiosInstance()).get<
    //     //     Array<responseType>
    //     // >(url);
    // //    return response;
    //    return (await createAxiosInstance()).get<Array<responseType>>(url);

    // };

    // export default fetchData;

