import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StateProvider, useStateValue } from "../../../Store/Store";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    rowDiff: {
        background: "#FF9789",
    },
    row: {},
});

export default function OptimizationParameters() {
  const classes = useStyles();
  const [stateDemo, setDemoState] = React.useState<any>({globalResultDemo: ""});
  const [ stateValue, dispatch ] = useStateValue();

useEffect(() => {
  if (Object.keys(stateValue.scenarioResults).length !== 0 ) {
    //   console.log("pass",stateValue.scenarioResults);
      setDemoState((prevState:any) => {
          return {
              ...prevState,
              globalResultDemo:stateValue?.scenarioOptimParamResultsDemo, 
          };
      });
  }
}, [stateValue.scenarioResults]);
// const {globalResult} = state;
const {globalResultDemo} = stateDemo;
const keys = Object.keys(globalResultDemo);
// const keysDemo = Object.keys(globalResultDemo);
const rows2:any = [];
// console.log(globalResultDemo);
if (globalResultDemo) {
// Object.entries(globalResult).map((key, index) =>{
//   const obj2:any={name:"",value:""};
//   obj2.name =  key[0];
//   obj2.value =  key[1];
//   rows2.push(obj2);
// });
// console.log("opt",rows2);
let count: any = Object.keys(globalResultDemo).length > 1 ? Object.keys(globalResultDemo).length : 1;
// console.log(globalResult);
for (let scenario in globalResultDemo) {
  // console.log(scenario);
        Object.entries(globalResultDemo[scenario]).map((key: any, index) => {
    // console.log("input",key);
        // console.log(keySub);
            var obj2: any = {};
                if ("scenarioOptimizeParashow_1" === scenario) {
                            obj2.name = key[1].name;
                            let match1: any = 6;
                            let match2: any = 7;
                            let matchstring1 = key[1].name.substring(key[1].name.length - match1);
                            let matchstring2 = key[1].name.substring(key[1].name.length - match2);
                            // console.log(matchstring1);
                            if (( "active" == matchstring1) || "allowed" == matchstring2) {
                                switch (key[1].value) {
                                    case "0.0":
                                    case "0": 
                                    case "False":
                                    case 0 :
                                        key[1].value = "False";
                                        break;
                                        case "1.0":
                                        case "1": 
                                        case "True":
                                        case 1 :
                                        key[1].value = "True";
                                        break;
                                    default:
                                        key[1].value = key[1].value;
                                        break;
                                }
                            }
                            if ("gt2_operating_mode" === key[1].name) {
                                switch (key[1].value) {
                                    case "0":
                                    case 0:
                                        key[1].value = "Fixed at minimum (data top)";
                                        break;
                                        case "1":
                                        case 1:
                                        key[1].value = "Operating range (data top)";
                                        break;
                                    case "2" || 2:
                                        key[1].value = "Let optimizer choose";
                                        break;
                                    default:
                                        key[1].value = key[1].value;
                                        break;
                                }
                            }
                            // console.log(key[1]);
                            obj2.scenario_1 = key[1].value;
                            obj2.unit_1 = key[1].unit;
                            if (count >= 2) {
                                // obj2.scenario_2 = globalResultDemo["scenarioResult_2"]![key[0]];
                                for (let j = 2; j <= count; j++) {
                                    if (
                                    globalResultDemo["scenarioOptimizeParashow_" + j] && Object.keys(globalResultDemo["scenarioOptimizeParashow_" + j]).length !== 0 ) {
                                        // console.log( globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value);
                                        if (( "active" == matchstring1) || "allowed" == matchstring2) {
                                            let match = globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value;
                                            // console.log(match);
                                            switch (match) {
                                                case "0.0":
                                                case "0": 
                                                case "False":
                                                case 0 :
                                                    globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value = "False";
                                                    // console.log(globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value);
                                                    break;
                                                    case "1.0":
                                                    case "1": 
                                                    case "True":
                                                    case 1 :
                                                    globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value = "True";
                                                    break;
                                                default:
                                                    globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value = globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value;
                                                    break;
                                            }
                                        }
                                        // console.log("match",globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value);
                                        // console.log("match1",globalResultDemo["scenarioOptimizeParashow_" + j][key[0]]);
                                        if ("gt2_operating_mode" == key[1].name) {
                                        let match = globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value;
                                        // console.log(match);
                                        switch (match) {
                                            case "0":
                                            case 0:
                                                globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value = "Fixed at minimum (data top)";
                                                break;
                                            case "1":
                                            case 1:
                                                globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value = "Operating range (data top)";
                                                break;
                                            case "2":
                                                case 2:
                                                globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value = "Let optimizer choose";
                                                break;
                                            default:
                                                globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value = globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value;
                                                break;
                                        }
                                    }
                                    obj2[`scenario_${j}`] = globalResultDemo["scenarioOptimizeParashow_" + j]![key[0]].value;
                                        if(obj2[stateValue.scenarioCompareoption.scenarioCompare1] !== obj2[stateValue.scenarioCompareoption.scenarioCompare2]){
                                        obj2[`scenario_diff`] = 1;
                                    }else{
                                        obj2[`scenario_diff`] = 0;
                                    }
                                    }else{
                                    globalResultDemo["scenarioOptimizeParashow_" + j] = '';
                                    }
                                }
                            }
                    }
                rows2.push(obj2);
        });
        break;
            // console.log("gaurav", rows2);
    }

        return (
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Parameter Name</TableCell>
                            {keys.map((row: any, index: any) => (
                                <TableCell key={index} align="right">{`Scenario_${index + 1}`}</TableCell>
                            ))}
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows2.map((row: any, index: any) => (
                            <TableRow key={index} className={row.scenario_diff ? classes.rowDiff : classes.row}>
                                <TableCell component="th" scope="row">
                                    {row.name}  {row.unit_1 !== "na" ? `[${row.unit_1}]` :"" }
                                </TableCell>
                                <TableCell align="right">{row.scenario_1}</TableCell>
                                <TableCell align="right">{row.scenario_2}</TableCell>
                                <TableCell align="right">{row.scenario_3}</TableCell>
                                <TableCell align="right">{row.scenario_4}</TableCell>
                                <TableCell align="right">{row.scenario_5}</TableCell>
                                <TableCell align="right">{row.scenario_6}</TableCell>
                                <TableCell align="right">{row.scenario_7}</TableCell>
                                <TableCell align="right">{row.scenario_8}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    return <></>;
}
