import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useStateValue } from "../Store/Store";
import { fetchData } from "../API/fetchAPI";
import { myConstClass } from "../constants";
import { DecimalTwoDigit } from "../tools/timeutil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 240,
            minHeight: 40,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        select: {
            backgroundColor: "#fff",
        },
    })
);

const SelectInput = (props: any): any => {
    const classes = useStyles();
    const [stateValue, dispatch] = useStateValue();
    const [option, setOption] = useState<any | undefined>(props.default || "");

    const options = props.value;
    //  const {value: {3}} = props;

     useEffect(() => {
      setOption(props.default);
  }, [props]);
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        // setOption(event.target.value as string);
        let anc = { ...stateValue };
        if("compare" == props.Tabtype && "scenarioCompare1" === props.name){
            console.log("compare1",event.target.value);
            anc.scenarioCompareoption["scenarioCompare1"] = event.target.value;
        }
        if( "compare" == props.Tabtype && "scenarioCompare2" === props.name){
            anc.scenarioCompareoption["scenarioCompare2"] = event.target.value;
        }
        if("GT2" == props.Tabtype){
            // console.log(anc.GT2);
            anc.GT2[props.name].value = event.target.value;
        }
        if("RKB" == props.Tabtype){
          // console.log(anc.GT2);
          anc.RKB[props.name].value = event.target.value;
      }
      if("result" == props.Tabtype){
        if (anc.scenario) {
          anc.scenario.default = event.target.value;
      }
      let optid;
      // console.log("scenario event");
      if(stateValue.optimID){
          console.log(stateValue.optimID);
          console.log(stateValue.scenarioDefault);
          optid = stateValue.optimID[stateValue.scenario.default];
      } else{
          optid = "";
      }
      GlobalOptimResult(optid,stateValue.scenario.default);
      GT2OptimResult(optid,stateValue.scenario.default);
      RKBOptimResult(optid,stateValue.scenario.default);
    }
        dispatch({
            type: "set",
            ...anc,
        });
    };
    // useEffect(() => {
      
    // }, [stateValue.scenario.default]);
    const OptimizerId =(optimID:any, scenarioDefault:any) => {
        if(optimID){
            let optid = optimID[scenarioDefault];
            return optid;
        } else{
            return "";
        }
    }
    const GlobalOptimResult =(optid:any,scenarioDefault:any) => {
        if(stateValue.scenario.default && "" !== optid && optid !== undefined ){
            fetchData<any>(`${myConstClass.GLOBAL_API}${ OptimizerId(stateValue.optimID,scenarioDefault) ? OptimizerId(stateValue.optimID,scenarioDefault) : '1'}`)
            .then((response: any) => {
                // console.log(response);
                // let fields = response.data.reduce(
                //     (obj: any, item: any) => Object.assign(obj, { [item.parameter]: item }),
                //     {}
                // );
                let test = {};
                const fields: any = response.data.reduce((previousObject: any, currentObject: any) => {
                    if (typeof currentObject.parameter != "undefined" && currentObject.parameter != null) {
                        if (
                            currentObject.parameter !== "fo_co2_emission" &&
                            currentObject.parameter !== "hel_co2_emission" &&
                            currentObject.parameter !== "fg_co2_emission" &&
                            currentObject.parameter !== "ng_co2_emission" &&
                            currentObject.parameter !== "sfsg_co2_emission"
                        ) {
                            currentObject = { ...currentObject, ["value"]: DecimalTwoDigit(currentObject.value) };
                        }
                        test = { ...test, [currentObject.parameter]: currentObject };
                        return test;
                    }
                }, {});
                // setResposeGlobal(fields);
                // let anc = { ...stateValue };
                // anc.Global = fields;
                dispatch({
                    type: "set",
                    Global:fields,
                });
                console.log("global",fields);
            })
            .catch((error: any) => {
                console.log(error);
            });
        }
    }
    const GT2OptimResult =(optid:any,scenarioDefault:any) => {
             if(stateValue.scenario.default && "" !== optid && optid !== undefined ){
                console.log("optid",optid);
                console.log("optid",scenarioDefault);
                console.log("function",OptimizerId(optid,scenarioDefault));
            fetchData<any>(`${myConstClass.GT2_API}${ OptimizerId(stateValue.optimID,scenarioDefault) ? OptimizerId(stateValue.optimID,scenarioDefault) : '1'}`)
            .then((response: any) => {
                // console.log(response.data);
                // let fields = response.data.reduce(
                //     (obj: any, item: any) => Object.assign(obj, { [item.parameter]: item }),
                //     {}
                // );
                let test = {};
                const fields: any = response.data.reduce((previousObject: any, currentObject: any) => {
                    if (typeof currentObject.parameter != "undefined" && currentObject.parameter != null) {
                        if (
                            currentObject.parameter !== "gt2_operating_mode" &&
                            currentObject.parameter !== "gt2_electricity_a" &&
                            currentObject.parameter !== "gt2_electricity_b" &&
                            currentObject.parameter !== "netback_HPS" &&
                            currentObject.parameter !== "netback_LPS" &&
                            currentObject.parameter !== "ng_fee" &&
                            currentObject.parameter !== "standby_fees" &&
                            currentObject.parameter !== "steam_hps_coeff_a" &&
                            currentObject.parameter !== "steam_hps_coeff_b" &&
                            currentObject.parameter !== "steam_lps_coeff_a" &&
                            currentObject.parameter !== "steam_lps_coeff_b" &&
                            currentObject.parameter !== "steam_mps_coeff_a" &&
                            currentObject.parameter !== "steam_mps_coeff_b" &&
                            currentObject.parameter !== "boilerfeedwater_price" &&
                            currentObject.parameter !== "elec_fees" &&
                            currentObject.parameter !== "elec_consumption"
                        ) {
                            currentObject = { ...currentObject, ["value"]: DecimalTwoDigit(currentObject.value) };
                        }
                        // console.log(currentObject);
                        test = { ...test, [currentObject.parameter]: currentObject };
                        return test;
                    }
                }, {});
                // setResposeGT2(fields);
                dispatch({
                    type: "set",
                    GT2: fields,
                });
                console.log("fieldsGT2",fields);
            })
            .catch((error: any) => {
                console.log(error);
            });
        }
    }
    const RKBOptimResult =(optid:any,scenarioDefault:any) => {
        if(stateValue.scenario.default && "" !== optid && optid !== undefined ){
     fetchData<any>(`${myConstClass.RKB_API}${ OptimizerId(stateValue.optimID,scenarioDefault) ? OptimizerId(stateValue.optimID,scenarioDefault) : '1'}`)
            .then((response: any) => {
                // console.log(response.data);
                // let fields = response.data.reduce(
                //     (obj: any, item: any) => Object.assign(obj, { [item.parameter]: item }),
                //     {}
                // );
                let test = {};
                const fields: any = response.data.reduce((previousObject: any, currentObject: any) => {
                    if (typeof currentObject.parameter != "undefined" && currentObject.parameter != null) {
                        if (
                            currentObject.parameter !== "b0_hel_model_coeff_a" &&
                            currentObject.parameter !== "b0_hel_model_coeff_b" &&
                            currentObject.parameter !== "b0_ng_model_coeff_a" &&
                            currentObject.parameter !== "b0_ng_model_coeff_b" &&
                            currentObject.parameter !== "b0_sfsg_model_coeff_a" &&
                            currentObject.parameter !== "b0_sfsg_model_coeff_b" &&
                            currentObject.parameter !== "b1_fo_model_coeff_a" &&
                            currentObject.parameter !== "b1_fo_model_coeff_b" &&
                            currentObject.parameter !== "b1_ng_model_coeff_a" &&
                            currentObject.parameter !== "b1_ng_model_coeff_b" &&
                            currentObject.parameter !== "b1_fg_model_coeff_a" &&
                            currentObject.parameter !== "b1_fg_model_coeff_b" &&
                            currentObject.parameter !== "b2_fo_model_coeff_a" &&
                            currentObject.parameter !== "b2_fo_model_coeff_b" &&
                            currentObject.parameter !== "b2_ng_model_coeff_a" &&
                            currentObject.parameter !== "b2_ng_model_coeff_b" &&
                            currentObject.parameter !== "b2_fg_model_coeff_a" &&
                            currentObject.parameter !== "b2_fg_model_coeff_b" &&
                            currentObject.parameter !== "b3_fo_model_coeff_a" &&
                            currentObject.parameter !== "b3_fo_model_coeff_b" &&
                            currentObject.parameter !== "b3_ng_model_coeff_a" &&
                            currentObject.parameter !== "b3_ng_model_coeff_b" &&
                            currentObject.parameter !== "b3_fg_model_coeff_a" &&
                            currentObject.parameter !== "b3_fg_model_coeff_b" &&
                            currentObject.parameter !== "hpt_steam_stage1_model_coeff_a" &&
                            currentObject.parameter !== "hpt_steam_stage1_model_coeff_b" &&
                            currentObject.parameter !== "mpt_steam_stage1_model_coeff_a" &&
                            currentObject.parameter !== "mpt_steam_stage1_model_coeff_b" &&
                            currentObject.parameter !== "mpt_steam_stage2_model_coeff_a" &&
                            currentObject.parameter !== "mpt_steam_stage2_model_coeff_b" &&
                            currentObject.parameter !== "lpt_steam_stage1_model_coeff_a" &&
                            currentObject.parameter !== "lpt_steam_stage1_model_coeff_b"
                        ) {
                            currentObject = { ...currentObject, ["value"]: DecimalTwoDigit(currentObject.value) };
                        }
                        // console.log(currentObject);
                        test = { ...test, [currentObject.parameter]: currentObject };
                        return test;
                    }
                }, {});
                // setResposeRKB(fields);
                let anc = { ...stateValue };
                anc.RKB = fields;
                dispatch({
                    type: "set",
                    RKB: fields,
                });
                console.log("fields3",fields);
            })
            .catch((error: any) => {
                console.log(error);
            });

        }
    }

    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                <Select
                    id="demo-simple-select-outlined"
                    value={option}
                    className={classes.select}
                    // value ="'Let optimizer choose"
                    onChange={handleChange}
                >
                    {Object.entries(options).map((key: any, value: any) => {
                        return <MenuItem value={key[0]}>{key[1]}</MenuItem>;
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

export default SelectInput;
