import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AllUnits from '../Process/AllUnits';
import EachUnits from '../Process/EachUnits';
import Energyunits from './Energyunits';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: "95%",
        height: '100%',
        borderRadius: '16px',
        // padding:'24px',
        paddingLeft: "15px",
        
      },
    },
    fuel:{
      width: theme.spacing(200),
    },
    Steam: {
        width: theme.spacing(220),
        height: theme.spacing(14),
    },
    Heading:{
      fontWeight: 700,
      fontSize: '1.13rem',
      lineHeight: 1.3,
    },
    test:{
     backgroundColor:'unset',
    }
  }),
);

export default function EnergyModelFIeldContainer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Paper elevation={0}>
        {/* <AllUnits/> */}
        </Paper>
        <Paper elevation={0} className={classes.test}>
      <Energyunits/>
        </Paper>
    </div>
  );
}
