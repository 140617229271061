import React, { ChangeEvent } from "react";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
// import { createAxiosInstance } from "../tools/axios-auth-interceptor";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
// import { myConstClass } from "../constants";
import { Alert, AlertTitle } from "@material-ui/lab";
import { File } from "buffer";
import { useStateValue } from "../../../Store/Store";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: "none",
        },
        button: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            color: "#ffff",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: "#ED0000",
            borderColor: " #ED0000",
            fontSize: "1rem",
        },
        disableButton: {
            marginTop: "4px",
            paddingTop: "16px",
            paddingBottom: "16px",
            marginLeft: "0px",
            marginRight: " 0px",
            marginBottom: "0px",
            color: "#000000",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: "#EDEFF0",
            borderColor: " #EDEFF0",
            minWidth:"120px",
            fontSize: "1rem",
        },
        alertMain: {
            marginLeft: "70%",
            padding: "0px 0px 0px 0px",
            borderRadius: " 16px 0px 0px 16px",
            backgroundColor: "#ffffff",
        },
        alertClose: {
            display: "none",
        },
    })
);

interface responseType {
    config: object;
    data: object;
    headers: object;
    requests: object;
    status: object;
    statusText: object;
}

export default function SaveScenario() {
    const classes = useStyles();
    const [stateValue, dispatch] = useStateValue();

    const [FileList, setFileList] = useState<File | null | any>(null);
    const [alertopen, setAlertOpen] = React.useState(false);
    const [open, setOpen] = useState(true);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFileList(e.target.files[0]);
        }
    };

    // useEffect(() => {
    //     postFile(myConstClass.FlowerImport_API);
    // }, [FileList]);

    const handleUploadClick = () => {
        if (!FileList && null === FileList) {
            return;
        }
    };

    //     const postFile = async (url: any) => {

    //               if(FileList){

    //         let formData:any = new FormData();
    //         formData.append('file', FileList);
    //         for (const [key, value] of formData) {
    //            const out:any = `${key}: ${value}\n`;
    //            console.log(out);
    //           }

    //     const response: Promise<AxiosResponse<responseType[]> | undefined | any> = (await createAxiosInstance()).post(url, formData, {
    //       headers: {
    //           "Content-Type": "multipart/form-data",
    //       },
    //   });
    //   response.then(function (response) {
    //     console.log(response);
    //     setAlertOpen(true);

    //   }).catch(function (error) {
    //     console.log(error);
    //   });
    // }

    // };

    const handleClick = () => {
        setOpen(!open);
    };
    // console.log(open);

    return (
        <Box component="span" sx={{ p: 2 }} className={classes.root}>
            {/* <label htmlFor="contained-button-file">
                <Button
                    variant="outlined"
                    color="secondary"
                    component="span"
                    // className={classes.button}
                    className={classes.disableButton}
                    // endIcon={<ExpandMoreIcon />}
                    onClick={handleUploadClick}
                >
                    Save
                </Button>
            </label> */}

          
        </Box>
    );
}
