import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StateProvider, useStateValue } from "../../../Store/Store";
import { DecimalTwoDigit } from "../../../tools/timeutil";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    rowDiff: {
        background: "#FF9789",
    },
    row: {},
});

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function RKBParameters() {
    const classes = useStyles();
    const [state, setState] = React.useState<any>({ RKBResult: "" });
    const [stateValue, dispatch] = useStateValue();

    useEffect(() => {
        if (
            Object.keys(stateValue.scenarioResults).length !== 0 &&
            "rkb_block" in stateValue.scenarioResults.scenarioResult_1
        ) {
            // console.log("pass", stateValue.scenarioResults);
            setState((prevState: any) => {
                return {
                    ...prevState,
                    RKBResult: stateValue.scenarioResults,
                };
            });
        }
    }, [stateValue.scenarioResults]);
    const { RKBResult } = state;

    const rows2: any = [];
    const keys = Object.keys(RKBResult);

    if (RKBResult) {
        var i = 0;
        let count: any = Object.keys(RKBResult).length > 1 ? Object.keys(RKBResult).length : 1;

        for (let scenario in RKBResult) {
            i++;
            // console.log("scheamriolist",RKBResult[scenario].global_block);
            RKBResult[scenario]?.rkb_block.map((key: any, index:any) => {
                // console.log(scenario, "");
                var obj2: any = {};
                if ("scenarioResult_1" === scenario) {
                    obj2.name = key.name;
            obj2.unit = key.measured_in;
                    let match1: any = 7;
                    let match2: any = 8;
                    let matchstring1 = key.name.substring(0,match1);
                    let matchstring2 = key.name.substring(0,match2);
                    // console.log(matchstring1);
                    if (("on_off_" == matchstring1) || "op_mode_" == matchstring2) {
                        // console.log(key.value);
                        switch (key.value) {
                            case "0.0":
                            case "0": 
                            case "False":
                            case 0 :
                                key.value = "False";
                                // console.log(key.value);
                                break;
                                case "1.0":
                                case "1": 
                                case "True":
                                case 1 :
                                key.value = "True";
                                break;
                            default:
                                key.value = key.value;
                                break;
                        }
                    }
                    if("True" === key.value || "False" === key.value){
                        obj2.scenario_1 = key.value;
                    }else{
                        obj2.scenario_1 = DecimalTwoDigit(key.value);
                    }
                    if (count >= 2) {
                        // obj2.scenario_2 = globalResult["scenarioResult_2"]!.rkb_block[key[0]];
                        for (let j = 2; j <= count; j++) {
                            if (
                                RKBResult["scenarioResult_" + j] &&
                                Object.keys(RKBResult["scenarioResult_" + j]).length !== 0
                            ) {
                                if (("on_off_" == matchstring1) || "op_mode_" == matchstring2) {
                                    // console.log(key.value);
                                    switch (RKBResult["scenarioResult_" + j]!.rkb_block[index].value) {
                                        case "0.0":
                                        case "0": 
                                        case "False":
                                        case 0 :
                                            RKBResult["scenarioResult_" + j]!.rkb_block[index].value = "False";
                                            // console.log(RKBResult["scenarioResult_" + j]!.rkb_block[index].value);
                                            break;
                                            case "1.0":
                                            case "1": 
                                            case "True":
                                            case 1 :
                                            RKBResult["scenarioResult_" + j]!.rkb_block[index].value = "True";
                                            break;
                                        default:
                                            RKBResult["scenarioResult_" + j]!.rkb_block[index].value = RKBResult["scenarioResult_" + j]!.rkb_block[index].value;
                                            break;
                                    }
                                }
                                if("True" === RKBResult["scenarioResult_" + j]!.rkb_block[index].value || "False" === RKBResult["scenarioResult_" + j]!.rkb_block[index].value){
                                    obj2[`scenario_${j}`] = RKBResult["scenarioResult_" + j]!.rkb_block[index].value;
                                }else{
                                    obj2[`scenario_${j}`] = DecimalTwoDigit(RKBResult["scenarioResult_" + j]!.rkb_block[index].value);
                                }
                                if (
                                    obj2[stateValue.scenarioCompareoption.scenarioCompare1] !==
                                    obj2[stateValue.scenarioCompareoption.scenarioCompare2]
                                ) {
                                    obj2[`scenario_diff`] = 1;
                                } else {
                                    obj2[`scenario_diff`] = 0;
                                }
                            }else{
                              RKBResult["scenarioResult_" + j] = '';
                            }
                        }
                    }
                }
                rows2.push(obj2);
            });
            break;
        }

        return (
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>variable name</TableCell>
                            {keys.map((row: any, index: any) => (
                                <TableCell key={index} align="right">{`Scenario_${index + 1}`}</TableCell>
                            ))}
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                             <TableCell  align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows2.map((row: any, index: any) => (
                            <TableRow key={index} className={row.scenario_diff ? classes.rowDiff : classes.row}>
                                <TableCell component="th" scope="row">
                                    {row.name}{row.unit !== "na" ? `[${row.unit}]` :"" }
                                </TableCell>
                                <TableCell align="right">{row.scenario_1}</TableCell>
                                <TableCell align="right">{row.scenario_2}</TableCell>
                                <TableCell align="right">{row.scenario_3}</TableCell>
                                <TableCell align="right">{row.scenario_4}</TableCell>
                                <TableCell align="right">{row.scenario_5}</TableCell>
                                <TableCell align="right">{row.scenario_6}</TableCell>
                                <TableCell align="right">{row.scenario_7}</TableCell>
                                <TableCell align="right">{row.scenario_8}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    return <></>;
}
