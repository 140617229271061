import React, { useState, useEffect, useContext } from "react";
import { Theme, createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../../../context";
import { NumberInput } from "../../NumberInput";
import { APIDataType } from "../../../types/ApitParameter";
import Divider from "@material-ui/core/Divider";
import { InputTextTag } from "../../InputTextTag";
import { myConstClass } from "../../../constants";
import useFetch from "../../../API/oldfetchAPI";
import { DecimalTwoDigit } from "../../../tools/timeutil";
import { useStateValue } from "../../../Store/Store";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { fetchData } from "../../../API/fetchAPI";
import IconButton from "@material-ui/core/IconButton";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { EnergyModeSaveData, EnergyModeSubUnitType } from "../../../tools/timeutil";
import { AnyARecord } from "dns";
import { ProfileContext } from "../../../context/profile.context";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subHeading: {
            fontWeight: 700,
            fontSize: "1.1rem",
            width: "70%",
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize: "14px",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "11px",
            lineHeight: "16px",
        },
        tagLabel: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "100%",
            lineHeight: "16px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "95%",
            lineHeight: "16px",
            color: "#707374",
            paddingLeft: "5px",
            paddingRight: "5px",
        },
        unitlabel: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "95%",
            lineHeight: "16px",
            color: "#707374",
            paddingLeft: "5px",
            paddingRight: "5px",
            marginLeft: "unset",
            marginRight: "unset",
        },
        Steam: {
            height: "max-content",
        },
        extraUnit: {
            width: theme.spacing(200),
        },
        Electricity: {
            width: theme.spacing(50),
            height: theme.spacing(14),
        },
        Fuel: {
            width: theme.spacing(140),
            height: theme.spacing(14),
        },
        CO2: {
            width: theme.spacing(30),
            height: theme.spacing(14),
        },
        fuelMain: {
            paddingTop: "12px",
        },
        notEditable: {
             pointerEvents: "none",
        },
        resetIcon: {
            display: "flex",
            justifyContent: "space-between",
        },
        iconButton: {
            color: "#ED0000",
        },
        lastDate: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            lineHeight: "16px",
            color: "#707374",
            paddingLeft: "5px",
            paddingRight: "5px",
            fontWeight: 400,
            fontSize: "1.1rem",
        },
    })
);

export default function EnergyBlock(props: any): any {
    const classes = useStyles();
    const [state, dispatch] = useStateValue();
    const userContext = useContext(ProfileContext);
    const Usertype = userContext?.context?.type;
    
    let open = false;
    let tags = Object.values(props.tags || "");

    const Convertindex = (Parameter: any) => {
        let LPSnewArray: any = [];
        if (Parameter.length !== 0) {
            const fields: any = Parameter.map((currentObject: any, index: any) => {
                let label = "";
                let count = 1;
                // console.log("index",index);
                // if (typeof currentObject != "undefined" && currentObject != null) {
                // console.log("index",count);
                // console.log("index",currentObject);
                switch (index) {
                    case 0:
                        label = "A";
                        break;
                    case 1:
                        label = "B";
                        break;
                    case 2:
                        label = "C";
                        break;
                    case 3:
                        label = "D";
                        break;
                    case 4:
                        label = "E";
                        break;
                    case 5:
                        label = "F";
                        break;
                    case 7:
                        label = "G";
                        break;
                    case 8:
                        label = "H";
                        break;
                }
                // console.log("object",currentObject);
                // test = { ...test, ...currentObject };
                currentObject = { ...currentObject.currentObject, ["index"]: label };
                LPSnewArray.push({ currentObject });
                // test = { ...test, [currentObject.param_id]: currentObject1 };
                // console.log(test);
                // }
            });
        }
        return LPSnewArray;
        // console.log("New data",LPSnewArray);
    };
    let LPS = EnergyModeSubUnitType(tags, "LPS");
    let LPSFields = Convertindex(LPS);
    let MPS = EnergyModeSubUnitType(tags, "MPS");
    let MPSFields = Convertindex(MPS);
    let HPS = EnergyModeSubUnitType(tags, "HPS");
    let HPSFields = Convertindex(HPS);
    let ELEC = EnergyModeSubUnitType(tags, "ELEC");
    let ELECFields = Convertindex(ELEC);
    const [LPSUnit, setLPSUnit] = useState<any | undefined>([]);

    return (
        <>
            <Paper elevation={0} className={Usertype === 'admin' ? classes.extraUnit : classes.notEditable}>
                <Paper elevation={0} className={open ? classes.extraUnit : classes.Steam}>
                    <form noValidate autoComplete="off">
                        <Box className={classes.resetIcon} component="span">
                            <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                                {LPSFields.map((element: any, index: any) => {
                                    return element.currentObject.pi_tag === "NONE" ? (
                                        <>{props.unit}- LPS
                                        <Box className={classes.unit} component="span" m={1}>
                                            = Constant{" "}
                                        </Box>
                                        </>
                                    ) : (
                                        ""
                                    );
                                })}
                                {LPSFields.map((element: any, index: any) => {
                                    return element.currentObject.pi_tag !== "NONE" ? (
                                        <Box className={classes.unit} component="span" m={1}>
                                            {" "}
                                            + {element.currentObject.index} *{" "}
                                            {element.currentObject.pi_tag.slice(0, -1)}{" "}
                                        </Box>
                                    ) : (
                                        ""
                                    );
                                })}
                            </Typography>

                            <Box component="span" m={1} className={classes.lastDate}>
                                {" "}
                                {LPSFields.map((element: any, index: any) => {
                                    if (0 === index) {
                                        return element ? (
                                            <Box component="span" m={6} className={classes.lastDate}>
                                                Last update :
                                                {/* {console.log(element.currentObject.timestamp.substring(0, 10))} */}
                                                {` ${element.currentObject.timestamp.substring(0, 10)}`}
                                            </Box>
                                        ) : (
                                            ""
                                        );
                                    }
                                })}
                            </Box>
                        </Box>
                        <Grid container spacing={3}>
                            {LPSFields.map((element: any, index: any) => {
                                return element.currentObject.pi_tag === "NONE" ? (
                                    <Grid item xs={1}>
                                        <label className={classes.tagLabel}>
                                            <Box m={1}> {element.index}</Box>
                                            Constant:
                                        </label>
                                        <InputTextTag
                                            {...Object.assign({}, element.currentObject, { Tabtype: "Energy" })}
                                        />
                                    </Grid>
                                ) : (
                                    ""
                                );
                            })}
                            {LPSFields.map((element: any, index: any) => {
                                return element.currentObject.pi_tag !== "NONE" ? (
                                    <Grid item xs={1}>
                                        <label className={classes.tagLabel}>
                                            <Box m={1}> {element.index}</Box>
                                            {element.currentObject.index}
                                        </label>
                                        <InputTextTag
                                            {...Object.assign({}, element.currentObject, { Tabtype: "Energy" })}
                                        />
                                    </Grid>
                                ) : (
                                    ""
                                );
                            })}
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={open ? classes.extraUnit : classes.Steam}>
                    <form noValidate autoComplete="off">
                        <Box className={classes.resetIcon} component="span">
                            <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                                
                                {MPSFields.map((element: any, index: any) => {
                                    return element.currentObject.pi_tag === "NONE" ? (
                                        <>{props.unit}- MPS
                                        <Box className={classes.unit} component="span" m={1}>
                                            = Constant{" "}
                                        </Box>
                                        </>
                                    ) : (
                                        ""
                                    );
                                })}
                                {MPSFields.map((element: any, index: any) => {
                                    return element.currentObject.pi_tag !== "NONE" ? (
                                        <Box className={classes.unit} component="span" m={1}>
                                            {" "}
                                            + {element.currentObject.index} *{" "}
                                            {element.currentObject.pi_tag.slice(0, -1)}{" "}
                                        </Box>
                                    ) : (
                                        ""
                                    );
                                })}
                            </Typography>
                            <Box component="span" m={1} className={classes.lastDate}>
                                {" "}
                                {/* {MPSFields.map((element: any, index: any) => {
                                    if (0 === index) {
                                        return element ? (
                                            <Box component="span" m={6} className={classes.lastDate}>
                                                Last update :
                                                {` ${element.currentObject.timestamp.substring(0, 10)}`}
                                            </Box>
                                        ) : (
                                            ""
                                        );
                                    }
                                })} */}
                            </Box>
                        </Box>
                        <Grid container spacing={3}>
                            {MPSFields.map((element: any, index: any) => {
                                const temp = element.currentObject;
                                return element.currentObject.pi_tag === "NONE" ? (
                                    <Grid item xs={1}>
                                        <label className={classes.tagLabel}>
                                            <Box m={1}> {element.index}</Box>
                                            Constant:
                                        </label>
                                        <InputTextTag
                                            {...Object.assign({}, element.currentObject, { Tabtype: "Energy" })}
                                        />
                                    </Grid>
                                ) : (
                                    ""
                                );
                            })}
                            {MPSFields.map((element: any, index: any) => {
                                const temp = element.currentObject;
                                //    console.log(element.currentObject);
                                return element.currentObject.pi_tag !== "NONE" ? (
                                    <Grid item xs={1}>
                                        <label className={classes.tagLabel}>
                                            <Box m={1}> {element.index}</Box>
                                            {element.currentObject.index}
                                        </label>
                                        <InputTextTag
                                            {...Object.assign({}, element.currentObject, { Tabtype: "Energy" })}
                                        />
                                    </Grid>
                                ) : (
                                    ""
                                );
                            })}
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={open ? classes.extraUnit : classes.Steam}>
                    <form noValidate autoComplete="off">
                        <Box className={classes.resetIcon} component="span">
                            <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                                {HPSFields.map((element: any, index: any) => {
                                    return element.currentObject.pi_tag === "NONE" ? (
                                        <>{props.unit}- HPS
                                        <Box className={classes.unit} component="span" m={1}>
                                            = Constant{" "}
                                        </Box> </>
                                    ) : (
                                        ""
                                    );
                                })}
                                {HPSFields.map((element: any, index: any) => {
                                    return element.currentObject.pi_tag !== "NONE" ? (
                                        <Box className={classes.unit} component="span" m={1}>
                                            {" "}
                                            + {element.currentObject.index} *{" "}
                                            {element.currentObject.pi_tag.slice(0, -1)}{" "}
                                        </Box>
                                    ) : (
                                        ""
                                    );
                                })}
                            </Typography>
                            <Box component="span" m={1} className={classes.lastDate}>
                                {" "}
                                {/* {HPSFields.map((element: any, index: any) => {
                                    if (0 === index) {
                                        return element ? (
                                            <Box component="span" m={6} className={classes.lastDate}>
                                                Last update :
                                                {` ${element.currentObject.timestamp.substring(0, 10)}`}
                                            </Box>
                                        ) : (
                                            ""
                                        );
                                    }
                                })} */}
                            </Box>
                        </Box>
                        <Grid container spacing={3}>
                            {HPSFields.map((element: any, index: any) => {
                                // console.log(element.currentObject);
                                const temp = element.currentObject;
                                return element.currentObject.pi_tag === "NONE" ? (
                                    <Grid item xs={1}>
                                        <label className={classes.tagLabel}>
                                            <Box m={1}> {element.index}</Box>
                                            Constant:
                                        </label>
                                        <InputTextTag
                                            {...Object.assign({}, element.currentObject, { Tabtype: "Energy" })}
                                        />
                                    </Grid>
                                ) : (
                                    ""
                                );
                            })}
                            {HPSFields.map((element: any, index: any) => {
                                // console.log(element.currentObject);
                                const temp = element.currentObject;
                                return element.currentObject.pi_tag !== "NONE" ? (
                                    <Grid item xs={1}>
                                        <label className={classes.tagLabel}>
                                            <Box m={1}> {element.index}</Box>
                                            {element.currentObject.index}
                                        </label>
                                        <InputTextTag
                                            {...Object.assign({}, element.currentObject, { Tabtype: "Energy" })}
                                        />
                                    </Grid>
                                ) : (
                                    ""
                                );
                            })}
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={open ? classes.extraUnit : classes.Steam}>
                    <form noValidate autoComplete="off">
                        <Box className={classes.resetIcon} component="span">
                            <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                                {ELECFields.map((element: any, index: any) => {
                                    return element.currentObject.pi_tag === "NONE" ? (
                                        <>{props.unit}- ELEC
                                        <Box className={classes.unit} component="span" m={1}>
                                            = Constant{" "}
                                        </Box></>
                                    ) : (
                                        ""
                                    );
                                })}
                                {ELECFields.map((element: any, index: any) => {
                                    return element.currentObject.pi_tag !== "NONE" ? (
                                        <Box className={classes.unit} component="span" m={1}>
                                            {" "}
                                            + {element.currentObject.index} *{" "}
                                            {element.currentObject.pi_tag.slice(0, -1)}{" "}
                                        </Box>
                                    ) : (
                                        ""
                                    );
                                })}
                            </Typography>
                            <Box component="span" m={1} className={classes.lastDate}>
                                {" "}
                                {/* {ELECFields.map((element: any, index: any) => {
                                    if (0 === index) {
                                        return element ? (
                                            <Box component="span" m={6} className={classes.lastDate}>
                                                Last update :
                                                {` ${element.currentObject.timestamp.substring(0, 10)}`}
                                            </Box>
                                        ) : (
                                            ""
                                        );
                                    }
                                })} */}
                            </Box>
                        </Box>
                        <Grid container spacing={3}>
                            {ELECFields.map((element: any, index: any) => {
                                // console.log(element.currentObject);
                                const temp = element.currentObject;
                                return element.currentObject.pi_tag === "NONE" ? (
                                    <Grid item xs={1}>
                                        <label className={classes.tagLabel}>
                                            <Box m={1}> {element.index}</Box>
                                            Constant:
                                        </label>
                                        <InputTextTag
                                            {...Object.assign({}, element.currentObject, { Tabtype: "Energy" })}
                                        />
                                    </Grid>
                                ) : (
                                    ""
                                );
                            })}
                            {ELECFields.map((element: any, index: any) => {
                                // console.log(element.currentObject);
                                const temp = element.currentObject;
                                return element.currentObject.pi_tag !== "NONE" ? (
                                    <Grid item xs={1}>
                                        <label className={classes.tagLabel}>
                                            <Box m={1}> {element.index}</Box>
                                            {element.currentObject.index}
                                        </label>
                                        <InputTextTag
                                            {...Object.assign({}, element.currentObject, { Tabtype: "Energy" })}
                                        />
                                    </Grid>
                                ) : (
                                    ""
                                );
                            })}
                        </Grid>
                    </form>
                </Paper>
            </Paper>
        </>
    );
}
