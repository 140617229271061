import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { authenticationClientProvider } from './config/auth-provider';
import AuthenticationProvider from './pages/authentication/auth-provider';
import { ProfileContextProvider } from './context/profile.context';
import { StateProvider} from "./Store/Store";
import reducer, { initialState } from "./Store/reducer";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './config/ApplicationInsights';
//ReactDOM.render(<App />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
      <MsalProvider instance={authenticationClientProvider}>
          <ProfileContextProvider>
            <AuthenticationProvider>
            <AppInsightsContext.Provider value={reactPlugin}>
            <StateProvider reducer={reducer} initialState={initialState}> 
              <App />
              </StateProvider>
              </AppInsightsContext.Provider>
            </AuthenticationProvider>
            </ProfileContextProvider>
        
      </MsalProvider>
  </React.StrictMode>,
);
reportWebVitals();
