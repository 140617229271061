import React, {useState,useEffect} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    alertStatus:{
      height:"30px", 
      paddingLeft:" 30px",
    }
  }),
);

export const Notices = (props: any): any => {
  const classes = useStyles();
  const [value, setValue] = useState<any | undefined>(props.value );
  const [status, setStatus] = useState<any | undefined>(props.status );
//   const [option, setOption] = useState<any | undefined>(props.option );

  useEffect(() => {
    setValue(props.value);
}, [props]);

if("error" === status){
  return (
   <>  <Alert variant="filled" severity="error" className={classes.alertStatus}>
   {value}
   </Alert></>
  );
}

  if("success"=== status){
    return (
      <>
       <Alert variant="filled" severity="success" className={classes.alertStatus}>
       {value}
</Alert>
  
      </>
    );
}
}
