import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AccordianMain from './AccordianMain';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: "95%",
        height: '100%',
        borderRadius: '16px',
        // padding:'24px',
        
      },
    },
    fuel:{
      width: theme.spacing(200),
    },
    Steam: {
        width: theme.spacing(220),
        height: theme.spacing(11),
    },
    Heading:{
      fontWeight: 700,
      fontSize: '1.13rem',
      lineHeight: 1.3,
    },
    test:{
     backgroundColor:'unset',
    }
  }),
);

export default function ResultsFieldContainer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        {/* <Paper elevation={0}> */}
       <AccordianMain />
        {/* </Paper> */}
        <Paper elevation={0} className={classes.test}>
      {/* <EachUnits/> */}
        </Paper>
    </div>
  );
}
