import React, { useState, useContext, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import TextFields from './TextFields';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../../../context";
import { NumberInput } from "../../NumberInput";
import SelectInput from "../../SelectInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import InfoIcon from "@material-ui/icons/Info";
import Divider from "@material-ui/core/Divider";
import { APIDataType } from "../../../types/ApitParameter";
import { useStateValue } from "../../../Store/Store";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(1),
                width: theme.spacing(60),
                height: theme.spacing(1),
                border: "2px solid #F7F9FA",
                borderRadius: " 16px",
                padding: "16px",
                paddingBottom: "0px",
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subH: {
            fontWeight: 700,
            fontSize: "1rem",
            width: theme.spacing(100),
            height: "unset",
            border: "0px",
        },
        H: {
            width: "100%",
            height: "unset",
            border: "0px",
            fontSize: "1.13rem",
            fontWeight: 700,
            lineHeight: "22px",
            textTransform: "uppercase",
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "16px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#707374",
        },
        Network: {
            width: theme.spacing(50),
            height: theme.spacing(14),
        },
        Pendelband: {
            width: theme.spacing(50),
            height: theme.spacing(14),
            border: "2px solid #F7F9FA",
            borderRadius: " 16px",
            padding: "16px",
            paddingBottom: "0px",
            marginRight:"100%",
        },
        select: {
            width: theme.spacing(50),
            height: theme.spacing(20),
        },
        GT2Turbine: {
            border: "unset",
            height: "unset",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "22px",
            marginRight: "41px",
        },
        switch: {
            width: theme.spacing(200),
            height: "unset",
            border: "0px",
        },
        switchIcon: {
            verticalAlign: "middle",
            color: "#80CDFF",
        },
        switchLabel: {
            marginLeft: "9px",
        },
        subsection:{
            width: theme.spacing(50),
            height: theme.spacing(20),
          },
        switchdivider: {
            marginTop: "25px",
        },
        checkboxStyle: {
            display: "none",
        },
        checkboxStyleSwitch: {
            width: theme.spacing(50),
            height: theme.spacing(20),
            opacity: "0.5",
            backgroundColor:'#F7F9FA',
        },
        checkboxStyleSwitchDisable: {
            width: theme.spacing(50),
            height: theme.spacing(20),
            pointerEvents: "none",
            opacity: "0.5",
            backgroundColor:'#F7F9FA',
        },
        heading:{
            fontWeight: 700,
          },
          disable:{
            pointerEvents: "none",
          },
          active:{
          },
        sectionHide: {
            border:'unset',
        },
    })
);

export default function B1Boiler() {
    const classes = useStyles();
    // const data = useContext(AppContext);
    const [ stateValue, dispatch ] = useStateValue();

    // let fields = data.RKB.reduce(
    //     (obj:any, item:any) => Object.assign(obj, { [item.parameter]: item }), {});
    
    const [state, setState] = React.useState<any>({
        b1_active: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        b1_force_active: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        b1_fg_allowed: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        b1_ng_allowed:{ measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        b1_fo_allowed:{ measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
    });

    useEffect(() => {
        if (Object.keys(stateValue.RKB).length !== 0) {
            setState((prevState:any) => {
                return {
                    ...prevState,
                    b1_active: stateValue.RKB["b1_active"].value,
                    b1_force_active: stateValue.RKB["b1_force_active"].value,
                    b1_fg_allowed: stateValue.RKB["b1_fg_allowed"].value,
                    b1_ng_allowed: stateValue.RKB["b1_ng_allowed"].value,
                    b1_fo_allowed: stateValue.RKB["b1_fo_allowed"].value,
                };
            });
        }
    }, [stateValue]);



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let anc = {...stateValue};
        let value = "";
        if(event.target.checked === true){
            value = "1";
        } else{
            value = "0";
        }
        // console.log("checkevent:", value);
        // console.log("checkevent:",event.target.name);
        anc.RKB[`${event.target.name}`].value = value;
        dispatch( {
            type: "set",
            ...anc,
        } );
    };

    const { b1_active,b1_force_active,b1_fg_allowed,b1_ng_allowed,b1_fo_allowed } = state;
    // console.log(b1_active);

    const booleanValue = (parameter:any) => {
        let testValue;
        if(parameter == "1.0" || parameter == "1"){
            testValue = true;
            // console.log('pass');
        }
        else{
            testValue = false;
        }
        // console.log(testValue);
        return testValue;
      }
    const [switchstate, setswitchState] = React.useState({
        HELFlowBounds:true,
    });

    const switchHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setswitchState({ ...switchstate, [event.target.name]: event.target.checked });
    };

    const disable = {'disabled':true};

    const { HELFlowBounds } = switchstate;

    return (
        <>
            <div className={classes.root}>
                <Paper elevation={0} className={classes.switch}>
                    <FormControlLabel
                        control={<Checkbox checked={booleanValue(b1_active)} onChange={handleChange} name = "b1_active" />}
                        label="B1 Boiler"
                        className={classes.GT2Turbine}
                    />
                    <InfoIcon className={classes.switchIcon} color="primary" />
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={booleanValue(b1_force_active)}
                                onChange={handleChange}
                                name="b1_force_active"
                            />
                        }
                        label="Force active"
                        labelPlacement="start"
                        className={classes.switchLabel}
                    />
                    <Divider className={classes.switchdivider} />
                </Paper>
                {/* <Typography variant="h6" gutterBottom className={B0Boiler ? classes.H : classes.checkboxStyle}>
                    Electricity production bounds
                </Typography> */}
                <Paper elevation={0} className={booleanValue(b1_active) ? classes.Pendelband : classes.checkboxStyle}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subH}>
                        Boiler Live steam production bounds
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Min
                                    <Box className={classes.unit} component="span" m={1}>
                                       (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["b1_prod_min_bnd"],{"Tabtype":"RKB"})} />
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Max
                                    <Box className={classes.unit} component="span" m={1}>
                                       (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["b1_prod_max_bnd"],{"Tabtype":"RKB"})}/>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={booleanValue(b1_active) ? booleanValue(b1_fo_allowed) ? classes.subsection : classes.checkboxStyleSwitch : classes.checkboxStyle} >
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={<Checkbox checked={booleanValue(b1_fo_allowed)} onChange={handleChange} name="b1_fo_allowed" />}
                            label="FO consumption bounds"
                            className={classes.heading}
                        />
                        <Grid container spacing={3} className={booleanValue(b1_fo_allowed) ? classes.active : classes.disable}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Min
                                    <Box className={classes.unit} component="span" m={1}>
                                        (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["b1_fo_min_bnd"],{"Tabtype":"RKB"})}/>
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Max
                                    <Box className={classes.unit} component="span" m={1}>
                                        (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["b1_fo_max_bnd"],{"Tabtype":"RKB"})}/>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={booleanValue(b1_active) ? booleanValue(b1_fg_allowed) ? classes.subsection : classes.checkboxStyleSwitch : classes.checkboxStyle}>
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={<Checkbox checked={booleanValue(b1_fg_allowed)} onChange={handleChange} name="b1_fg_allowed" />}
                            label="FG consumption bounds"
                            className={classes.heading}
                        />
                        <Grid container spacing={3} className={booleanValue(b1_fg_allowed) ? classes.active : classes.disable}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Min
                                    <Box className={classes.unit} component="span" m={1}>
                                        (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["b1_fg_min_bnd"],{"Tabtype":"RKB"})} />
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Max
                                    <Box className={classes.unit} component="span" m={1}>
                                        (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["b1_fg_max_bnd"],{"Tabtype":"RKB"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className= {booleanValue(b1_active) ? booleanValue(b1_ng_allowed) ? classes.subsection : classes.checkboxStyleSwitch : classes.checkboxStyle}>
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={<Checkbox checked={booleanValue(b1_ng_allowed)} onChange =  {handleChange} name="b1_ng_allowed" />}
                            label="NG consumption bounds"
                            className={classes.heading}
                        />
                        <Grid container spacing={3} className={booleanValue(b1_ng_allowed) ? classes.active : classes.disable}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Min
                                    <Box className={classes.unit} component="span" m={1}>
                                       (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["b1_ng_min_bnd"],{"Tabtype":"RKB"})} />
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Max
                                    <Box className={classes.unit} component="span" m={1}>
                                       (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["b1_ng_max_bnd"],{"Tabtype":"RKB"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
        </>
    );
}
