import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import reducer, { initialState } from './reducer';
interface IAuthProvider {
  children: ReactNode
}

export const StateContext = createContext<any>('');

export const StateProvider = ({ children,reducer, initialState}:IAuthProvider | any):any => 
<StateContext.Provider value={ useReducer( reducer, initialState ) }>
		{ children }
	</StateContext.Provider>
// { const [state, dispatch] = useReducer(reducer, initialState);
// 	 return ( <StateContext.Provider value={{ state, dispatch }}> 
// 	 {children}
// 	  </StateContext.Provider> ); };

export const useStateValue = () => useContext( StateContext );