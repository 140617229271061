import React, { useState, useContext,useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import TextFields from './TextFields';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { AppContext } from "../../context";
import { NumberInput } from "../NumberInput";
import { APIDataType } from "../../types/ApitParameter";
import { useStateValue } from "../../Store/Store";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(60),
        height: theme.spacing(16),
        border: '2px solid #F7F9FA',
        borderRadius:' 16px',
        padding:'16px',
        paddingBottom: '0px',

      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    subHeading:{
      fontWeight: 700,
      fontSize:'1rem',
    },
    field:{
      width:'140px',
      height:'38px',
      borderRadius: '8px',
      flexDirection:'row',
    },
    label:{
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
    },
    unit:{
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#707374',
    },
    Network:{
      width: theme.spacing(40),
      height: theme.spacing(14),
    },
    Pendelband:{
      width: theme.spacing(50),
    },
    Heading:{
      fontWeight: 700,
      fontSize: '1.13rem',
      lineHeight: 1.3,
      paddingLeft: "17px",
      paddingTop: "17px",
    },
    checkboxstyle: {
      width: theme.spacing(40),
      height: theme.spacing(15),
      opacity: "0.5",
  },
  }),
);

export default function SteamImportBounds() {
  const classes = useStyles();
  const [ stateValue, dispatch ] = useStateValue();
  const [state, setState] = React.useState<any>({
    allow_steam_import_bounds: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
  });
  
  useEffect(() => {
      if (Object.keys(stateValue.Global).length !== 0) {
          // console.log("useeffect",stateValue.Global);
          setState((prevState:any) => {
              return {
                  ...prevState,
                  allow_steam_import_bounds: stateValue.Global["allow_steam_import_bounds"],
              };
          });
      }
  }, [stateValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name: any) => {
      let anc = {...stateValue};
      let value = "";
      if(event.target.checked === true){
          value = "1";
      } else{
          value = "0";
      }
      // console.log("checkevent:", event.target.name);
      anc.Global[`${event.target.name}`].value = value;
      dispatch( {
          type: "set",
          ...anc,
      } );
      // setState((prevState:any) => {
      //     return {
      //         ...prevState,
      //         [event.target.name]: {
      //             param_id: fo_allowed.param_id,
      //             parameter: fo_allowed.parameter,
      //             value: event.target.checked,
      //         },
      //     };
      // });
  };

  const booleanValue = (parameter:any) => {
      let testValue;
      if(parameter.value == "1.0" || parameter.value == "1"){
          testValue = true;
      }
      else{
          testValue = false;
      }
      return testValue;
    }

  const { allow_steam_import_bounds } = state;
  return (
    <>
      <Typography variant="h6" gutterBottom className={classes.Heading}>
    <FormControlLabel
                            control={
                              <Checkbox
                              checked={booleanValue(allow_steam_import_bounds)}
                              onChange={(event) => handleChange(event, allow_steam_import_bounds)}
                              name={allow_steam_import_bounds.parameter}
                          />
                            }
                            label="Allow Steam Imports"
                        />
                                </Typography>
    <div className={classes.root}>
        <Paper elevation={0} className={booleanValue(allow_steam_import_bounds) ? classes.Network : classes.checkboxstyle}>
        {/* <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
        Low pressure steam
      </Typography> */}
      <form noValidate autoComplete="off">
    
      <Grid container spacing={3}>
            <Grid item xs={6}>
            <label className={classes.label} >LPS Min<Box className={classes.unit} component="span" m={1}>(ton/hr)</Box></label>
        <NumberInput {...Object.assign( {},stateValue.Global["import_steam_lps_min_bnd"],{"Tabtype":"Global"})} />
            </Grid>
            <Grid item xs={6}>
            <label className={classes.label} >LPS Max<Box className={classes.unit} component="span" m={1}>(ton/hr)</Box></label>
        <NumberInput {...Object.assign( {},stateValue.Global["import_steam_lps_max_bnd"],{"Tabtype":"Global"})} />
            </Grid>
        </Grid>
      </form>
        </Paper>
        <Paper elevation={0} className={booleanValue(allow_steam_import_bounds) ? classes.Network : classes.checkboxstyle}>
      {/* <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
      Medium pressure steam
      </Typography> */}
      <form noValidate autoComplete="off">
      
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <label className={classes.label} >MPS Min<Box className={classes.unit} component="span" m={1}>(ton/hr)</Box></label>
      <NumberInput {...Object.assign( {},stateValue.Global["import_steam_mps_min_bnd"],{"Tabtype":"Global"})} />
        </Grid>
        <Grid item xs={6}>
          <label className={classes.label} >MPS Max<Box className={classes.unit} component="span" m={1}>(ton/hr)</Box></label>
      <NumberInput {...Object.assign( {},stateValue.Global["import_steam_mps_max_bnd"],{"Tabtype":"Global"})} />
        </Grid>
      </Grid>
      </form>
        </Paper>
        <Paper elevation={0} className={booleanValue(allow_steam_import_bounds) ? classes.Network : classes.checkboxstyle}>
      {/* <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
      High pressure steam
      </Typography> */}
      <form noValidate autoComplete="off">
      
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <label className={classes.label} >HPS Min<Box className={classes.unit} component="span" m={1}>(ton/hr)</Box></label>
      <NumberInput {...Object.assign( {},stateValue.Global["import_steam_hps_min_bnd"],{"Tabtype":"Global"})} />
        </Grid>
        <Grid item xs={6}>
          <label className={classes.label} >HPS Max<Box className={classes.unit} component="span" m={1}>(ton/hr)</Box></label>
      <NumberInput {...Object.assign( {},stateValue.Global["import_steam_hps_max_bnd"],{"Tabtype":"Global"})} />
        </Grid>
      </Grid>
      </form>
        </Paper>
    </div>
     </>
  );
}
