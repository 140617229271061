import React, { useState, useEffect, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, IconButton, InputAdornment} from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Box from '@material-ui/core/Box';
import { DecimalTwoDigit } from "../tools/timeutil";
import { useStateValue } from "../Store/Store";
import { ProfileContext } from "../context/profile.context";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
                width: "25ch",
                display: "flex",
            },
        },
        field: {
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize:'14px',
        },
        error: {
            borderColor: '#FF0000',
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize:'14px',
        },
        errorMessage: {
           fontSize:'10px',
           color:'#FF0000',
           display: 'flex',
           alignItems: 'center',
        },
        warning: {
            color:'#FF0000',
         },
         noError: {
            visibility: 'hidden',
         },

    })
);

interface APIDataType {
    id: number;
    email: string;
    first_name: string;
    optim_id: number;
    optim_output: null;
    value: number;
    timestamp: string;
    param_id: number;
    units_id: number;
    parameter: string;
    measured_in: String;
}
interface valueType {
    value: number;
}

export const InputTextTag = (props: any): any => {
    const classes = useStyles();
    const [errorMessage,setErrorMessage] = useState('');
    const [errorType,setTypeError] = useState(false);
    const userContext = useContext(ProfileContext);
    const Usertype = userContext?.context?.type;
    // console.log("field main data",props);
    
    const [ state, dispatch ] = useStateValue();

    const [value, setValue] = useState<any | undefined>(props.value || props.coeff || "0");

    useEffect(() => {
        if(props.value){
        setValue(DecimalTwoDigit(props.value));
        }
        if(props.coeff){
            setValue(props.coeff);
        }
    }, [props]);
    // console.log("Input",props);
    
    const handleInputValueChange = (event: any) => {
        setValue(event.target.value);

        let anc = {...state};
        if("Energy" == props.Tabtype){
            anc.energyModel[props.refinery_units_id].map((currentObject: any, index: any) => {
                if(props.pi_tag == currentObject.pi_tag && props.refinery_units_type == currentObject.refinery_units_type){
                    anc.energyModel[props.refinery_units_id][index].coeff = event.target.value.replace(/\s/g, '');
                    anc.energyModel[props.refinery_units_id][index]["editstatus"] = true;
                    anc.energyModel[props.refinery_units_id][index]["editindex"] = index;
                    anc.EnergyEditUnit = {
                        ...anc.EnergyEditUnit,
                        [`Energyedit_${index}`]: props.refinery_units_id,
                    };
                    
                };
            });
            // console.log(anc.energyModel[props.refinery_units_id]);
            // anc.energyModel[props.refinery_units_id].value =event.target.value;
            if('admin' === Usertype){
            anc.EnergyEditStatus = true;
            }
        }
        dispatch( {
            type: "set",
            ...anc,
        } );
    };
   
//    function handleOnKeyDown(event: any) {
//     handleOnMouseOver(event);
//    }

    function handleOnBlur(event: any) {
        var regex = /^[()+*#-]*(?:\d[()+*#-]*){20,40}$/;
        let num = Number(value);    // allow only numbers [0-9] 
        if(! value){
            setErrorMessage('Please enter the value.')
            setTypeError(true);
        }else if (value && regex.test(value)){
            setErrorMessage('Only allow Maximum 20 digit ')
            setTypeError(true);
        }else if (isNaN(num)){
        setErrorMessage('Please enter only digit or decimal[0-9].')
        setTypeError(true);
    } else{
            setErrorMessage('');
            setTypeError(false);
        }
       
    }
    function getHandleCross() {
        if (errorType) {
            return (
                <InputAdornment position="end">
                    <IconButton className={classes.warning}>
                        <WarningIcon/>
                    </IconButton>
                </InputAdornment>
            );
        }
    }

    return (
    <>
    <div className={`Input-${props.Tabtype} ${errorType ? "error": "validated"}`}>
            <TextField
                id={`tag-${props.refinery_units_id}-${props.refinery_units_pkid}`}
                value={value}
                onChange={(event) => handleInputValueChange(event)}
                variant="outlined"
                // onMouseOver ={(event) => handleOnMouseOver(event)}
                // onKeyDown ={(event) => handleOnKeyDown(event)}
                onBlur ={(event) => handleOnBlur(event)}
                className={errorType ? classes.error: classes.field}
                InputProps={{
                    inputProps: { "data-testid": props?.parameter },
                    endAdornment: getHandleCross(),
                    // className: props?.classNameInput,
                }}
            />
        </div>
        <Typography className={errorType ? classes.errorMessage: classes.noError}><Box className='' component="span" m={1}><ErrorOutlineIcon/></Box>{errorMessage} </Typography>
    </>
        
    );
};
