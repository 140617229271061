import React, { useEffect,useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import GlobalFieldContainer from "./GlobalFieldContainer";
import GT2FieldContainer from "./Gt2FieldContainer";
import RKBFieldContainer from "./RKBFieldContainer";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
// import { AppContext } from '../context';
import Divider from "@material-ui/core/Divider";
import UploadButton from "./UploadButton";
import ProcessFieldContainer from "./Tabcomponents/Process/ProcessFieldContainer";
import ResultsFieldContainer from "./Tabcomponents/results/ResultsFieldContainer";
import AllUnits from "./Tabcomponents/Process/AllUnits";
import ResultTab from "./Tabcomponents/results/ResultTab";
import OptimizerButton from "./Tabcomponents/results/OptimizerButton";
import DownloadButton from "./Tabcomponents/results/DownloadButton";
import SaveScenario from "./Tabcomponents/results/SaveScenario";
import { useStateValue } from "../Store/Store";
import { DownloadResult } from "../tools/timeutil";
import { AnySoaRecord } from "dns";
import ComputeUtilityButton from "./Tabcomponents/Process/ComputeUtilityButton";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#F5F5F5",
        paddingLeft: "20px",
        paddingTop: "40px",
    },
    AppBar: {
        color: "#374649",
        backgroundColor: "#FFFFFF",
        width: "-webkit-fill-available",
        position: "absolute",
        paddingLeft: "144px",
        top: "0px",
        // marginLeft: "12%",
        marginTop: " 5.4%",
        left: "0%",
    },
    tab: {
        padding: "unset",
    },
    mainHeading: {
        paddingTop: "15px",
        paddingBottom: "4px",
    },
    appBarDivider: {
        // flex: 1,
        width: "95%",
    },
    test: {
        marginTop: "10px",
    },
}));

export default function CustomTab() {
    const [stateValue, dispatch] = useStateValue();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.AppBar} elevation={0}>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Process" {...a11yProps(0)} className={classes.tab} />
                    <Tab label="Global" {...a11yProps(1)} className={classes.tab} />
                    <Tab label="RKB" {...a11yProps(2)} className={classes.tab} />
                    <Tab label="GT2" {...a11yProps(3)} className={classes.tab} />
                    <Tab label="Results" {...a11yProps(4)} className={classes.tab} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Typography variant="h4" gutterBottom className={classes.mainHeading}>
                    Units troughputs & parameters
                    <UploadButton />
                    <ComputeUtilityButton />
                </Typography>
                <ProcessFieldContainer />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Typography variant="h4" gutterBottom className={classes.mainHeading}>
                    Global refinery parameters
                </Typography>
                <GlobalFieldContainer />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Typography variant="h4" gutterBottom className={classes.mainHeading}>
                    Powerplant RKB parameters
                </Typography>
                <RKBFieldContainer />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Typography variant="h4" gutterBottom className={classes.mainHeading}>
                    GT2 parameters
                </Typography>
                <GT2FieldContainer />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ResultTab />
                <Typography variant="h4" gutterBottom className={classes.mainHeading}>
                    Optimization results
                    <DownloadButton fileName={"excel export"}></DownloadButton>
                    <SaveScenario />
                </Typography>
                <ResultsFieldContainer />
            </TabPanel>
        </div>
    );
}
