import React, { ChangeEvent } from "react";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { createAxiosInstance } from "../tools/axios-auth-interceptor";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Box from "@material-ui/core/Box";
import { myConstClass } from "../constants";
import { Alert, AlertTitle } from "@material-ui/lab";
import { File } from "buffer";
import { fetchData } from "../API/fetchAPI";
import { useStateValue } from "../Store/Store";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
            marginLeft: "22%",
        },
        input: {
            display: "none",
        },
        button: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: " #FFFFFF",
            color: "#ED0000",
            borderColor: " #ED0000",
        },
        alertMain: {
            marginLeft: "70%",
            padding: "0px 0px 0px 0px",
            borderRadius: " 16px 0px 0px 16px",
            backgroundColor: "#ffffff",
        },
        alertClose: {
            display: "none",
        },
    })
);

interface responseType {
    config: object;
    data: object;
    headers: object;
    requests: object;
    status: object;
    statusText: object;
}

export default function UploadButton() {
    const classes = useStyles();

    const [FileList, setFileList] = useState<File | null | any>(null);
    const [alertopen, setAlertOpen] = React.useState(false);
    const [open, setOpen] = useState(true);
    const [errorMesaage, setErrorMesaage] = useState("");
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [resposeFutureAPI, setResposeFutureAPI] = useState<any | undefined>([]);
    const [state, dispatch] = useStateValue();
    const [utilityDemand, setUtilityDemand] = useState<any | undefined>([]);
    const [eventCall, setEventCall] = useState<any | undefined>([]);
    // console.log("error",errorAlertOpen);
    // console.log("success",alertopen);
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e.target);
        if (e.target.files) {
            setFileList(e.target.files[0]);
            setEventCall(e);
        }
    };
    // useEffect(() => {
    //     if (Array.isArray(resposeFutureAPI) && resposeFutureAPI.length !== 0) {
    //     dispatch({
    //         type: "set",
    //         Future_API:resposeFutureAPI,
    //     });
    // }
    // }, [resposeFutureAPI]);
    useEffect(() => {
        dispatch({
            type: "set",
            utilityDemandAPI: utilityDemand,
        });
    }, [utilityDemand]);

    useEffect(() => {
        // console.log(FileList);
        postFile(myConstClass.FlowerImport_API);
    }, [FileList]);

    const handleUploadClick = () => {
        if (!FileList && null === FileList) {
            return;
        }
    };

    const postFile = async (url: any) => {
        if (FileList) {
            let formData: any = new FormData();
            formData.append("file", FileList);
            for (const [key, value] of formData) {
                const out: any = `${key}: ${value}\n`;
                console.log(out);
            }

            const response: Promise<AxiosResponse<responseType[]> | undefined | any> = (
                await createAxiosInstance()
            ).post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            response
                .then(function (response) {
                    // console.log(response);
                    setAlertOpen(true);
                    fetchData<any>(myConstClass.Future_API)
                        .then((response: any) => {
                            // console.log(response);
                            let flowerData = {};
                            const flowerAPIFields: any = response.data.reduce(
                                (obj: any, item: any) => {
                                    if (
                                        typeof item.is_throughput != "undefined" &&
                                        item.is_throughput != null &&
                                        item.is_throughput == 1 &&
                                        item.unit !== "431"
                                    ) {
                                        if (item.unit === "631_451" && item.tag === "I_631B1001:F1002.") {
                                            item.unit = "631_451_1";
                                            console.log(item.unit);
                                          flowerData = { ...flowerData, [item.unit]: item };
                                        } else if (item.unit === "651" && item.tag === "I_651B1001:F1015.") {
                                            item.unit = "651_1";
                                          flowerData = { ...flowerData, [item.unit]: item };
                                        } else {
                                          flowerData = { ...flowerData, [item.unit]: item };
                                        }
                                        return flowerData;
                                    }
                                    Object.assign(obj, { [item.unit]: item });
                                    
                                },
                                {}
                            );
                            dispatch({
                                type: "set",
                                Future_API: flowerAPIFields,
                            });
                            // setResposeFutureAPI(flowerAPIFields);
                        })
                        .catch((error: any) => {
                            setErrorAlertOpen(true);
                        });
                    fetchData<any>(myConstClass.utilityDemand)
                        .then((response: any) => {
                            setUtilityDemand(response.data);
                            console.log("fields", response.data);
                        })
                        .catch((error: any) => {
                            // console.log(error);
                        });
                        setTimeout(function() {
                            // setOption(false);
                            setAlertOpen(false);
                        }, 3000);
                })
                .catch(function (error) {
                    setErrorAlertOpen(true);
                    setErrorMesaage(error.response.data.message);
                    console.log(error);
                    // console.log(errorAlertOpen);
                    setTimeout(function() {
                        // setOption(false);
                        setErrorAlertOpen(false);
                    }, 3000);
                });
        }
    };
    const handleClick = () => {
        // setOpen(!open);
    };
    const onInputClick = ( event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const element = event.target as HTMLInputElement
        element.value = ''
    }
    // console.log(open);

    return (
        <Box component="span" sx={{ p: 2, marginLeft: "50%" }} className={classes.root}>
            {/* <div className={classes.root}> */}
            <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                onClick={onInputClick} 
                multiple
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="contained-button-file">
                <Button
                    variant="outlined"
                    color="secondary"
                    component="span"
                    className={classes.button}
                    startIcon={<InsertDriveFileIcon />}
                    onClick={handleUploadClick}
                >
                    Import flowers data
                </Button>
            </label>
            <Alert
                // onClose={() => handleClick()}
                severity="success"
                className={ alertopen ? classes.alertMain : classes.alertClose}
            >
                <AlertTitle>{FileList ? `Successfully` : "Unsuccessfully"}</AlertTitle>
                <strong>{FileList ? `${FileList.name}has been correctly imported` : errorMesaage}</strong>
                <p>
                    The values from the file has been correctly imported and use to calculate the throughput, LP, MP,
                    etc
                </p>
            </Alert>
            <Alert
                // onClose={() => handleClick()}
                severity="error"
                className={errorAlertOpen ? classes.alertMain : classes.alertClose}
            >
                <AlertTitle>Unsuccessfully</AlertTitle>
                <strong>{FileList ? FileList.name : "not found"}</strong> &nbsp; &nbsp;&nbsp;has not been correctly
                imported
                <p>{errorMesaage}</p>
            </Alert>
            {/* {/* </div> */}
        </Box>
    );
}
