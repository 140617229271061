import React, { useState, useContext, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import TextFields from './TextFields';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { myConstClass } from "../../../constants";
import { fetchData } from "../../../API/fetchAPI";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import SelectInput from "../../SelectInput";
import UploadButton from "../../UploadButton";
import Button from "@material-ui/core/Button";
import OptimizerButton from "./OptimizerButton";
import IconButton from "@material-ui/core/IconButton";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AddIcon from "@material-ui/icons/Add";
import { useStateValue } from "../../../Store/Store";
import SaveScenario from "./SaveScenario";
import { Notices } from "../../Notices";
import { DecimalTwoDigit } from "../../../tools/timeutil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(3),
                width: theme.spacing(100),
                height: theme.spacing(100),
                borderRadius: " 16px",
                padding: "16px",
                paddingBottom: "0px",
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subHeading: {
            fontWeight: 700,
            fontSize: "1.1rem",
            lineHeight: "22px",
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize: "14px",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "18px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#707374",
        },
        Steam: {
            width: theme.spacing(200),
            height: theme.spacing(14),
            padding: "16px 24px 16px 24px",
            display: "flex",
        },
        Electricity: {
            width: theme.spacing(50),
            height: theme.spacing(10),
        },
        Fuel: {
            width: theme.spacing(140),
            height: theme.spacing(14),
        },
        CO2: {
            width: theme.spacing(30),
            height: theme.spacing(14),
        },
        fuelMain: {
            paddingLeft: "12px",
        },
        button: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: " #FFFFFF",
            color: "#ED0000",
            borderColor: " #ED0000",
        },
        selectControl: {
            color: "#ED0000",
        },
        loadHide: {
            color: "#ED0000",
            pointerEvents: "none",
            opacity:" 0.5",
        },
    })
);

export default function ResultTab() {
    const classes = useStyles();
    const [ stateValue, dispatch ] = useStateValue();
    const [resposeGlobal, setResposeGlobal] = useState<any | undefined>(stateValue.Global);
    const [resposeGT2, setResposeGT2] = useState<any | undefined>(stateValue.GT2);
    const [resposeRKB, setResposeRKB] = useState<any | undefined>(stateValue.RKB);
    const [option,setOption] = useState(false);
    const [statusType,setStatusType] = useState("");
    const [ statusValue,setStatusValue] = useState("");
    const [loading,setLoading] = useState(stateValue.loading );
    const Selectoption = {
        default: stateValue.scenario.default,
        name: "scenario_name",
        value: stateValue.scenario.value,
    };
    const StatusOption = {
        value: stateValue.notices.noticeStatus,
        status: stateValue.notices.noticeTypes,
    };

    
    const handleScenario = (event: any, number:number) => {
         let anc = {...stateValue};
        let value = "";
        anc.scenario.count = anc.scenario.count + number;
        console.log("secnario event");
        if(anc.scenario.count && anc.scenario.count <= "8" ){
            anc.scenario.default = `scenario_${anc.scenario.count}`;
            anc.scenario.value = {...anc.scenario.value,[`scenario_${anc.scenario.count}` ]:`Scenario ${anc.scenario.count}`}
            // setStatusValue(`Scenario is created:${anc.scenario.count}`);
            anc.notices.noticeStatus = `Scenario is created:${anc.scenario.count}`;
            anc.notices.noticeTypes = "success";
            anc.notices.noticeOption = true;
            // setOption(true);
        }else{
            anc.notices.noticeStatus = `Scenario is not created , limit up to 8 Scenarios`;
            anc.notices.noticeTypes = "error";
            anc.notices.noticeOption = true;
        }
        setTimeout(function() {
            // setOption(false);
            anc.notices.noticeOption = false;
            dispatch( {
                type: "set",
                ...anc,
            } );
        }, 4000);
        dispatch( {
            type: "set",
            ...anc,
        } );
       
        
    };
    
    return (
        <>
            <div className={classes.root}>
                <Paper elevation={0} className={classes.Steam}>
                    <Box component="span" sx={{ p: 2 }} className={classes.selectControl}>
                        <SelectInput {...Object.assign({},Selectoption,{"Tabtype":"result"})} />
                        <Box component="span" sx={{ p: 2 }} className={ stateValue.loading ? classes.loadHide : "" }>
                        Create a new scenario
                        <IconButton color="primary" aria-label="Create a new scenario" onClick={(event) => handleScenario(event, 1)}>
                            <AddIcon className={classes.selectControl} />
                        </IconButton>
                        </Box>
                    </Box>
                    <OptimizerButton />
                </Paper>
            </div>
            {stateValue.notices.noticeOption ? <Notices {...StatusOption}/> : ""}
        </>
    );
}
