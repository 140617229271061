import React, { ChangeEvent } from "react";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
// import { createAxiosInstance } from "../tools/axios-auth-interceptor";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import OptimizerIcon from "./OptimizerIcon";
import Box from "@material-ui/core/Box";
// import { myConstClass } from "../constants";
import { myConstClass } from "../../../constants";
import { fetchData } from "../../../API/fetchAPI";
import { StateProvider, useStateValue } from "../../../Store/Store";
import reducer, { initialState } from "../../../Store/reducer";
import { createAxiosInstance } from "../../../tools/axios-auth-interceptor";
import { UnitSaveParameter,UnitShowParameter,UnitShowParameterDemo } from "../../../tools/timeutil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
            marginLeft: "56%",
            marginTop: "2%",
        },
        input: {
            display: "none",
        },
        button: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            color: "#FFFFF",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: "#ED0000",
            borderColor: " #ED0000",
        },
        alertMain: {
            marginLeft: "70%",
            padding: "0px 0px 0px 0px",
            borderRadius: " 16px 0px 0px 16px",
            backgroundColor: "#ffffff",
        },
        alertClose: {
            display: "none",
        },
        loadHide: {
            "& > *": {
                margin: theme.spacing(1),
            },
            marginLeft: "56%",
            marginTop: "2%",
            pointerEvents: "none",
            opacity:" 0.5",
        },
    })
);

interface responseType {
    config: object;
    data: object;
    headers: object;
    requests: object;
    status: object;
    statusText: object;
}

export default function OptimizerButton() {
    const classes = useStyles();
    const [stateValue, dispatch] = useStateValue();
    const [optimizerData, setOPtimizerData] = useState<any | undefined>("");
    const [optimizerID, setOPtimizerID] = useState<any | undefined>("");
    const [ParameterSaveResponse, setParameterSaveResponse] = useState<any | undefined>([]);
    // console.log(ParameterSaveResponse);
    const GT2SaveParameter = UnitSaveParameter(stateValue.GT2);
    const gLobalSaveParameter = UnitSaveParameter(stateValue.Global);
    const RKBSaveParameter = UnitSaveParameter(stateValue.RKB);

    const [option,setOption] = useState(false);
    const [statusType,setStatusType] = useState("");
    const [ statusValue,setStatusValue] = useState("");
    const [loading,setLoading] = useState(false);

    const StatusOption = {
        value: stateValue.notices.noticeStatus,
        status: stateValue.notices.noticeTypes,
    };

    let finalSaveParameter = { ...GT2SaveParameter, ...gLobalSaveParameter, ...RKBSaveParameter };
    let GT2SaveParameterShowDemo1 = UnitShowParameterDemo(stateValue.GT2);
    let GlobalSaveParameterShowDemo = UnitShowParameterDemo(stateValue.Global);
    let RKBSaveParameterShowDemo = UnitShowParameterDemo(stateValue.RKB);
    let SaveParameterShowDemo = {...GlobalSaveParameterShowDemo,...RKBSaveParameterShowDemo,...GT2SaveParameterShowDemo1}
   
    const handleOptimizerClick = (e: any) => {
        // console.log("final", finalSaveParameter);
        let data = { ...stateValue };
        data.loading = true;
        dispatch({
            type: "set",
            ...data,
        });
        setLoading(true);
        const saveData = {
            scenario_name: stateValue.scenario.default,
            optim_id: 0,
            id_values: finalSaveParameter, //{ "1": "200", "2": "200" },
        };

        const postParameter = async (url: any) => {
            const response: Promise<AxiosResponse<responseType[]> | undefined | any> = (
                await createAxiosInstance()
            ).post(url, saveData, {
                headers: {
                    accept: "*/*",
                    "Content-Type": "application/json",
                },
            });
            response
                .then(function (response) {
                    setParameterSaveResponse(response.data);
                    setOPtimizerID(response.data.optim_id);
                    OptimizerCall(myConstClass.Optimizer_API,response.data.optim_id);              
                })
                .catch(function (error) {
                    console.log(error);
                    let anc = { ...stateValue };
                    anc.noticeStatus = `Optimization unsuccessful !, Please check input parameter`;
                    anc.noticeTypes = "error";
                    anc.noticeOption = true;
                    dispatch({
                        type: "set",
                        ...anc,
                    });
                    setTimeout(function() {
                        // setOption(false);
                        anc.noticeOption = false;
                        dispatch( {
                            type: "set",
                            ...anc,
                        } );
                    }, 4000);
                    setLoading(false);
                })
                .finally(() => {
                  });  
                  
                  
        };

        postParameter(myConstClass.ParameterSave_API).then(()=>{
          
        });
        const OptimizerCall = async (url: any,optimizerID:any) => { 
            // console.log("inside", optimizerID);    
        if (optimizerID) {
            let dataID = optimizerID;
            // console.log("inside", dataID);
            fetchData<any>(`${url}${optimizerID}`)
                .then((response: any) => {
                    let anc = { ...stateValue };
                    anc.resulttabs = true;
                    if (anc.scenario.default && anc.scenarioCount[anc.scenario.default]) {
                        anc.scenarioOptimizeParameterResults = {
                            ...anc.scenarioOptimizeParameterResults,
                            [`scenarioOptimizePara_${anc.scenarioCount[anc.scenario.default]}`]: finalSaveParameter,
                        };
                        anc.scenarioOptimParamResultsDemo = {
                            ...anc.scenarioOptimParamResultsDemo,
                            [`scenarioOptimizeParashow_${anc.scenarioCount[anc.scenario.default]}`]: SaveParameterShowDemo,
                        };
                        anc.scenarioResults = {
                            ...anc.scenarioResults,
                            [`scenarioResult_${anc.scenarioCount[anc.scenario.default]}`]: response.data,
                        };
                        anc.scenarioCount = {
                            ...anc.scenarioCount,
                            [`scenario_${anc.scenarioCount[anc.scenario.default]}`]:
                                anc.scenarioCount[anc.scenario.default],
                        };
                    } else {
                        let defaultCount = anc.scenario.default.substring(9);
                        anc.scenarioOptimizeParameterResults = {
                            ...anc.scenarioOptimizeParameterResults,
                            [`scenarioOptimizePara_${defaultCount}`]: finalSaveParameter,
                        };
                        anc.scenarioOptimParamResultsDemo = {
                            ...anc. scenarioOptimParamResultsDemo,
                            [`scenarioOptimizeParashow_${defaultCount}`]: SaveParameterShowDemo,
                        };
                        anc.scenarioResults = {
                            ...anc.scenarioResults,
                            [`scenarioResult_${defaultCount}`]: response.data,
                        };
                        anc.scenarioCount = {
                            ...anc.scenarioCount,
                            [`scenario_${defaultCount}`]: defaultCount,
                        };
                    }
                       anc.optimID =  {
                        ...anc.optimID,
                        [`${anc.scenario.default}`]: optimizerID,
                    };
                    anc.notices.noticeStatus = `Optimization successful !`;
                    anc.notices.noticeTypes = "success";
                    anc.notices.noticeOption = true;
                    anc.loading = false;
                    // setOPtimizerData(anc);
                    dispatch({
                        type: "set",
                        ...anc,
                    });
                    setTimeout(function() {
                        // setOption(false);
                        anc.notices.noticeOption = false;
                        dispatch( {
                            type: "set",
                            ...anc,
                        } );
                    }, 4000);
                    setLoading(false);

                })
                .catch((error: any) => {
                    console.log(error);
                    let anc = { ...stateValue };
                    setStatusValue(`Optimization unsuccessful !`);
                    anc.notices.noticeStatus = `Optimization unsuccessful !`;
                    anc.notices.noticeTypes = "error";
                    anc.notices.noticeOption = true;
                    anc.loading = false;
                    dispatch({
                        type: "set",
                        ...anc,
                    });
                    setTimeout(function() {
                        // setOption(false);
                        anc.notices.noticeOption = false;
                        dispatch( {
                            type: "set",
                            ...anc,
                        } );
                    }, 4000);
                    setLoading(false);
                })
                .finally(() => {
                    setOPtimizerID(dataID);
                    let data = { ...stateValue };
                    setOPtimizerData(data);

                });
        }
       
        };

    }
  

    return (
        <Box component="span" sx={{ p: 2, marginLeft: "50%" }} className={loading ? classes.loadHide : classes.root}>
            {/* <div className={classes.root}> */}
            <label htmlFor="contained-button-file">
                <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                    className={classes.button}
                    startIcon={<OptimizerIcon />}
                    onClick={handleOptimizerClick}
                >
                    Launch optimization
                </Button>
            </label>

            {/* </div> */}
        </Box>
    );
}
