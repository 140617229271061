import {
    Configuration,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication,
  } from '@azure/msal-browser';
  
  const AZURE_CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';
  const AZURE_TENANT_URI = process.env.REACT_APP_TENANT_AUTHORITY_URI || 'https://login.microsoftonline.com/common';
  const AZURE_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || '/';

  export const msalConfig: Configuration = {
    auth: {
      clientId: AZURE_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
      authority: AZURE_TENANT_URI, // Defaults to "https://login.microsoftonline.com/common"
      redirectUri: AZURE_REDIRECT_URI, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
      postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }
          if (level === LogLevel.Error) {
            console.error(message);
          }
        },
      },
    },
  };
  
  export const authenticationClientProvider: IPublicClientApplication = new PublicClientApplication(
    msalConfig,
  );