import AuthentificationLayout from '../../layout/authentication-layout';
import React from 'react';
import logo from '../../assets/img/logo/logo-TotalEnergies.png';
import BackgroundImage from  '../../assets/img/login/login-back.png';
import { useMsal } from '@azure/msal-react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { relative } from 'path';


const useStyles = makeStyles({
  root: {
    maxWidth: 280,
    margin: 'auto',
    display:'releative',
    height:'302px',
    borderRadius:'32px',
  },
  media: {
    margin:'auto',
    width: '50%',
  },
  loginHeading: {
    margin:'auto',
    width: '75%',
    letterSpacing: '0.5em',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color:' #ED0000',
  },
  loginButton: {
    margin:'auto',
    borderRadius: '40px',
    backgroundColor: '#ED0000',
  },
  Authenticate: {
    height:"850px",
  },
  authentificationLayout: {
    position: 'relative',
    top: '40%',
  },
});

const LoginComponent: React.FunctionComponent = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  const handleLogin = () => {
    instance.loginRedirect().catch((error) => console.error(error));
  };

  return (
    <div style={{ backgroundImage: `url(${BackgroundImage})` }} className={classes.Authenticate}>
    <div className={classes.authentificationLayout}>
    <AuthentificationLayout >
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="NRJEZER"
          height="140"
          image = {logo}
          title="Contemplative Reptile" className={classes.media}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" className={classes.loginHeading}>
          NRJEZER
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <Button size="small" variant="contained" color="secondary" onClick={handleLogin} className={classes.loginButton}>
        Login
      </Button>
      </CardActions>
    </Card>
    </AuthentificationLayout>
    </div>
    </div>
   
  );
};

export default LoginComponent;