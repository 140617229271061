import { User } from "../pages/authentication/types";
import React, { useState } from "react";

type ProfileContextType = {
    context?: User;
    setContext: (value: User) => void;
};
export const ProfileContext = React.createContext<ProfileContextType | null>(null);

type ProfileContextProviderType = {
    children: JSX.Element;
};
export const ProfileContextProvider: React.FunctionComponent<ProfileContextProviderType> = (
    props: ProfileContextProviderType
) => {
    const [context, setContext] = useState<User>();
    return <ProfileContext.Provider value={{ context, setContext }}>{props.children}</ProfileContext.Provider>;
};
