
import React from "react";
import Appbar from "../../components/Appbar";
import CustomTab from "../../components/CustomTab";
import { TrackUserActivity } from "../../tools/AppInsight";
import MainHeader from "../../components/MainHeader";
export default function Header() {

            return (
                <>      
                <main >
                <Appbar />
                <TrackUserActivity name="Dashboard page">
                <MainHeader/>
                </TrackUserActivity>
                </main>
                </>
            );
}