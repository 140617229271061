import React, { ChangeEvent } from "react";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { createAxiosInstance } from "../../../tools/axios-auth-interceptor";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Box from "@material-ui/core/Box";
import { myConstClass } from "../../../constants";
import { Alert, AlertTitle } from "@material-ui/lab";
import { File } from "buffer";
import { fetchData } from "../../../API/fetchAPI";
import { UnitShowParameter } from "../../../tools/timeutil";
import { useStateValue } from "../../../Store/Store";
import ComputeUtilityDemandIcon from "./ComputeUtilityDemandIcon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
            marginLeft: "0%",
        },
        input: {
            display: "none",
        },
        button: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: "#ED0000",
            color: "#FFFF",
            borderColor: " #ED0000",
        },
        buttonDisable: {
            margin: theme.spacing(1),
            padding: "8px 16px 8px 16px",
            borderRadius: "40px",
            gap: "8px",
            backgroundColor: "#FFFF",
            color: "#707374",
            borderColor: "#707374",
            pointerEvents:"none",
        },
        alertMain: {
            marginLeft: "70%",
            padding: "0px 0px 0px 0px",
            borderRadius: " 16px 0px 0px 16px",
            backgroundColor: "#ffffff",
        },
        alertClose: {
            display: "none",
        },
        notClick:{
            pointerEvents:"none",
        }
    })
);

interface responseType {
    config: object;
    data: object;
    headers: object;
    requests: object;
    status: object;
    statusText: object;
}

export default function ComputeUtilityButton() {
    const [state, dispatch] = useStateValue();
    const classes = useStyles();
    const [FileList, setFileList] = useState<File | null | any>(null);
    const [alertopen, setAlertOpen] = React.useState(false);
    const [open, setOpen] = useState(true);
    const [ProcessEdit, setProcessEditSet] = useState(state.processEditStatus);
    const [errorMesaage, setErrorMesaage] = useState("");
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [resposeFutureAPI, setResposeFutureAPI] = useState<any | undefined>([]);
    const [utilityDemand, setUtilityDemand] = useState<any | undefined>([]);
    const [eventCall, setEventCall] = useState<any | undefined>([]);

    useEffect(() => {
        dispatch({
            type: "set",
            utilityDemandAPI: utilityDemand,
        });
    }, [utilityDemand]);

    const handleComputeUtilityDemand = () => {
        let flowerUploadData = UnitShowParameter(state.Future_API);
        // console.log("error",flowerUploadData);
        const postFutureParamater = async (url: any) => {
            const response: Promise<AxiosResponse<responseType[]> | undefined | any> = (
                await createAxiosInstance()
            ).post(url, flowerUploadData, {
                headers: {
                    accept: "*/*",
                    "Content-Type": "application/json",
                },
            });
            response
                .then(function (response) {
                    // console.log("result",finalSaveParameter );
                    // console.log("before", response.data.optim_id);
                    fetchData<any>(myConstClass.utilityDemand)
                    .then((response: any) => {
                        setUtilityDemand(response.data);
                        setAlertOpen(true);
                        setTimeout(function () {
                            // setOption(false);
                            setAlertOpen(false);
                            // console.log("close");
                        }, 3000);
                        let anc = {...state};
                        anc.CUDClickStatus = true;
                        dispatch( {
                            type: "set",
                            ...anc,
                        } );
                    })
                    .catch((error: any) => {
                        // console.log(error);
                        setErrorAlertOpen(true);
                        setTimeout(function () {
                            // setOption(false);
                            setErrorAlertOpen(false);
                        }, 3000);
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorAlertOpen(true);
                    setTimeout(function () {
                        // setOption(false);
                        setErrorAlertOpen(false);
                    }, 3000);
                });
        };

        postFutureParamater(myConstClass.FutureSaveAPI);
    };


    return (
        <Box component="span" sx={{ p: 2}} className={state.processEditStatus ? classes.root : classes.notClick}>
            <label>
                <Button
                    variant="outlined"
                    color="secondary"
                    className={state.processEditStatus ? classes.button : classes.buttonDisable}
                    startIcon={<ComputeUtilityDemandIcon />}
                    onClick={handleComputeUtilityDemand}
                >
                    Compute utility demand
                </Button>
            </label>
            <Alert
                // onClose={() => handleClick()}
                severity="success"
                className={alertopen ? classes.alertMain : classes.alertClose}
            >
                <AlertTitle>Successfully</AlertTitle>
                {/* <strong>{FileList ? `${FileList.name}has been correctly imported` : errorMesaage}</strong> */}
                <p>The compute utitlity demand Calculated</p>
            </Alert>
            <Alert
                // onClose={() => handleClick()}
                severity="error"
                className={errorAlertOpen ? classes.alertMain : classes.alertClose}
            >
                <AlertTitle>Unsuccessfully</AlertTitle>
                {/* <strong>{FileList ? FileList.name : "not found"}</strong> &nbsp; &nbsp;&nbsp;has not been correctly
                imported */}
                <p>The compute utitlity demand are not Calculated</p>
            </Alert>
            {/* {/* </div> */}
        </Box>
    );
}
