import React, { useState, useContext,useEffect } from "react";
import { Theme, createStyles, makeStyles,withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import TextFields from './TextFields';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../../../context";
import { NumberInput } from "../../NumberInput";
import { APIDataType } from "../../../types/ApitParameter";
import { InputTextTag } from "../../InputTextTag";
import { DecimalTwoDigit } from "../../../tools/timeutil";
import { myConstClass } from "../../../constants";
import { fetchData } from "../../../API/fetchAPI";
import { useStateValue } from "../../../Store/Store";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import IconButton from "@material-ui/core/IconButton";
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { DeleteData } from "../../../API/fetchAPI";
import { Timestamps } from "../../../tools/timeutil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(1),
                width: theme.spacing(100),
                height: theme.spacing(100),
                borderRadius: " 16px",
                padding: "16px",
                paddingBottom: "0px",
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subHeading: {
            fontWeight: 700,
            fontSize: "1.1rem",
            lineHeight: "22px",
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize: "14px",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "18px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#707374",
        },
        Steam: {
            width: theme.spacing(200),
            height: theme.spacing(14),
            padding: "16px 24px 16px 24px",
        },
        Electricity: {
            width: theme.spacing(50),
            height: theme.spacing(14),
        },
        Fuel: {
            width: theme.spacing(140),
            height: theme.spacing(14),
        },
        CO2: {
            width: theme.spacing(30),
            height: theme.spacing(14),
        },
        fuelMain: {
            paddingLeft: "12px",
        },
        resetIcon:{
            display: "flex",
            justifyContent: "space-between",  
            },
            iconButton:{
                color:"#ED0000",
            }
    })
);

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

export default function AllUnits() {
    const classes = useStyles();
    const [stateValue, dispatch] = useStateValue();
    const [resposeUtilityDemand, setResposeUtilityDemand] = useState<any | undefined>([]);

    // useEffect(() => {
    //     fetchData<any>(myConstClass.utilityDemand)
    //         .then((response: any) => {
    //             setResposeUtilityDemand(response.data);
    //             // console.log("fields",fields);
    //         })
    //         .catch((error: any) => {
    //             // console.log(error);
    //         });
    //     }, []);
    // console.log(fields);
    const [state, setState] = React.useState({PIHPS: "", PIMPS: "", PILPS: "", PIELEC: "",FHPS: "", FMPS: "", FLPS: "", FELEC: "",THPS: "", TMPS: "", TLPS: "", TELEC: ""});
    // console.log(fields);
    useEffect(() => {
        if (Array.isArray(stateValue.utilityDemandAPI) && stateValue.utilityDemandAPI.length !== 0) {
            setState((prevState) => {
                return {
                    ...prevState,
                    PIHPS:stateValue.utilityDemandAPI[2].all_units_sum.PI.HPS, 
                    PIMPS:stateValue.utilityDemandAPI[2].all_units_sum.PI.MPS, 
                    PILPS:stateValue.utilityDemandAPI[2].all_units_sum.PI.LPS,
                    PIELEC: stateValue.utilityDemandAPI[2].all_units_sum.PI.ELEC,
                    FHPS:stateValue.utilityDemandAPI[2].all_units_sum.FLO.HPS, 
                    FMPS:stateValue.utilityDemandAPI[2].all_units_sum.FLO.MPS, 
                    FLPS:stateValue.utilityDemandAPI[2].all_units_sum.FLO.LPS,
                    FELEC: stateValue.utilityDemandAPI[2].all_units_sum.FLO.ELEC,
                    THPS:stateValue.utilityDemandAPI[3].deltas_sum_demand.HPS, 
                    TMPS:stateValue.utilityDemandAPI[3].deltas_sum_demand.MPS, 
                    TLPS:stateValue.utilityDemandAPI[3].deltas_sum_demand.LPS,
                    TELEC: stateValue.utilityDemandAPI[3].deltas_sum_demand.ELEC,
                };
            });
        }
    }, [stateValue.utilityDemandAPI[0]]);
    const {PIHPS,PIMPS,PILPS,PIELEC,FHPS,FMPS,FLPS,FELEC,THPS,TMPS,TLPS,TELEC} = state;

    function RestFlowerAll(event: any) {

        if(stateValue.CUDClickStatus){
            DeleteData<any>(`${myConstClass.resetFlowerData}?unit=0&timestamp=${Timestamps().end_date}`)
            .then((response: any) => {
                console.log("deleted",response.data);
                fetchData<any>(myConstClass.Future_API)
            .then((response: any) => {
                let flowerData = {};
                const flowerAPIFields: any = response.data.reduce(
                    (obj: any, item: any) => {
                        if (
                            typeof item.is_throughput != "undefined" &&
                            item.is_throughput != null &&
                            item.is_throughput == 1 &&
                            item.unit !== "431"
                        ) {
                            if (item.unit === "631_451" && item.tag === "I_631B1001:F1002.") {
                                item.unit = "631_451_1";
                              flowerData = { ...flowerData, [item.unit]: item };
                            } else if (item.unit === "651" && item.tag === "I_651B1001:F1015.") {
                                item.unit = "651_1";
                              flowerData = { ...flowerData, [item.unit]: item };
                            } else {
                              flowerData = { ...flowerData, [item.unit]: item };
                            }
                            return flowerData;
                        }
                        Object.assign(obj, { [item.unit]: item });
                        
                    },
                    {}
                );
                let anc = {...stateValue};
                anc.Future_API = flowerAPIFields;
                dispatch( {
                    type: "set",
                    ...anc,
                } );
            })

            })
            .catch((error: any) => {
            });
            
            

        } else {
            fetchData<any>(myConstClass.Future_API)
            .then((response: any) => {
                let flowerData = {};
                const flowerAPIFields: any = response.data.reduce(
                    (obj: any, item: any) => {
                        if (
                            typeof item.is_throughput != "undefined" &&
                            item.is_throughput != null &&
                            item.is_throughput == 1 &&
                            item.unit !== "431"
                        ) {
                            if (item.unit === "631_451" && item.tag === "I_631B1001:F1002.") {
                                item.unit = "631_451_1";
                              flowerData = { ...flowerData, [item.unit]: item };
                            } else if (item.unit === "651" && item.tag === "I_651B1001:F1015.") {
                                item.unit = "651_1";
                              flowerData = { ...flowerData, [item.unit]: item };
                            } else {
                              flowerData = { ...flowerData, [item.unit]: item };
                            }
                            return flowerData;
                        }
                        Object.assign(obj, { [item.unit]: item });
                        
                    },
                    {}
                );
                let anc = {...stateValue};
                    anc.Future_API = flowerAPIFields;
                    dispatch( {
                        type: "set",
                        ...anc,
                    } );
            })
        }
   }
    // console.log(LPS);

    return (
        <>
            <div className={classes.root} >
                <Paper elevation={0} className={classes.Steam}>
                <Box className={classes.resetIcon} component="span" m={1}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                        All units
                        </Typography>
                        <Box component="span" m={1}>
            <IconButton className={classes.iconButton} aria-label="Reset flowers data for all units" onClick={(event) => RestFlowerAll(event)}>
            <LightTooltip title= {`Reset flowers data for all units `} placement="left">
            <RotateLeftIcon />
            </LightTooltip>
                        </IconButton>
            </Box>
            </Box>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <label className={classes.label}>
                                    LPS
                                    <Box className={classes.unit} component="span" m={1}></Box>
                                </label>
                                <Typography variant="body2" gutterBottom>
                                {FLPS ? DecimalTwoDigit(FLPS): "n/a"}
                                    <Box className={classes.unit} component="span" m={1}>
                                        (Flowers)
                                    </Box>{" "}
                                    - {PILPS ? DecimalTwoDigit(PILPS): "n/a"}{" "}
                                    <Box className={classes.unit} component="span" m={1}>
                                    (PI) 
                                    </Box>
                                     = {TLPS ? DecimalTwoDigit(TLPS): "n/a"}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <label className={classes.label}>
                                    MPS
                                    <Box className={classes.unit} component="span" m={1}></Box>
                                </label>
                                <Typography variant="body2" gutterBottom>
                                {FMPS ? DecimalTwoDigit(FMPS) : "n/a"}  <Box className={classes.unit} component="span" m={1}>
                                        (Flowers)
                                    </Box>{" "}
                                    - {PIMPS ? DecimalTwoDigit(PIMPS) : "n/a"}{" "}
                                    <Box className={classes.unit} component="span" m={1}>
                                    (PI) 
                                    </Box>
                                    = {TMPS ? DecimalTwoDigit(TMPS) : "n/a"}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <label className={classes.label}>
                                    HPS
                                    <Box className={classes.unit} component="span" m={1}></Box>
                                </label>
                                <Typography variant="body2" gutterBottom>
                                {FHPS ? DecimalTwoDigit(FHPS) : "n/a"}  <Box className={classes.unit} component="span" m={1}>
                                        (Flowers)
                                    </Box>{" "}
                                    - {PIHPS ? DecimalTwoDigit(PIHPS) : "n/a"}{" "}
                                    <Box className={classes.unit} component="span" m={1}>
                                    (PI) 
                                    </Box>
                                    = {THPS ? DecimalTwoDigit(THPS) : "n/a"}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <label className={classes.label}>
                                    ELEC
                                    <Box className={classes.unit} component="span" m={1}></Box>
                                </label>
                                <Typography variant="body2" gutterBottom>
                                {FELEC ? DecimalTwoDigit(FELEC) : "n/a"}  <Box className={classes.unit} component="span" m={1}>
                                        (Flowers)
                                    </Box>{" "}
                                    - {PIELEC ? DecimalTwoDigit(PIELEC) : "n/a"}{" "}
                                    <Box className={classes.unit} component="span" m={1}>
                                    (PI) 
                                    </Box> =  {TELEC ? DecimalTwoDigit(TELEC) : "n/a"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
        </>
    );
}
