import React, { useState, useContext } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import TextFields from './TextFields';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../../../context";
import { NumberInput } from "../../NumberInput";
import { APIDataType } from "../../../types/ApitParameter";
import SelectInput from "../../SelectInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import InfoIcon from "@material-ui/icons/Info";
import Divider from "@material-ui/core/Divider";
import { useStateValue } from "../../../Store/Store";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(1),
                width: theme.spacing(60),
                height: theme.spacing(16),
                border: "2px solid #F7F9FA",
                borderRadius: " 16px",
                padding: "16px",
                paddingBottom: "0px",
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subHeading: {
            fontWeight: 700,
            fontSize: "1rem",
            width: theme.spacing(100),
            height: "unset",
            border: "0px",
        },
        Heading: {
            width: theme.spacing(100),
            height: "unset",
            border: "0px",
            fontSize: "1.13rem",
            fontWeight: 700,
            lineHeight: "22px",
            textTransform: "uppercase",
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "16px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#707374",
        },
        powerplantParam: {
            width: theme.spacing(50),
            height: theme.spacing(14),
        },
    })
);

export default function PowerPlantParameters() {
    const classes = useStyles();
    const [ stateValue, dispatch ] = useStateValue();
    // const data = useContext(AppContext);
    // let fields = data.RKB.reduce(
    //     (obj:any, item:any) => Object.assign(obj, { [item.parameter]: item }), {});

    return (
        <>
            <div className={classes.root}>
                <Typography variant="h6" gutterBottom className={classes.Heading}>
                Powerplant parameters 
                </Typography>
                <Paper elevation={0} className={classes.powerplantParam}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                    Global Live steam production bounds
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    Min
                                    <Box className={classes.unit} component="span" m={1}>
                                    (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({}, stateValue.RKB["live_steam_prod_min_bnd"],{"Tabtype":"RKB"})} />
                            </Grid>
                            <Grid item xs={6}>
                            <label className={classes.label}>
                                    Max
                                    <Box className={classes.unit} component="span" m={1}>
                                    (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["live_steam_prod_max_bnd"],{"Tabtype":"RKB"})}/>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={classes.powerplantParam}>
                    <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
                    STEAG live steam consumption
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                Steam consumption
                                    <Box className={classes.unit} component="span" m={1}>
                                    (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["live_steam_steag_consumption"],{"Tabtype":"RKB"})} />
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
        </>
    );
}
