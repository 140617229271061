import React, { useState, useContext } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import TextFields from './TextFields';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { AppContext } from "../../context";
import { NumberInput } from "../NumberInput";
import { APIDataType } from "../../types/ApitParameter";
import { useStateValue } from '../../Store/Store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(60),
        height: theme.spacing(16),
        border: '2px solid #F7F9FA',
        borderRadius:' 16px',
        padding:'16px',
        paddingBottom: '0px',

      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    subHeading:{
      fontWeight: 700,
      fontSize:'1rem',
    },
    field:{
      width:'140px',
      height:'38px',
      borderRadius: '8px',
      flexDirection:'row',
    },
    label:{
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
    },
    unit:{
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#707374',
    },
    Steam:{
      width: theme.spacing(60),
    },
    Electricity:{
      width: theme.spacing(50),
    },
    Fuel:{
      width: theme.spacing(140),
      height: theme.spacing(14),
    },
    CO2:{
      width: theme.spacing(30),
    }
  }),
);

export default function UtilityDemands() {
  const [ stateValue, dispatch ] = useStateValue();
  // console.log(stateValue);
  const classes = useStyles();
  // const data = useContext(AppContext);
  //     let fields = data.global.reduce(
  //       (obj:any, item:any) => Object.assign(obj, { [item.parameter]: item }), {});
  return (
    <>
    <div className={classes.root}>
        <Paper elevation={0} className={classes.Fuel} >
      <form noValidate autoComplete="off">
      <Grid container spacing={3}>
            <Grid item xs={3}>
            <label className={classes.label} >LPS<Box className={classes.unit} component="span" m={1}>(ton/h)</Box></label>
        <NumberInput {...Object.assign( {},stateValue.Global["steam_lps_demand"],{"Tabtype":"Global"})} />
            </Grid>
            <Grid item xs={3}>
            <label className={classes.label} >MPS<Box className={classes.unit} component="span" m={1}>(ton/h)</Box></label>
        <NumberInput {...Object.assign( {},stateValue.Global["steam_mps_demand"],{"Tabtype":"Global"})} />
            </Grid>
        <Grid item xs={3}>
            <label className={classes.label} >HPS<Box className={classes.unit} component="span" m={1}>(ton/h)</Box></label>
        <NumberInput {...Object.assign( {},stateValue.Global["steam_hps_demand"],{"Tabtype":"Global"})} />
            </Grid>
            <Grid item xs={3}>
            <label className={classes.label} >Electricity<Box className={classes.unit} component="span" m={1}>(MW)</Box></label>
        <NumberInput {...Object.assign( {},stateValue.Global["electricity_demand"],{"Tabtype":"Global"})} />
            </Grid>
        </Grid>
      </form>
        </Paper>
    </div>
     </>
  );
}
