import React, { useState, useContext, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import TextFields from './TextFields';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../../../context";
import { NumberInput } from "../../NumberInput";
import SelectInput from "../../SelectInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import InfoIcon from "@material-ui/icons/Info";
import Divider from "@material-ui/core/Divider";
import { APIDataType } from "../../../types/ApitParameter";
import { useStateValue } from "../../../Store/Store";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(1),
                width: theme.spacing(60),
                height: theme.spacing(1),
                border: "2px solid #F7F9FA",
                borderRadius: " 16px",
                padding: "16px",
                paddingBottom: "0px",
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subH: {
            fontWeight: 700,
            fontSize: "1rem",
            width: theme.spacing(100),
            height: "unset",
            border: "0px",
        },
        H: {
            width: "100%",
            height: "unset",
            border: "0px",
            fontSize: "1.13rem",
            fontWeight: 700,
            lineHeight: "22px",
            textTransform: "uppercase",
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "16px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#707374",
        },
        Network: {
            width: theme.spacing(50),
            height: theme.spacing(14),
        },
        Pendelband: {
            width: theme.spacing(50),
            height: theme.spacing(14),
            border: "2px solid #F7F9FA",
            borderRadius: " 16px",
            padding: "16px",
            paddingBottom: "0px",
        },
        select: {
            width: theme.spacing(50),
            height: theme.spacing(20),
        },
        GT2Turbine: {
            border: "unset",
            height: "unset",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "22px",
            marginRight: "41px",
        },
        switch: {
            width: theme.spacing(200),
            height: "unset",
            border: "0px",
        },
        switchIcon: {
            verticalAlign: "middle",
            color: "#80CDFF",
        },
        switchLabel: {
            marginLeft: "9px",
        },
        subsection:{
            width: theme.spacing(50),
            height: theme.spacing(20),
          },
        switchdivider: {
            marginTop: "25px",
        },
        checkboxStyle: {
            display: "none",
        },
        checkboxStyleSwitch: {
            width: theme.spacing(50),
            height: theme.spacing(20),
            opacity: "0.5",
            backgroundColor:'#F7F9FA',
        },
        heading:{
            fontWeight: 700,
          },
        sectionHide: {
            border:'unset',
        },
    })
);

export default function Turbines() {
    const classes = useStyles();
    const data = useContext(AppContext);
    const [ stateValue, dispatch ] = useStateValue();
    // const arrayOfKeys: (keyof APIDataType)[] = ["param_id", "parameter", "value"];

    //     let fields = data.RKB.reduce(
    //         (obj:any, item:any) => Object.assign(obj, { [item.parameter]: item }), {});

    // const LPTField = stateValue.RKB["lpt_active"];
    // const MPTField = stateValue.RKB["mpt_stage1_active"];
    // const HPTField = stateValue.RKB["hpt_active"];
    // const [state, setState] = React.useState({
    //     mpt_stage1_active: MPTField.value,
    //     lpt_active:LPTField.value,
    //     hpt_active:HPTField.value,
    // });

    const [state, setState] = React.useState<any>({
        lpt_active: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        mpt_stage1_active: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        hpt_active: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
    });

    useEffect(() => {
        if (Object.keys(stateValue.RKB).length !== 0) {
            setState((prevState:any) => {
                return {
                    ...prevState,
                    lpt_active: stateValue.RKB["lpt_active"].value,
                    mpt_stage1_active: stateValue.RKB["mpt_stage1_active"].value,
                    hpt_active: stateValue.RKB["hpt_active"].value,
                };
            });
        }
    }, [stateValue]);



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let anc = {...stateValue};
        let value = "";
        if(event.target.checked === true){
            value = "1";
        } else{
            value = "0";
        }
        // console.log("checkevent:", value);
        // console.log("checkevent:",event.target.name);
        anc.RKB[`${event.target.name}`].value = value;
        dispatch( {
            type: "set",
            ...anc,
        } );
    };

    const { lpt_active,mpt_stage1_active,hpt_active } = state;

    const booleanValue = (parameter:any) => {
        let testValue;
        if(parameter == "1.0" || parameter == "1"){
            testValue = true;
        }
        else{
            testValue = false;
        }
        return testValue;
      }


    return (
        <>
            <div className={classes.root}>
                <Paper elevation={0} className={booleanValue(lpt_active) ?classes.subsection  : classes.checkboxStyleSwitch} >
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={<Checkbox checked={booleanValue(lpt_active)} onChange={handleChange} name="lpt_active" />}
                            label="LP TURBINE"
                            className={classes.heading}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <label className={classes.label}>
                                BFW pump electric consumption
                                    <Box className={classes.unit} component="span" m={1}>
                                    (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["lpt_bfw_pump_consumption"],{"Tabtype":"RKB"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={booleanValue(mpt_stage1_active) ?classes.subsection  : classes.checkboxStyleSwitch} >
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={<Checkbox checked={booleanValue(mpt_stage1_active)} onChange={handleChange} name="mpt_stage1_active" />}
                            label="MP TURBINE"
                            className={classes.heading}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <label className={classes.label}>
                                BFW pump electric consumption
                                    <Box className={classes.unit} component="span" m={1}>
                                    (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["mpt_bfw_stage1_pump_consumption"],{"Tabtype":"RKB"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={booleanValue(hpt_active) ?classes.subsection  : classes.checkboxStyleSwitch} >
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={<Checkbox checked={booleanValue(hpt_active)} onChange={handleChange} name="hpt_active"/>}
                            label="HP TURBINE"
                            className={classes.heading}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <label className={classes.label}>
                                BFW pump electric consumption
                                    <Box className={classes.unit} component="span" m={1}>
                                        (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign({},stateValue.RKB["hpt_bfw_pump_consumption"],{"Tabtype":"RKB"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
        </>
    );
}
