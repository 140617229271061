import React, { useState, useContext, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import TextFields from './TextFields';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../../../context";
import { NumberInput } from "../../NumberInput";
import { APIDataType } from "../../../types/ApitParameter";
import Divider from "@material-ui/core/Divider";
import { InputTextTag } from "../../InputTextTag";
import { myConstClass } from "../../../constants";
import { fetchData } from "../../../API/fetchAPI";
import useFetch from "../../../API/oldfetchAPI";
import ProcessUnit from "./ProcessUnit";
import { Timestamps } from "../../../tools/timeutil";

import { StateProvider, useStateValue } from "../../../Store/Store";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                // margin: theme.spacing(1),
                width: theme.spacing(200),
                // border: "2px solid #F7F9FA",
                borderRadius: " 16px",
                padding: "16px",
                // pointerEvents: "none",
                marginTop: "16px",
                marginBottom: "16px",
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subHeading: {
            fontWeight: 700,
            fontSize: "1.1rem",
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize: "14px",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "16px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#707374",
            paddingLeft: "5px",
            paddingRight: "5px",
        },
        Steam: {
            width: theme.spacing(200),
            height: theme.spacing(22),
        },
        Electricity: {
            width: theme.spacing(50),
            height: theme.spacing(14),
        },
        Fuel: {
            width: theme.spacing(140),
            height: theme.spacing(14),
        },
        CO2: {
            width: theme.spacing(30),
            height: theme.spacing(14),
        },
        fuelMain: {
            paddingLeft: "12px",
        },
    })
);

export default function EachUnits() {
    const classes = useStyles();
    const units:any = {
        0: "611",
        1: "631_451",
        2: "641 BHC",
        3: "641-1",
        4: "641-2",
        5: "651",
        6: "661",
        7: "662",
        8: "662",
        9: "663",
        10: "664",
        11: "681",
        12: "691",
        13: "763",
        14: "823",
        15: "824",
        16: "POX",
    };

    // const [Future_API, setFuture_API] = useState<any | undefined>([]);
    // const [Actual_API, setActual_API] = useState<any | undefined>([]);
    const [utilityDemand, setUtilityDemand] = useState<any | undefined>([]);
    const [PITag, setPITag] = useState<any | undefined>([]);
    // console.log("Future_API",Future_API);
    const [stateValue, dispatch] = useStateValue();
    const [stateFuture, setFutureState] = useState<any>({ flowerAPIFields: "" });
    // const [stateActual, setActualState] = useState<any>({ actualAPIField: "" });
    useEffect(() => {
        if (Object.keys(stateValue.Future_API).length !== 0) {
            // console.log("pass",stateValue.Future_API);
            setFutureState((prevState: any) => {
                return {
                    ...prevState,
                    flowerAPIFields:stateValue.Future_API,
                };
            });
        }
    }, [stateValue.Future_API]);
    const { flowerAPIFields } = stateFuture;
    // useEffect(() => {
    //     if (Object.keys(stateValue.Actual_API).length !== 0) {
    //         console.log("pass",stateValue.Actual_API);
    //         setActualState((prevState: any) => {
    //             return {
    //                 ...prevState,
    //                 actualAPIField:stateValue.Actual_API,
    //             };
    //         });
    //     }
    // }, [stateValue.Actual_API]);
    // const { actualAPIField } = stateActual;
    // console.log("tests",flowerAPIFields);
    useEffect(() => {
        dispatch({
            type: "set",
            utilityDemandAPI:utilityDemand,
        });
    }, [utilityDemand]);

    useEffect(() => {
            let test:any;
            for (const key in units) {
            fetchData<any>(`${myConstClass.PITag}?refinery_unit_id=${units[key]}&start_timestamp=${Timestamps().start_date}&end_timestamp=${Timestamps().end_date}`)
            .then((response: any) => {
               test = { ...test, [units[key]]: response.data };
               dispatch({
                type: "set",
                PITagAPI:test,
            });
                // console.log("fields",test);
            })
            .catch((error: any) => {
                // console.log(error);
            });
        } 
        // setPITag(test);
    }, []);

    useEffect(() => {

        if (stateValue.loadUtility) {
           fetchData<any>(myConstClass.utilityDemand)
           .then((response: any) => {
               setUtilityDemand(response.data);
               // console.log("fields",fields);
           })
           .catch((error: any) => {
               // console.log(error);
           });
       } 
       // setPITag(test);
   }, [stateValue.loadUtility]);

    // useEffect(() => {
    //     dispatch({
    //         type: "set",
    //         PITagAPI:PITag,
    //     });
    // }, [PITag]);
    // console.log("Future_APIn",Future_APIn);

    const [state, setState] = React.useState<any>({
        deltavaluesELECAPI: "",
        deltavaluesMPSAPI: "",
        deltavaluesLPSAPI: "",
        deltavaluesHPSAPI: "",
    });

    const [pistate, setPIState] = React.useState<any>({
        PITag611: "",
        PITag631_451:"",
        PITag641BHC:"",
        PITag641_1: "",
        PITag641_2: "",
        PITag651: "",
        PITag661: "",
        PITag662: "",
        PITag663: "",
        PITag664: "",
        PITag681: "",
        PITag691: "",
        PITag763: "",
        PITag823: "",
        PITag824: "",
        POX: "",
    });
    // console.log(fields);
    useEffect(() => {
       
        if (Array.isArray(stateValue.utilityDemandAPI) && stateValue.utilityDemandAPI.length !== 0) {
            // console.log("pass",utilityDemand[0]);
            setState((prevState: any) => {
                return {
                    ...prevState,
                    deltavaluesELECAPI: stateValue.utilityDemandAPI[0].deltas.ELEC,
                    deltavaluesMPSAPI: stateValue.utilityDemandAPI[0].deltas.MPS,
                    deltavaluesLPSAPI: stateValue.utilityDemandAPI[0].deltas.LPS,
                    deltavaluesHPSAPI: stateValue.utilityDemandAPI[0].deltas.HPS,
                };
            });
    }
    }, [stateValue.utilityDemandAPI]);
    const { deltavaluesELECAPI, deltavaluesMPSAPI, deltavaluesLPSAPI, deltavaluesHPSAPI } = state;
    useEffect(() => {
        if (Object.keys(stateValue.PITagAPI).length !== 0 ) {
            // console.log("pass",stateValue.PITagAPI);
            setPIState((prevState: any) => {
                return {
                    ...prevState,
                    PITag611: stateValue.PITagAPI["611"],
                    PITag631_451:stateValue.PITagAPI["631_451"],
                    PITag641BHC:stateValue.PITagAPI["641 BHC"],
                    PITag641_1: stateValue.PITagAPI["641-1"],
                    PITag641_2: stateValue.PITagAPI["641-2"],
                    PITag651: stateValue.PITagAPI["651"],
                    PITag661: stateValue.PITagAPI["661"],
                    PITag662: stateValue.PITagAPI["662"],
                    PITag663: stateValue.PITagAPI["663"],
                    PITag664: stateValue.PITagAPI["664"],
                    PITag681: stateValue.PITagAPI["681"],
                    PITag691: stateValue.PITagAPI["691"],
                    PITag763: stateValue.PITagAPI["763"],
                    PITag823: stateValue.PITagAPI["823"],
                    PITag824: stateValue.PITagAPI["824"],
                    PITagPOX: stateValue.PITagAPI["POX"],
                };
            });
        }
    }, [stateValue.PITagAPI]);

    // console.log(deltavaluesELECAPI);
const{ PITag611,PITag631_451,PITag641BHC,PITag641_1,PITag641_2,PITag651,PITag661,PITag662,PITag663,PITag664,PITag681,PITag691,PITag763,PITag823,PITag824,PITagPOX} = pistate;
// console.log("direct",PITag611);
    // const EachEnergyModel = (unit: any) => {
    //     return useFetch(`${myConstClass.EnergyModel}/${unit}`);
    // };

    // const EachEnergyModel = (unit: any) => {
    //     fetchData<any>(`${myConstClass.PITag}?refinery_unit_id=611&start_timestamp=${Timestamps().start_date}&end_timestamp=${Timestamps().end_date}`)
    //         .then((response: any) => {
    //             // setUtilityDemand(response.data);
    //             console.log("fields",response.data);
    //         })
    //         .catch((error: any) => {
    //             // console.log(error);
    //         });
    // };
//  console.log(EachEnergyModel(611));
    // const EachEnergyModel = (unit: any) => {
    //     return useFetch(`${myConstClass.PITag}?refinery_unit_id=${unit}&start_timestamp=${Timestamps().start_date}&end_timestamp=${Timestamps().end_date}`);
    // };

    // const flowerAPIFields: any = Future_API.reduce(
    //     (obj: any, item: any) => Object.assign(obj, { [item.unit]: item }),
    //     {}
    // );
    // console.log("PI",Actual_API);
    // console.log("store-PI",actualAPIField);
    // const actualAPIField1 = [actualAPIField];

    let test = {};
    // const actualAPIFields: any = Actual_API.reduce((previousObject: any, currentObject: any) => {
    //     if (
    //         typeof currentObject.is_throughput != "undefined" &&
    //         currentObject.is_throughput != null &&
    //         currentObject.is_throughput == 1 &&
    //         currentObject.unit !== "431"
    //     ) {
    //         test = { ...test, [currentObject.unit]: currentObject };
    //         return test;
    //     }
    // }, {});
    // console.log(actualAPIFields);
    const actualAPIFields: any = stateValue.Actual_API;

    const EachDeltas = (unit: any) => {
        // console.log(delta[0].deltas.ELEC);

    
    // console.log(delta[0].deltas.ELEC);
  
    const deltavaluesELEC = deltavaluesELECAPI;
    const deltavaluesMPS = deltavaluesMPSAPI;
    const deltavaluesLPS = deltavaluesLPSAPI;
    const deltavaluesHPS = deltavaluesHPSAPI;
  
    const refactorUnitdelta =   {...Object.assign(
      { ELEC: deltavaluesELEC[`${unit}`] },
      { MPS: deltavaluesMPS[`${unit}`] },
      { LPS: deltavaluesLPS[`${unit}`] },
      { HPS: deltavaluesHPS[`${unit}`] },
  )};
      return refactorUnitdelta;
  };
  // console.log(EachDeltas("611"));


    return (
            <div className={classes.root}>
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["611"] },
                        { flower: flowerAPIFields["611"] },
                        { tags: PITag611},
                        { deltas: EachDeltas("611") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["631_451"] },
                        { actual_1: actualAPIFields["631_451_1"] },
                        { flower: flowerAPIFields["631_451"] },
                        { flower_1: flowerAPIFields["631_451_1"] },
                        { tags: PITag631_451 },
                        { deltas: EachDeltas("631_451") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["641 BHC"] },
                        { flower: flowerAPIFields["641 BHC"] },
                        { tags: PITag641BHC },
                        { deltas: EachDeltas("641 BHC") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["641-1"] },
                        { flower: flowerAPIFields["641-1"] },
                        { tags: PITag641_1 },
                        { deltas: EachDeltas("641-1") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["641-2"] },
                        { flower: flowerAPIFields["641-2"] },
                        { tags: PITag641_2},
                        { deltas: EachDeltas("641-2") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["651"] },
                        { actual_1: actualAPIFields["651_1"] },
                        { flower: flowerAPIFields["651"] },
                        { flower_1: flowerAPIFields["651_1"] },
                        { tags: PITag651 },
                        { deltas: EachDeltas("651") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["661"] },
                        { flower: flowerAPIFields["661"] },
                        { tags: PITag661 },
                        { deltas: EachDeltas("661") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["662"] },
                        { flower: flowerAPIFields["662"] },
                        { tags: PITag662 },
                        { deltas: EachDeltas("662") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["663"] },
                        { flower: flowerAPIFields["663"] },
                        { tags: PITag663 },
                        { deltas: EachDeltas("663") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["664"] },
                        { flower: flowerAPIFields["664"] },
                        { tags: PITag664 },
                        { deltas: EachDeltas("664") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["681"] },
                        { flower: flowerAPIFields["681"] },
                        { tags: PITag681 },
                        { deltas: EachDeltas("681") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["691"] },
                        { flower: flowerAPIFields["691"] },
                        { tags: PITag691 },
                        { deltas: EachDeltas("691") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["763"] },
                        { flower: flowerAPIFields["763"] },
                        { tags: PITag763 },
                        { deltas: EachDeltas("763") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["823_1"] },
                        { flower: flowerAPIFields["823"] },
                        { tags: PITag823 },
                        { deltas: EachDeltas("823") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["824"] },
                        { flower: flowerAPIFields["824"] },
                        { tags: PITag824 },
                        { deltas: EachDeltas("824") }
                    )}
                />
                <ProcessUnit
                    {...Object.assign(
                        { actual: actualAPIFields["POX"] },
                        { flower: flowerAPIFields["POX"] },
                        { tags: PITagPOX },
                        { deltas: EachDeltas("POX") }
                    )}
                />
            </div>
    );  
}
