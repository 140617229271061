import React, { useState, useContext, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { AppContext } from "../../context";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import TextFields from './TextFields';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { NumberInput } from "../NumberInput";
import { APIDataType } from "../../types/ApitParameter";
import CheckboxInput from "./CheckboxInput";
import { useStateValue } from "../../Store/Store";

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(1),
                width: theme.spacing(60),
                height: theme.spacing(25),
                border: "2px solid #F7F9FA",
                borderRadius: " 16px",
                padding: "16px",
                paddingBottom: "0px",
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subHeading: {
            fontWeight: 700,
            fontSize: "1rem",
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "16px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#707374",
        },
        fuel: {
            width: theme.spacing(40),
            height: theme.spacing(15),
        },
        Pendelband: {
            width: theme.spacing(40),
            height: theme.spacing(15),
        },
        checkboxstyle: {
            width: theme.spacing(40),
            height: theme.spacing(15),
            opacity: "0.5",
        },
        heading: {
            fontWeight: 700,
        },
    })
);

export default function FuelConsumptionBounds() {
  const classes = useStyles();
  const [ stateValue, dispatch ] = useStateValue();

    const [state, setState] = React.useState<any>({
        fo_allowed: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        fg_allowed: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        ng_allowed: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        hel_allowed: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
        SFSG_allowed: { measured_in: "",optim_id:null,optim_output:null,param_id: null, parameter: "",timestamp:"",units_id:null,value: true },
    });
    
    useEffect(() => {
        if (Object.keys(stateValue.Global).length !== 0) {
            // console.log("useeffect",stateValue.Global["fg_allowed"]);
            setState((prevState:any) => {
                return {
                    ...prevState,
                    fo_allowed: stateValue.Global["fo_allowed"],
                    fg_allowed: stateValue.Global["fg_allowed"],
                    ng_allowed: stateValue.Global["ng_allowed"],
                    hel_allowed: stateValue.Global["hel_allowed"],
                    SFSG_allowed: stateValue.Global["sfsg_allowed"],
                };
            });
        }
    }, [stateValue]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fo_allowed: any) => {
        let anc = {...stateValue};
        let value = "";
        if(event.target.checked === true){
            value = "1";
        } else{
            value = "0";
        }
        console.log("checkevent:", value);
        anc.Global[`${fo_allowed.parameter}`].value = value;
        dispatch( {
            type: "set",
            ...anc,
        } );
        // setState((prevState:any) => {
        //     return {
        //         ...prevState,
        //         [event.target.name]: {
        //             param_id: fo_allowed.param_id,
        //             parameter: fo_allowed.parameter,
        //             value: event.target.checked,
        //         },
        //     };
        // });
    };

    const booleanValue = (parameter:any) => {
        let testValue;
        if(parameter.value == "1.0" || parameter.value == "1"){
            testValue = true;
        }
        else{
            testValue = false;
        }
        return testValue;
      }

    const { fo_allowed, fg_allowed, ng_allowed, hel_allowed, SFSG_allowed } = state;
    // const foaAllowedChecked =  booleanValue(fo_allowed);
    
    // console.log(fo_allowed);
    return (
        <>
            <div className={classes.root}>
                <Paper elevation={0} className={booleanValue(fo_allowed) ? classes.fuel : classes.checkboxstyle}>
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={booleanValue(fo_allowed)}
                                    onChange={(event) => handleChange(event, fo_allowed)}
                                    name={fo_allowed.parameter}
                                />
                            }
                            label="Fuel Oil"
                            className={classes.heading}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    FO Min
                                    <Box className={classes.unit} component="span" m={1}>
                                        (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["fo_consumption_min_bnd"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    FO Max
                                    <Box className={classes.unit} component="span" m={1}>
                                        (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput  {...Object.assign( {},stateValue.Global["fo_consumption_max_bnd"],{"Tabtype":"Global"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={booleanValue(fg_allowed) ? classes.Pendelband : classes.checkboxstyle}>
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={booleanValue(fg_allowed)}
                                    onChange={(event) => handleChange(event, fg_allowed)}
                                    name={fg_allowed.parameter}
                                />
                            }
                            label="Fuel Gas"
                            className={classes.heading}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    FG Min
                                    <Box className={classes.unit} component="span" m={1}>
                                        (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["fg_consumption_min_bnd"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    FG Max
                                    <Box className={classes.unit} component="span" m={1}>
                                        (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["fg_consumption_max_bnd"],{"Tabtype":"Global"})}/>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={booleanValue(ng_allowed) ? classes.fuel : classes.checkboxstyle}>
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={booleanValue(ng_allowed)}
                                    onChange={(event) => handleChange(event, ng_allowed)}
                                    name={ng_allowed.parameter}
                                />
                            }
                            label="Natural Gas"
                            className={classes.heading}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    NG Min
                                    <Box className={classes.unit} component="span" m={1}>
                                        (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["ng_consumption_min_bnd"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    NG Max
                                    <Box className={classes.unit} component="span" m={1}>
                                        (MW)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["ng_consumption_max_bnd"],{"Tabtype":"Global"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={booleanValue(hel_allowed) ? classes.Pendelband : classes.checkboxstyle}>
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ booleanValue(hel_allowed) }
                                    onChange={(event) => handleChange(event, hel_allowed)}
                                    name={hel_allowed.parameter}
                                />
                            }
                            label="Heating Oil extra light"
                            className={classes.heading}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    HEL Min
                                    <Box className={classes.unit} component="span" m={1}>
                                        (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["hel_consumption_min_bnd"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    HEL Max
                                    <Box className={classes.unit} component="span" m={1}>
                                        (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["hel_consumption_max_bnd"],{"Tabtype":"Global"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper elevation={0} className={booleanValue(SFSG_allowed) ? classes.Pendelband : classes.checkboxstyle}>
                    <form noValidate autoComplete="off">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={booleanValue(SFSG_allowed) }
                                    onChange={(event) => handleChange(event, SFSG_allowed)}
                                    name={SFSG_allowed.parameter}
                                />
                            }
                            label="SFSG"
                            className={classes.heading}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    SFSG Min
                                    <Box className={classes.unit} component="span" m={1}>
                                        (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["sfsg_consumption_min_bnd"],{"Tabtype":"Global"})} />
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    SFSG Max
                                    <Box className={classes.unit} component="span" m={1}>
                                        (ton/hr)
                                    </Box>
                                </label>
                                <NumberInput {...Object.assign( {},stateValue.Global["sfsg_consumption_max_bnd"],{"Tabtype":"Global"})} />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
        </>
    );
}
