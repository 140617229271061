import React, { useState, useEffect, useContext } from "react";
import { Theme, createStyles, makeStyles,withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../../../context";
import { NumberInput } from "../../NumberInput";
import { APIDataType } from "../../../types/ApitParameter";
import Divider from "@material-ui/core/Divider";
import { InputTextTag } from "../../InputTextTag";
import { myConstClass } from "../../../constants";
import useFetch from "../../../API/oldfetchAPI";
import { DecimalTwoDigit } from "../../../tools/timeutil";
import { useStateValue } from "../../../Store/Store";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { fetchData } from "../../../API/fetchAPI";
import IconButton from "@material-ui/core/IconButton";
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import { DeleteData } from "../../../API/fetchAPI";
import { Timestamps } from "../../../tools/timeutil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        subHeading: {
            fontWeight: 700,
            fontSize: "1.1rem",
        },
        field: {
            width: "140px",
            height: "38px",
            borderRadius: "8px",
            flexDirection: "row",
            fontSize: "14px",
        },
        label: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "11px",
            lineHeight: "16px",
        },
        tagLabel: {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "56%",
            lineHeight: "16px",
        },
        unit: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "95%",
            lineHeight: "16px",
            color: "#707374",
            paddingLeft: "5px",
            paddingRight: "5px",
        },
        unitlabel: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "95%",
            lineHeight: "16px",
            color: "#707374",
            paddingLeft: "5px",
            paddingRight: "5px",
            marginLeft:"unset",
            marginRight:"unset",
        },
        Steam: {
            width: theme.spacing(200),
            height: theme.spacing(24),
        },
        extraUnit: {
            width: theme.spacing(200),
            height: theme.spacing(40),
        },
        Electricity: {
            width: theme.spacing(50),
            height: theme.spacing(14),
        },
        Fuel: {
            width: theme.spacing(140),
            height: theme.spacing(14),
        },
        CO2: {
            width: theme.spacing(30),
            height: theme.spacing(14),
        },
        fuelMain: {
            paddingTop: "12px",
        },
        notEditable: {
            //  pointerEvents: "none",
        },
        resetIcon:{
            display: "flex",
            justifyContent: "space-between", 
            height: "30px",     
        },
        iconButton:{
            color:"#ED0000",
        }
    })
);
const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

export default function ProcessUnit(props: any): any {
    const classes = useStyles();
    const [ state, dispatch ] = useStateValue();
    let open = false;
    let tags = Object.values(props.tags || "");
    let actual = props.actual;
    let flower = props.flower;
    let deltas = props.deltas;
    if(flower === undefined){
        flower = '';
    }
    if(actual === undefined){
        actual = '';
    }
    if(tags === undefined){
        tags = [];
    }
    if(deltas === undefined){
        deltas = [];
    }
    if(actual.unit === "823_1"){
        actual.unit = "823"
    }

    let actual_1:any = [];
    if(actual.unit === "631_451" || actual.unit === "651"){
        actual_1 = props.actual_1;
        if(actual_1 === undefined){
            actual_1 = [];
        }
        // console.log(props.actual_1);
    }
    let flower_1:any = [];
    if(flower.unit === "631_451" || flower.unit === "651"  ){
        flower_1 = props.flower_1;
        if(flower_1 === undefined){
            flower_1 = [];
        }
    }
    if(actual_1.unit === "651_1" || actual_1.unit === "631_451_1"){
        open = true;
    }
    const [flowerUnit, setFlowerUnit] = useState<any | undefined>(flower.unit);
    const [flowerValue, setFlowerValue] = useState<any | undefined>(flower.throughput);
    const [actualUnit, setActualUnit] = useState<any | undefined>(actual.unit);
    const [actualValue, setActualValue] = useState<any | undefined>(actual.value );
    const [flowerValue1, setFlower1Value] = useState<any | undefined>(flower_1.throughput || "");
    const [actualValue1, setActual1Value] = useState<any | undefined>(actual_1.value || "");

    useEffect(() => {
        setFlowerUnit(flower.unit);
        setFlowerValue(flower.throughput);
        setActualUnit(actual.unit);
        setActualValue(actual.value);
        setFlower1Value(flower_1.throughput);
        setActual1Value(actual_1.value);
    }, [props]);

    const flowerDetail = {
       "parameter":flowerUnit,
        "value":flowerValue,
        "Tabtype":"Flower"
    }
    const flowerDetail1 = {
        "parameter":`${flowerUnit}_1`,
         "value":flowerValue1,
         "Tabtype":"Flower"
     }
    const actualDetail = {
        "parameter":actualUnit,
         "value":DecimalTwoDigit(actualValue),
         "Tabtype":"Actual"
     }
     const actualDetail1 = {
        "parameter":`${actualUnit}_1`,
         "value":DecimalTwoDigit(actualValue1),
         "Tabtype":"Actual"
     }
     const restUnit = actual.unit ? actual.unit : flower.unit;

        function RestFlower(event: any,restUnit:any) {
            // console.log("event",restUnit);
               
                if(state.CUDClickStatus){
                    DeleteData<any>(`${myConstClass.resetFlowerData}?unit=${restUnit}&timestamp=${Timestamps().end_date}`)
                    .then((response: any) => {
                        console.log("deleted",response.data);
                        fetchData<any>(myConstClass.Future_API)
                        .then((response: any) => {
                            let flowerData = {};
                            const flowerAPIFields: any = response.data.reduce(
                                (obj: any, item: any) => {
                                    if (
                                        typeof item.is_throughput != "undefined" &&
                                        item.is_throughput != null &&
                                        item.is_throughput == 1 &&
                                        item.unit !== "431"
                                    ) {
                                        if (item.unit === "631_451" && item.tag === "I_631B1001:F1002.") {
                                            item.unit = "631_451_1";
                                            console.log(item.unit);
                                          flowerData = { ...flowerData, [item.unit]: item };
                                        } else if (item.unit === "651" && item.tag === "I_651B1001:F1015.") {
                                            item.unit = "651_1";
                                          flowerData = { ...flowerData, [item.unit]: item };
                                        } else {
                                          flowerData = { ...flowerData, [item.unit]: item };
                                        }
                                        return flowerData;
                                    }
                                    Object.assign(obj, { [item.unit]: item });
                                    
                                },
                                {}
                            );
                            let anc = {...state};
                            console.log("fields", flowerAPIFields);
                            console.log(flowerAPIFields[restUnit]);
                            anc.Future_API[restUnit] =flowerAPIFields[restUnit];
                            dispatch( {
                                type: "set",
                                ...anc,
                            } );
                        })
                    })
                    .catch((error: any) => {
                    });

                } else {
                    fetchData<any>(myConstClass.Future_API)
                    .then((response: any) => {
                        let flowerData = {};
                        const flowerAPIFields: any = response.data.reduce(
                            (obj: any, item: any) => {
                                if (
                                    typeof item.is_throughput != "undefined" &&
                                    item.is_throughput != null &&
                                    item.is_throughput == 1 &&
                                    item.unit !== "431"
                                ) {
                                    if (item.unit === "631_451" && item.tag === "I_631B1001:F1002.") {
                                        item.unit = "631_451_1";
                                      flowerData = { ...flowerData, [item.unit]: item };
                                    } else if (item.unit === "651" && item.tag === "I_651B1001:F1015.") {
                                        item.unit = "651_1";
                                      flowerData = { ...flowerData, [item.unit]: item };
                                    } else {
                                      flowerData = { ...flowerData, [item.unit]: item };
                                    }
                                    return flowerData;
                                }
                                Object.assign(obj, { [item.unit]: item });
                                
                            },
                            {}
                        );
                        let anc = {...state};
                        anc.Future_API[restUnit] =flowerAPIFields[restUnit];
                        dispatch( {
                            type: "set",
                            ...anc,
                        } );
                    })
                }
       }

    return (
        <Paper elevation={0} className={ open ? classes.extraUnit : classes.Steam }>
            <Box className={classes.resetIcon} component="span" >
            <Typography variant="subtitle2" gutterBottom className={classes.subHeading}>
            {actual.unit ? actual.unit : flower.unit }
            </Typography>
            <Box component="span" m={1}>
            <IconButton className={classes.iconButton} aria-label="Reset PI import for unit restUnit" onClick={(event) => RestFlower(event,restUnit)}>
            <LightTooltip title= {`Reset flowers data for unit ${restUnit}`} placement="left">
            <RotateLeftIcon />
            </LightTooltip>
                        </IconButton>
            </Box>
            </Box>

            <form noValidate autoComplete="off">
                <Grid container spacing={3} >
                    <Grid item xs={2} className={ classes.notEditable }>
                        <label className={classes.label}>
                            Current throughput
                            <Box className={classes.unit} component="span" m={1}>
                                (PI)
                            </Box>
                        </label>
                            {/* <TextField
                                id={`tag-${props.refinery_units_id}-${props.refinery_units_pkid}`}
                                value={DecimalTwoDigit(actual.value)}
                                variant="outlined"
                                className={classes.field}
                                InputProps={{
                                    inputProps: { "data-testid": props?.parameter },
                                }}
                            /> */}
                            <NumberInput {...Object.assign( {},actualDetail)} />

                    </Grid>
                    <Grid item xs={2}>
                        <label className={classes.label}>
                            Forecasted throughput*
                            <Box className={classes.unitlabel} component="span" m={1}>
                                (Flowers)
                            </Box>
                        </label>
                        <div className="">
                            {/* <TextField
                                id={`tag-${flower.tag}`}
                                value={DecimalTwoDigit(flower.throughput) }
                                variant="outlined"
                                className={classes.field}
                                InputProps={{
                                    inputProps: { "data-testid": props?.parameter },
                                }}
                            /> */}
                            <NumberInput {...Object.assign( {},flowerDetail)} />
                        </div>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    {tags.map((element: any, index) => {
                        // console.log("process",element);
                        return element.pi_tag !== "NONE" ? (
                            <Grid item xs={1} className={ classes.notEditable }>
                                <label className={classes.tagLabel}>
                                <Box m={1}> {element.refinery_units_type}</Box>
                                    {element.pi_tag}        
                                </label>
                                <InputTextTag {...element} />
                            </Grid>
                        ) : ("");
                    })}
                </Grid>
                {open &&
                <Grid container spacing={3}>
                    <Grid item xs={2} className={ classes.notEditable }>
                        <label className={classes.label}>
                            Current throughput
                            <Box className={classes.unit} component="span" m={1}>
                                (PI)
                            </Box>
                        </label>
                        <div className="">
                            {/* <TextField
                                id={`tag-${props.refinery_units_id}-${props.refinery_units_pkid}`}
                                value={DecimalTwoDigit(actual_1.value)}
                                variant="outlined"
                                className={classes.field}
                                InputProps={{
                                    inputProps: { "data-testid": props?.parameter },
                                }}
                            /> */}
                             <NumberInput {...Object.assign( {},actualDetail1)} />
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <label className={classes.label}>
                            Forecasted throughput*
                            <Box className={classes.unitlabel} component="span" m={1}>
                                (Flowers)
                            </Box>
                        </label>
                        <div className="">
                            {/* <TextField
                                id={`tag-${props.refinery_units_id}-${props.refinery_units_pkid}`}
                                value={DecimalTwoDigit(flower_1.throughput)}
                                variant="outlined"
                                className={classes.field}
                                InputProps={{
                                    inputProps: { "data-testid": props?.parameter },
                                }}
                            /> */}
                             <NumberInput {...Object.assign( {},flowerDetail1)} />
                        </div>
                    </Grid>
                </Grid>
}
            </form>
            <Grid container spacing={3} className={classes.fuelMain}>
                <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>
                        <Box className={classes.label} component="span" m={1}>
                            LPS
                        </Box>
                        <Box className={classes.unit} component="span" m={1}>
                            (Delta)
                        </Box>{" "}
                        { DecimalTwoDigit(deltas.LPS)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>
                        <Box className={classes.label} component="span" m={1}>
                            MPS
                        </Box>
                        <Box className={classes.unit} component="span" m={1}>
                            (Delta)
                        </Box>
                        {DecimalTwoDigit(deltas.MPS)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>
                    <Box className={classes.label} component="span" m={1}>
                            HPS
                        </Box>
                        <Box className={classes.unit} component="span" m={1}>
                            (Delta)
                        </Box>
                        { DecimalTwoDigit(deltas.HPS)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>
                    <Box className={classes.label} component="span" m={1}>
                            ELEC
                        </Box>
                        <Box className={classes.unit} component="span" m={1}>
                            (Delta)
                        </Box>
                        {DecimalTwoDigit(deltas.ELEC)}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}
