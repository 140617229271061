import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../context";
import { myConstClass } from "../constants";
import axios, { AxiosResponse } from "axios";
import { createAxiosInstance } from "../tools/axios-auth-interceptor";
import { AnyTxtRecord } from "dns";

interface APIDataType {
    id: number;
    email: string;
    first_name: string;
    optim_id: number;
    optim_output: null;
    value: number;
    timestamp: string;
    param_id: number;
    units_id: number;
    parameter: string;
    measured_in: String;
}

interface responseType {
    config: object;
    data: APIDataType[];
    headers: object;
    requests: object;
    status: object;
    statusText: object;
}

export interface Sensor {
    data: APIDataType[];
}

export type Action = { type: "set" };

export const initialState = {
    Global: "",
    GT2: "",
    RKB: "",
    Future_API:"",
    Actual_API:"",
    PITagAPI:"",
    energyModel:"",
    utilityDemandAPI:"",
    scenario: {
        default: "scenario_1",
        name: "scenario_name",
        count: 1,
        value: {
            scenario_1: "Scenario 1",
        },
    },
    optimID: "",
    scenarioCount:"",
    resulttabs: false,
    CUDClickStatus:false,
    processEditStatus:false,
    EnergyEditStatus:false,
    EnergyEditUnit: "",
    energyModelSave:false,
    scenarioResults:"",
    scenarioOptimizeParameterResults:"",
    scenarioOptimParamResultsDemo:"",
    scenarioCompareoption:{
        scenarioCompare1: "scenario_1",
        scenarioCompare2: "scenario_1",
    },
    notices:{
    noticeStatus:"",
    noticeTypes:"",
    noticeOption:"",
    },
    loading: true,
    downloadOption:"",
    exportData:"",
    modelActive:false,
    loadUtility:false,
};

const reducer = (state = initialState, { type, ...rest }: Action) => {
    // console.log("action",rest);
    // console.log("result",{ ...state, ...rest });
    switch (type) {
        case "set":
            // console.log("action",rest);
            return { ...state, ...rest };

        default:
            return state;
    }
};

export default reducer;
